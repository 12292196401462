import { generate } from '@ant-design/colors';
import { Color } from '@material-ui/core';
import {
  amber, blue, brown, deepPurple,
} from '@material-ui/core/colors';

const mapAmberPaletteToMaterialColor = (antPalette: string[]): Color => ({
  50: antPalette[0],
  100: antPalette[1],
  200: antPalette[2],
  300: antPalette[3],
  400: antPalette[4],
  500: antPalette[5],
  600: antPalette[6],
  700: antPalette[7],
  800: antPalette[8],
  900: antPalette[9],
  A100: antPalette[2],
  A200: antPalette[3],
  A400: antPalette[5],
  A700: antPalette[9],
});

const generateMaterialColor = (colorValue: string): Color => (
  mapAmberPaletteToMaterialColor(generate(colorValue))
);

export const customGreen = generateMaterialColor('#D3EFC4');

export const PRIMARY = 'rgb(222,75,64)';
export const PRIMARY_LIGHT = 'rgb(242,171,170)';
export const SECONDARY = amber[500];

export const STATUS_GREEN = customGreen[500];
export const STATUS_GREEN_LIGHT = customGreen[200];

export const chartColors = [
  amber[200],
  PRIMARY_LIGHT,
  customGreen[500],
  blue[200],
  brown[200],
  deepPurple[100],
];
