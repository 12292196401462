import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import NumberInput from '../input-single/inputs/NumberInput';
import { mapEMobilityParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';

type Props = PropsFromRedux & {
  inputClassName?: string;
  textClassName?: string;
  sliderClassName?: string;
  tooltip?: string;
};

const TotalMobilityEnergy = 22200;

const EMobility = (props: Props) => {
  const { t } = useTranslation();
  const {
    inputClassName,
    sliderClassName,
    textClassName,
    additionalEMobilityEnergy,
    changeParams: _changeParams,
    tooltip,
  } = props;

  const handleNewValue = (value: number) => {
    _changeParams({ endUser: { additionalEMobilityEnergy: value } });
  };

  return (
    <>
      <Typography className={textClassName} variant="caption">
        {t(
          'paramsEMobilityText',
          {
            percentage: +(additionalEMobilityEnergy / TotalMobilityEnergy / 0.01).toFixed(2),
          },
        )}
      </Typography>
      <Tooltip
        placement="bottom"
        arrow
        enterDelay={1000}
        open={tooltip === undefined ? false : undefined}
        title={tooltip}
      >
        <SimpleSlider
          color="secondary"
          className={sliderClassName}
          value={additionalEMobilityEnergy}
          onNewValue={handleNewValue}
          max={TotalMobilityEnergy}
          step={TotalMobilityEnergy / 100}
        />
      </Tooltip>

      <Tooltip
        placement="bottom"
        arrow
        enterDelay={1000}
        open={tooltip === undefined ? false : undefined}
        title={tooltip}
      >
        <NumberInput
          className={inputClassName}
          label={t('paramsEMobilityEnergyConsumptionInputLabel')}
          value={additionalEMobilityEnergy}
          onNewValue={handleNewValue}
          step={1}
          unit="GWh"
        />
      </Tooltip>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapEMobilityParamsToProps, { changeParams });

export default connector(EMobility);
