import React from 'react';
import { Decimal } from 'decimal.js';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Handles, Rail, Slider, Tracks,
} from 'react-compound-slider';
import SliderRail from './compound/SliderRail';
import Handle from './compound/Handle';
import Track from './compound/Track';

const useStyles = makeStyles(() => createStyles({
  root: {
    height: 2,
    width: '100%',
  },
  slider: {
    position: 'relative',
    width: '100%',
  },
}));

type Props = {
  max: number;
  step: number;
  values: number[];
  onNewValues: (val: readonly number[]) => void;
};


const isDiffGreaterEqualsStep = (valueA: number, valueB: number, step: number): boolean => (
  Math.abs((new Decimal(valueA)).minus(new Decimal(valueB)).toNumber()) >= step
);

const shouldValueUpdate = (
  oldValue: number,
  newValue: number,
  step: number,
  max: number,
) => (
  isDiffGreaterEqualsStep(newValue, oldValue, step) // to keep initial values
  && (
    oldValue <= max // always update if old value is within bounds
    || newValue < max // always update if new value is lower than max
  )
);

const CapacityDuoSlider = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    max, step, values, onNewValues,
  } = props;

  const handleSliderChange = (update: readonly number[]) => {
    // prevent rounding of default values & prevent over max db value capping
    if (shouldValueUpdate(values[0], update[0], step, max)) {
      onNewValues([update[0], values[1]]);
    }
    if (shouldValueUpdate(values[1], update[1], step, max)) {
      onNewValues([values[0], update[1]]);
    }
  };

  const domain = [0, max];

  return (
    <div className={classes.root}>
      <Slider
        mode={1}
        step={step}
        domain={domain}
        className={classes.slider}
        onChange={handleSliderChange}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ activeHandleID, handles, getHandleProps }) => (
            <div>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  activeHandleID={activeHandleID}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }, i) => (
                <Track
                  key={id}
                  faded={i > 0}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
};

export default CapacityDuoSlider;
