import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import DownloadIcon from '@material-ui/icons/GetApp';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { mapComputedToProps } from '../../redux/mappers';
import exportResultData from '../../business-logic/data-export/exportResultData';

type Props = {
  className?: string;
} & PropsFromRedux

const ExportResultDataButton = (props: Props) => {
  const { t } = useTranslation();
  const {
    className,
    activeComputedData,
  } = props;

  const handleClick = () => {
    exportResultData(activeComputedData, t);
  };

  return (
    <Tooltip title={t('tooltipExportResultData')} enterDelay={200} arrow placement="top">
      <IconButton className={className || ''} onClick={handleClick}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapComputedToProps, {});

export default connector(ExportResultDataButton);
