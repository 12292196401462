import * as _ from 'lodash';
import { ActionTypes } from '../types';
import {
  ADD_COMPUTED_DATA,
  ADD_PRE_COMPUTED_CACHE_ENTRY,
  CHANGE_ACTIVE_PRE_COMPUTED_DATA,
  CHANGE_COMPARE_PRE_COMPUTED_DATA,
  DELETE_COMPUTED_DATA,
  IComputedState,
  REQUEST_COMPARE_PRE_COMPUTED_DATA,
  SET_ACTIVE_COMPUTED_DATA,
} from '../types/computedTypes';
import { LocalizedString } from '../../domain_model/scenario/LocalizedString';
import {
  SolarLoadPreScalingCacheEntry,
  SolarLoadPreScalingData,
} from '../../domain_model/ComputedDataCollection';

const initialState: IComputedState = {
  computedDataSets: {},
  activeComputedData: {
    scenarioInfo: {
      scenarioName: new LocalizedString('noScenarioSelectedTitle'),
      scenarioDescription: new LocalizedString('noScenarioSelectedDescription'),
    },
    basicLoads: {
      endUser: [],
      import: [],
      export: [],
      nuclear: [],
      thermal: [],
      river: [],
      solar: [],
      wind: [],
    },
    specialLoads: {
      networkLosses: [],
      damInflux: [],
    },
    customData: {
      customConsumption: [],
      customProduction: [],
    },
    computedStorage: {
      residual: [],

      damOverflow: [],
      damProducedPower: [],
      damUsableStorageLevel: [],
      damStorageLevel: [],

      pumpConsumedPower: [],
      pumpProducedPower: [],
      pumpUsableStorageLevel: [],
      pumpStorageLevel: [],

      batteryConsumedPower: [],
      batteryProducedPower: [],
      batteryUsableStorageLevel: [],
      batteryStorageLevel: [],
    },
    computedMisc: {
      damNegativeProducedPower: [],
      pumpNegativeProducedPower: [],
      batteryNegativeProducedPower: [],

      totalConsumption: [],
      totalProduction: [],

      excessSeries: [],
      shortageSeries: [],
    },
    computedSummary: {
      totalEnergyConsumption: 0,
      totalEnergyConsumptionPlusExport: 0,
      totalEnergyProduction: 0,
      totalEnergyProductionPlusImportAndShortage: 0,

      importEnergy: 0,
      exportEnergy: 0,
      nettImportEnergy: 0,

      excessEnergy: 0,
      shortageEnergy: 0,
      nettShortageEnergy: 0,

      selfSufficiencyPercentage: 0,
      importDurationH: 0,
      importMaxPower: 0,

      nuclearEnergyProduction: 0,
      thermalEnergyProduction: 0,
      solarEnergyProduction: 0,
      windEnergyProduction: 0,
      riverEnergyProduction: 0,
      damEnergyProduction: 0,

      pumpEnergyProduction: 0,
      pumpEnergyConsumption: 0,
      pumpEnergyNettProduction: 0,

      batteryEnergyProduction: 0,
      batteryEnergyConsumption: 0,
      batteryEnergyNettProduction: 0,

      customEnergyProduction: 0,
      customEnergyConsumption: 0,
      customEnergyNettProduction: 0,

      networkLossEnergyConsumption: 0,
      endUserEnergyConsumption: 0,

      usableStorageDifference: 0,
      usableFinalStorageLevel: 0,
      damUsableStorageLevelEnergyDiff: 0,
      damUsableStorageLevelEnergyIncrease: 0,
      damUsableStorageLevelEnergyDecrease: 0,
      pumpUsableStorageLevelEnergyDiff: 0,
      pumpUsableStorageLevelEnergyIncrease: 0,
      pumpUsableStorageLevelEnergyDecrease: 0,
      batteryUsableStorageLevelEnergyDiff: 0,
      batteryUsableStorageLevelEnergyIncrease: 0,
      batteryUsableStorageLevelEnergyDecrease: 0,
    },
    meta: {
      notYetComputed: true,
    },
  },
  preComputedData: {
    solar: {
      cache: [],
      active: {
        hash: '',
        data: {
          lowRes: [],
          highRes: [],
          sum: 0,
          max: 0,
        },
      },
      compare: {
        a: {
          state: 'NOT_INITIALIZED',
        },
        b: {
          state: 'NOT_INITIALIZED',
        },
      },
    },
  },
};

export default computed;

function computed(state = initialState, action: ActionTypes): IComputedState {
  switch (action.type) {
    case SET_ACTIVE_COMPUTED_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        activeComputedData: _.clone(data),
      };
    }
    case ADD_COMPUTED_DATA: {
      const { scenarioId, data } = action.payload;
      return {
        ...state,
        computedDataSets: { ...state.computedDataSets, [scenarioId]: data },
      };
    }
    case DELETE_COMPUTED_DATA: {
      const { scenarioId } = action.payload;
      return {
        ...state,
        computedDataSets: _.omit(state.computedDataSets, [scenarioId]),
      };
    }
    case CHANGE_ACTIVE_PRE_COMPUTED_DATA: {
      const { data, hash } = action.payload;
      const { cache: oldCache } = state.preComputedData.solar;
      return {
        ...state,
        preComputedData: {
          solar: {
            ...state.preComputedData.solar,
            active: {
              data,
              hash,
            },
            cache: updateCache(oldCache, data, hash),
          },
        },
      };
    }
    case CHANGE_COMPARE_PRE_COMPUTED_DATA: {
      const { ab, data, hash } = action.payload;
      const { cache: oldCache } = state.preComputedData.solar;
      return {
        ...state,
        preComputedData: {
          solar: {
            ...state.preComputedData.solar,
            compare: {
              ...state.preComputedData.solar.compare,
              [ab]: {
                state: 'CALCULATED',
                data,
                hash,
              },
            },
            cache: updateCache(oldCache, data, hash),
          },
        },
      };
    }
    case REQUEST_COMPARE_PRE_COMPUTED_DATA: {
      const { ab, scenarioId } = action.payload;
      return {
        ...state,
        preComputedData: {
          solar: {
            ...state.preComputedData.solar,
            compare: {
              ...state.preComputedData.solar.compare,
              [ab]: {
                state: 'REQUESTED',
                scenarioId,
              },
            },
          },
        },
      };
    }
    case ADD_PRE_COMPUTED_CACHE_ENTRY: {
      const { data, hash } = action.payload;
      const { cache: oldCache } = state.preComputedData.solar;
      return {
        ...state,
        preComputedData: {
          solar: {
            ...state.preComputedData.solar,
            cache: updateCache(oldCache, data, hash),
          },
        },
      };
    }
    default:
      return state;
  }
}

// Returns a new cache. Does not mutate old cache
function updateCache(
  oldCache: SolarLoadPreScalingCacheEntry[],
  data: SolarLoadPreScalingData,
  hash: string,
): SolarLoadPreScalingCacheEntry[] {
  const cacheIndex = oldCache.findIndex(
    (cacheEntry) => cacheEntry.hash === hash,
  );
  const newCache = _.clone(oldCache);
  if (cacheIndex >= 0) {
    newCache.splice(cacheIndex, 1);
  } else if (newCache.length > 10) {
    newCache.splice(-1, 1);
  }
  return [{ hash, data }, ...newCache];
}
