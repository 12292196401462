import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, Divider, makeStyles, Theme, Typography,
} from '@material-ui/core';
import YearAndScaling from '../input-combined/YearAndScaling';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_ENERGY_UNIT } from '../../../../domain_model/math/EnergyUnit';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const GRAPH_LIMIT = new Quantity(30000, DB_ENERGY_UNIT, false);

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr',
  },
  divider: {
    marginTop: theme.spacing(PARAMETER_SPACING),
  },
}));

const ImportExportParams = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const importInfoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: '',
      // TODO: add en doku paragraph
      en: '',
    },
  };

  const exportInfoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: '',
      // TODO: add en doku paragraph
      en: '',
    },
  };

  return (
    <div className={classes.root}>
      <Typography variant="overline">
        {t('paramsImportTitle')}
      </Typography>
      <YearAndScaling
        section="import"
        scalingLabel={t('paramsImport')}
        graphLimit={GRAPH_LIMIT}
        originalUnit={DB_ENERGY_UNIT}
        infoButtonProps={importInfoButtonProps}
      />

      <Divider className={classes.divider} />

      <Typography variant="overline">
        {t('paramsExportTitle')}
      </Typography>
      <YearAndScaling
        section="export"
        scalingLabel={t('paramsExport')}
        graphLimit={GRAPH_LIMIT}
        originalUnit={DB_ENERGY_UNIT}
        infoButtonProps={exportInfoButtonProps}
      />
    </div>
  );
};

export default ImportExportParams;
