import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { CSSObject, SeriesOptionsType } from 'highcharts';
import { amber, blue } from '@material-ui/core/colors';
import _ from 'lodash';
import clsx from 'clsx';
import { chartColors } from '../../../domain_model/Colors';
import { mapUnitScopeToProps } from '../../../redux/mappers';
import EnergyUnit, { DB_ENERGY_UNIT } from '../../../domain_model/math/EnergyUnit';
import { NumberConverter } from '../../../domain_model/math/Unit';
import { beautifyNumber } from '../../helper';

type ProductionConsumption = {
  production: number;
  consumption: number;
}

type ScenarioName = {
  name: string;
}

export type ScenarioData = {
  nuclear: ProductionConsumption;
  thermal: ProductionConsumption;
  solar: ProductionConsumption;
  wind: ProductionConsumption;
  river: ProductionConsumption;
  dam: ProductionConsumption;
  pump: ProductionConsumption;
  battery: ProductionConsumption;
  custom: ProductionConsumption;
  importExport: ProductionConsumption;
  shortageExcess: ProductionConsumption;
  networkLoss: ProductionConsumption;
}

type ScenarioInfo = (ScenarioName & { data: ScenarioData });

type Props = PropsFromRedux & {
  // Bug
  // eslint-disable-next-line react/no-unused-prop-types
  height: number;
  scenarioAData: ScenarioInfo | undefined;
  scenarioBData?: ScenarioInfo;
  className?: string;
};

const useStyles = makeStyles<Theme, Props>(() => createStyles({
  root: {
    height: ({ height }) => `${height}em`,
  },
  highCharts: {
    height: '100%',
  },
}));

const legendItemStyle: CSSObject = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '0.75rem',
  fontWeight: '400',
  letterSpacing: '0.03333em',
};

const notNulPredicate = ([, { production, consumption }]: [string, ProductionConsumption]) => (
  production >= 0.01 || consumption >= 0.01
);

function getCategories(scenarioAData: ScenarioData, scenarioBData?: ScenarioData) {
  const orderedCategoryKeys: (keyof ScenarioData)[] = [
    'nuclear',
    'thermal',
    'solar',
    'wind',
    'river',
    'dam',
    'pump',
    'battery',
    'custom',
    'importExport',
    'shortageExcess',
    'networkLoss',
  ];
  const nonNulCategoryKeys = _.union(
    _.filter(Object.entries(scenarioAData), notNulPredicate).map(([key]) => key),
    scenarioBData
      ? _.filter(Object.entries(scenarioBData), notNulPredicate).map(([key]) => key)
      : [],
  );
  return _.filter(orderedCategoryKeys, (key) => nonNulCategoryKeys.includes(key));
}

const getSeriesName = (
  prodCons: 'Production' | 'Consumption',
  t: (s: string) => string,
  scenarioName?: string,
) => (
  scenarioName
    ? `${scenarioName} - ${t(`graphYearlyBalance${prodCons}`)}`
    : t(`graphYearlyBalance${prodCons}`)
);

function getSeriesNameForSpecificSection(
  nonSpecificSeriesName: string,
  section: string,
  t: (s: string) => string,
  scenarioNames?: string[],
) {
  switch (section) {
    case t('graphYearlyBalanceImportExport'):
      if (scenarioNames !== undefined) {
        for (let i = 0; i < scenarioNames.length; i += 1) {
          const scenarioName = scenarioNames[i];
          if (nonSpecificSeriesName === getSeriesName('Production', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryImport')}`;
          }
          if (nonSpecificSeriesName === getSeriesName('Consumption', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryExport')}`;
          }
        }
      } else {
        if (nonSpecificSeriesName === getSeriesName('Production', t)) {
          return t('summaryImport');
        }
        if (nonSpecificSeriesName === getSeriesName('Consumption', t)) {
          return t('summaryExport');
        }
      }
      throw new Error('unknown non specific series name');
    case t('graphYearlyBalanceShortageExcess'):
      if (scenarioNames !== undefined) {
        for (let i = 0; i < scenarioNames.length; i += 1) {
          const scenarioName = scenarioNames[i];
          if (nonSpecificSeriesName === getSeriesName('Production', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryShortage')}`;
          }
          if (nonSpecificSeriesName === getSeriesName('Consumption', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryExcess')}`;
          }
        }
      } else {
        if (nonSpecificSeriesName === getSeriesName('Production', t)) {
          return t('summaryShortage');
        }
        if (nonSpecificSeriesName === getSeriesName('Consumption', t)) {
          return t('summaryExcess');
        }
      }
      throw new Error('unknown non specific series name');
    case t('graphYearlyBalancePump'):
      if (scenarioNames !== undefined) {
        for (let i = 0; i < scenarioNames.length; i += 1) {
          const scenarioName = scenarioNames[i];
          if (nonSpecificSeriesName === getSeriesName('Production', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryPumpProduction')}`;
          }
          if (nonSpecificSeriesName === getSeriesName('Consumption', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryPumpConsumption')}`;
          }
        }
      } else {
        if (nonSpecificSeriesName === getSeriesName('Production', t)) {
          return t('summaryPumpProduction');
        }
        if (nonSpecificSeriesName === getSeriesName('Consumption', t)) {
          return t('summaryPumpConsumption');
        }
      }
      throw new Error('unknown non specific series name');
    case t('graphYearlyBalanceBattery'):
      if (scenarioNames !== undefined) {
        for (let i = 0; i < scenarioNames.length; i += 1) {
          const scenarioName = scenarioNames[i];
          if (nonSpecificSeriesName === getSeriesName('Production', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryBatteryProduction')}`;
          }
          if (nonSpecificSeriesName === getSeriesName('Consumption', t, scenarioName)) {
            return `${scenarioName} - ${t('summaryBatteryConsumption')}`;
          }
        }
      } else {
        if (nonSpecificSeriesName === getSeriesName('Production', t)) {
          return t('summaryBatteryProduction');
        }
        if (nonSpecificSeriesName === getSeriesName('Consumption', t)) {
          return t('summaryBatteryConsumption');
        }
      }
      throw new Error('unknown non specific series name');
    default:
      return nonSpecificSeriesName;
  }
}


function getSeries(
  scenarioAData: ScenarioInfo,
  scenarioBData: ScenarioInfo | undefined,
  categories: (keyof ScenarioData)[],
  converter: (x: number) => number,
  t: (s: string) => string,
): Array<SeriesOptionsType> {
  let result: Array<SeriesOptionsType> = [
    {
      name: getSeriesName('Production', t, scenarioBData ? scenarioAData.name : ''),
      color: amber[100],
      data: _.filter(
        Object.entries(scenarioAData.data),
        ([key]) => categories.includes(key as keyof ScenarioData),
      ).map(([, { production }]) => (production > 0.01 ? converter(production) : null)),
      stack: 'A',
      type: 'column',
      pointPadding: scenarioBData ? -0.7 : -0.5,
      pointPlacement: scenarioBData ? 0.10 : 0.15,
    },
    {
      name: getSeriesName('Consumption', t, scenarioBData ? scenarioAData.name : ''),
      color: amber[500],
      data: _.filter(
        Object.entries(scenarioAData.data),
        ([key]) => categories.includes(key as keyof ScenarioData),
      ).map(([, { consumption }]) => (consumption > 0.01 ? converter(consumption) : null)),
      stack: 'A',
      type: 'column',
      pointPadding: scenarioBData ? 0.02 : 0,
      pointPlacement: scenarioBData ? -0.04 : -0.15,
    },
  ];

  if (scenarioBData) {
    result = result.concat([
      {
        name: getSeriesName('Production', t, scenarioBData.name),
        color: blue[200],
        data: _.filter(
          Object.entries(scenarioBData.data),
          ([key]) => categories.includes(key as keyof ScenarioData),
        ).map(([, { production }]) => (production > 0.01 ? converter(production) : null)),
        stack: 'B',
        type: 'column',
        pointPadding: -0.7,
        pointPlacement: 0.2,
      },
      {
        name: getSeriesName('Consumption', t, scenarioBData.name),
        color: blue[600],
        data: _.filter(
          Object.entries(scenarioBData.data),
          ([key]) => categories.includes(key as keyof ScenarioData),
        ).map(([, { consumption }]) => (consumption > 0.01 ? converter(consumption) : null)),
        stack: 'B',
        type: 'column',
        pointPadding: 0.02,
        pointPlacement: 0.06,
      },
    ]);
  }

  return result;
}

const getCategoryLocalizationKey = (category: string) => (
  `graphYearlyBalance${category.charAt(0).toUpperCase()}${category.slice(1)}`
);

function getOptions(
  scenarioA: ScenarioInfo,
  scenarioB: ScenarioInfo | undefined,
  t: (x: string) => string,
  converter: (x: number) => number,
  unit: string,
): Highcharts.Options {
  const categories = getCategories(scenarioA.data, scenarioB && scenarioB.data);
  return {
    chart: {
      spacing: [20, 0, 0, 60],
      zoomType: 'x',
    },
    colors: chartColors,
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        align: 'left',
        x: -60,
        y: -2,
        formatter() {
          return `${beautifyNumber(this.value, 0, true)} ${unit}`;
        },
      },
    },
    xAxis: {
      categories: categories.map((category) => t(getCategoryLocalizationKey(category))),
    },
    legend: {
      enabled: true,
      align: 'left',
      alignColumns: false,
      padding: 0,
      margin: 12,
      itemMarginTop: 8,
      itemMarginBottom: 4,
      itemStyle: legendItemStyle,
    },
    tooltip: {
      shared: true,
      valueDecimals: 1,
      formatter() {
        return this.points && this.points.reduce((partial, point) => (
          `${partial}<br/>${
            (getSeriesNameForSpecificSection(
              point.series.name,
              this.x as unknown as string,
              t,
              scenarioB ? [scenarioA.name, scenarioB.name] : undefined,
            ))}: ${beautifyNumber(point.y, point.y < 10 ? 2 : 0, true)} ${unit}`
        ), `<b>${this.x}</b>`);
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: getSeries(scenarioA, scenarioB && scenarioB, categories, converter, t),
  };
}

const Chart = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    unitScope,
    className,
    scenarioBData,
    scenarioAData,
  } = props;

  const displayEnergyUnit = EnergyUnit.createEnergyUnit(unitScope, DB_ENERGY_UNIT.base);
  const energyConverter = DB_ENERGY_UNIT.getConverterTo(displayEnergyUnit) as NumberConverter;

  return (
    <div className={clsx(className || '', classes.root)}>
      {scenarioAData && (
        <HighchartsReact
          highcharts={Highcharts}
          options={getOptions(
            scenarioAData,
            scenarioBData,
            t,
            energyConverter,
            displayEnergyUnit.name,
          )}
          containerProps={{
            className: classes.highCharts,
          }}
        />
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitScopeToProps, {});

export default connector(Chart);
