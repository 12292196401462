import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GetRailProps } from 'react-compound-slider';


const useStyles = makeStyles(() => createStyles({
  common: {
    position: 'absolute',
    width: '100%',
    transform: 'translate(0%, -50%)',
  },
  outer: {
    height: 42,
    cursor: 'pointer',
  },
  inner: {
    height: 1,
    borderRadius: 0,
    pointerEvents: 'none',
    backgroundColor: 'rgb(205,205,205)',
  },
}));

type Props = {
  getRailProps: GetRailProps;
}

const SliderRail = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { getRailProps } = props;

  return (
    <>
      <div
        className={clsx(classes.common, classes.outer)}
        {...getRailProps()}
      />
      <div className={clsx(classes.common, classes.inner)} />
    </>
  );
};

export default SliderRail;
