import _ from 'lodash';
import { ArbitrarySection } from '../data/sharedTypes';


export const dataSeriesSections = [
  'endUser',
  'import',
  'export',
  'nuclear',
  'thermal',
  'river',
  'solarDirectA',
  'solarDiffuseA',
  'solarDirectB',
  'solarDiffuseB',
  'solarDirectC',
  'solarDiffuseC',
  'wind',
  'dam',
] as const;
export type DataSeriesSection = typeof dataSeriesSections[number];

export enum CustomDataSeriesSections {
  CustomConsumption = 'customConsumption',
  CustomProduction = 'customProduction',
}

export enum DataStatus {
  NotRequested,
  FetchInProgress,
  Available
}

export type DataSeries = number[];
export type ByYearOrSlug<T> = { [year: string]: T | undefined }
export type BySection<T> = Record<DataSeriesSection, T>

export interface IDataSeriesDto {
  type: DataSeriesSection;
  year: number;
  data: DataSeries;
}

export interface IPreloadDataDto {
  type: DataSeriesSection;
  data: IPreloadDataCollectionDto;
}

export interface IPreloadSecondaryDataDto {
  data: Omit<IPreloadSecondaryDataCollection, 'thetaZ'>;
}

export interface IPreloadDataCollectionDto {
  lowResSectionDataCollection: BySection<ByYearOrSlug<DataSeries>>;
  maxSectionDataCollection: BySection<ByYearOrSlug<number>>;
  sumSectionDataCollection: BySection<ByYearOrSlug<number>>;
  availableYearsInApi: number[];
  arbitraryWithoutData: {
    type: ArbitrarySection;
    slug: string;
    order: number;
  }[];
}

export interface IPreloadDataCollection {
  lowResSectionDataCollection: BySection<ByYearOrSlug<DataSeries>>;
  maxSectionDataCollection: BySection<ByYearOrSlug<number>>;
  sumSectionDataCollection: BySection<ByYearOrSlug<number>>;
  availableYearsInApi: string[];
  arbitraryWithoutData: {
    type: ArbitrarySection;
    slug: string;
    order: number;
  }[];
}

export interface IPreloadSecondaryDataCollection {
  thetaA: DataSeries;
  thetaE: DataSeries;
  thetaZ: DataSeries;
}

export const initBySection = <T>(initVal: T): BySection<T> => dataSeriesSections
  .reduce((partial: Partial<BySection<T>>, val) => {
    // eslint-disable-next-line no-param-reassign
    partial[val] = _.clone(initVal);
    return partial;
  }, {}) as BySection<T>;

export type SourceDataCollectionDTO = {
  sectionDataCollection: BySection<ByYearOrSlug<DataSeries>>;
  sectionDataStatus: BySection<ByYearOrSlug<DataStatus>>;
}

export default class SourceDataCollection {
  private readonly _sectionDataCollection: BySection<ByYearOrSlug<DataSeries>> = initBySection({});
  private readonly _sectionDataStatus: BySection<ByYearOrSlug<DataStatus>> = initBySection({});

  private get sectionDataCollection() {
    return this._sectionDataCollection;
  }

  private get sectionDataStatus() {
    return this._sectionDataStatus;
  }

  public constructor(other?: SourceDataCollection, dto?: SourceDataCollectionDTO) {
    if (other) {
      this._sectionDataCollection = _.cloneDeep(other.sectionDataCollection);
      this._sectionDataStatus = _.cloneDeep(other.sectionDataStatus);
    }
    if (dto) {
      this._sectionDataCollection = dto.sectionDataCollection;
      this._sectionDataStatus = dto.sectionDataStatus;
    }
  }

  public getOneYearDataSeries(dataSection: DataSeriesSection, yearOrSlug: string): DataSeries {
    if (this._sectionDataStatus[dataSection][yearOrSlug] !== DataStatus.Available) {
      throw new Error('Requested dataSeries is not available');
    }
    return _.clone(this._sectionDataCollection[dataSection][yearOrSlug] as DataSeries);
  }

  public getOneYearDataSeriesStatus(
    dataSection: DataSeriesSection, yearOrSlug: string,
  ): DataStatus {
    return this._sectionDataStatus[dataSection][yearOrSlug] || DataStatus.NotRequested;
  }

  public addDataSeries(
    dataSection: DataSeriesSection,
    yearOrSlug: string,
    data: number[],
  ): SourceDataCollection {
    this._sectionDataCollection[dataSection][yearOrSlug] = _.clone(data);
    this._sectionDataStatus[dataSection][yearOrSlug] = DataStatus.Available;
    return (this);
  }

  public setFetchingFlag(dataSection: DataSeriesSection, yearOrSlug: string): SourceDataCollection {
    this._sectionDataStatus[dataSection][yearOrSlug] = DataStatus.FetchInProgress;
    return (this);
  }

  public copy(): SourceDataCollection {
    return new SourceDataCollection(this);
  }

  public toDTO(): SourceDataCollectionDTO {
    return {
      sectionDataCollection: this._sectionDataCollection,
      sectionDataStatus: this._sectionDataStatus,
    };
  }
}
