export default class Column<T extends (number | string)> {
  public header: string;
  public data: (T)[] = [];

  constructor(header: string, data?: T[]) {
    this.header = header;
    if (data !== undefined) {
      this.data = data;
    }
  }
}
