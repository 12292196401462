import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import EqComponent from './EqComponent';
import { changeParams } from '../../../../../redux/actions';
import { mapParamsToProps } from '../../../../../redux/mappers';
import { IPvOrientation } from '../../../../../domain_model/Parameters';

type Props = PropsFromRedux & {
  className?: string;
  type: keyof IPvOrientation;
  repeatFirst?: boolean;
}

const SolarEqSliders = (props: Props) => {
  const {
    className, type, repeatFirst, changeParams: _changeParams,
  } = props;
  const { activeParams: { solar: { [type]: weightedValues } } } = props;

  const values = weightedValues.map((x) => x.weight);
  const labels = weightedValues.map((x) => x.label).filter((x) => x !== undefined) as string[];

  // Duplicate the first slider (N) and add it to the end
  const concatFirstToEnd = <T extends unknown>(array: Array<T>) => array.concat(array[0]);

  function updateValue(index: number, newValue: number) {
    const newValues = _.cloneDeep(weightedValues);
    newValues[index % (values.length)].weight = newValue;
    _changeParams({ solar: { [type]: newValues } });
  }

  return (
    <EqComponent
      className={className}
      values={repeatFirst ? concatFirstToEnd(values) : values}
      labels={repeatFirst ? concatFirstToEnd(labels) : labels}
      onValueChanged={updateValue}
    />
  );
};


type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(SolarEqSliders);
