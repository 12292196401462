import React from 'react';
import {
  createStyles, Grid, makeStyles, Theme,
} from '@material-ui/core';
import Header from './header/Header';
import Content from './main-section/Content';
import ParameterDrawer from './scenario/ScenarioDrawer';
import { ACCORDION_WIDTH, DESKTOP_SPACING } from '../domain_model/GlobalStyleConstants';
import ClosedScenarioDrawer from './ClosedScenarioDrawer';
import CalculationLoadingScreen from './CalculationLoadingScreen';
import Comparison from './comparison/ComparisonDialog';
import ConnectionErrorLoopNotification from './ConnectionErrorLoopNotification';

const useStyles = makeStyles((theme: Theme) => createStyles({
  subRoot: {
    flexGrow: 1,
    paddingRight: theme.spacing(DESKTOP_SPACING),
    paddingLeft: theme.spacing(ACCORDION_WIDTH + DESKTOP_SPACING),
    paddingTop: theme.spacing(DESKTOP_SPACING - 4),
    paddingBottom: 0,
  },
  bottom: {
    paddingBottom: '0 !important',
  },
}));

const PageLayout = (): JSX.Element => {
  const classes = useStyles();

  const innerLayout = (
    <div className={classes.subRoot}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} className={classes.bottom}>
          <Content />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <ConnectionErrorLoopNotification />
      <CalculationLoadingScreen />
      <ParameterDrawer />
      <ClosedScenarioDrawer />
      <Comparison />
      {innerLayout}
    </div>
  );
};

export default PageLayout;
