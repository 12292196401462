import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

type BorderLabel = {
  label: string;
  border: string;
};

type Props = {
  entry: BorderLabel;
}

const useStyles = makeStyles(() => createStyles({
  label: {
    marginRight: '1.6em',
  },
  line: {
    width: '2em',
    height: '5px',
    borderTop: (props: Props) => props.entry.border,
    marginRight: '0.6em',
    display: 'inline-block',
  },
}));

function LineLabel(props: Props): JSX.Element {
  const { entry: { label } } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.label}>
      <Typography variant="caption">
        <span className={classes.line} />
        {label}
      </Typography>
    </div>
  );
}

export default LineLabel;
