import { createSelector } from 'reselect';
import { IControllerState, IReduxState } from './types';
import {
  IPreloadDataCollection,
  IPreloadSecondaryDataCollection,
} from '../domain_model/SourceDataCollection';
import { IComputedState } from './types/computedTypes';
import { ISizeState, IUnitState } from './types/miscTypes';
import { IParameterState } from './types/parameterTypes';
import { ICompareState, IHighlightState, IUiState } from './types/uiTypes';
import { IEMobility } from '../domain_model/Parameters';
import { IComputedDataFlat, IPreComputedData } from '../domain_model/ComputedDataCollection';
import { generateHash } from '../controller/helper';


// 1:1 mappers

export const mapSizeToProps = (state: IReduxState): ISizeState => (state.size);

export const mapUnitToProps = (state: IReduxState): IUnitState => (state.unit);

export const mapComputedToProps = (state: IReduxState): IComputedState => (state.computed);

export const mapParamsToProps = (state: IReduxState): IParameterState => (state.parameter);

export const mapUiStateToProps = (state: IReduxState): IUiState => (state.uiState);


// Partial mappers

const getPreComputedData = (state: IReduxState): IPreComputedData => (
  state.computed.preComputedData
);

export const mapPreloadDataToProps = (state: IReduxState): IPreloadDataCollection => (
  state.data.preloadData
);

export const mapPreloadSecondaryDataToProps = (
  state: IReduxState,
): IPreloadSecondaryDataCollection => (
  state.data.preloadSecondaryData
);

export const mapExtendedSectionToProps = (state: IReduxState): Pick<IUiState, 'expandedSection'> => (
  { expandedSection: state.uiState.expandedSection }
);

export const mapOverviewOpenToProps = (state: IReduxState): Pick<IUiState, 'overviewOpen'> => (
  { overviewOpen: state.uiState.overviewOpen }
);

export const mapParamsOpenToProps = (state: IReduxState): Pick<IUiState, 'paramsOpen'> => (
  { paramsOpen: state.uiState.paramsOpen }
);

export const mapParamsScenarioSelectOpenToProps = (state: IReduxState): Pick<IUiState, 'paramsScenarioSelectOpen'> => (
  { paramsScenarioSelectOpen: state.uiState.paramsScenarioSelectOpen }
);

export const mapCalcStatesToProps = (state: IReduxState): Pick<IUiState, 'calculationStates'> => (
  { calculationStates: state.uiState.calculationStates }
);

export const mapCalcStateAndParamsOpenToProps = (state: IReduxState): Pick<IUiState, 'calculationStates' | 'paramsOpen'> => (
  {
    calculationStates: state.uiState.calculationStates,
    paramsOpen: state.uiState.paramsOpen,
  }
);

export const mapActiveComputedToPropsFlat = (state: IReduxState): {
  activeComputedData: IComputedDataFlat;
} => (
  {
    activeComputedData: {
      ...state.computed.activeComputedData.scenarioInfo,
      ...state.computed.activeComputedData.basicLoads,
      ...state.computed.activeComputedData.specialLoads,
      ...state.computed.activeComputedData.customData,
      ...state.computed.activeComputedData.computedStorage,
      ...state.computed.activeComputedData.computedMisc,
      ...state.computed.activeComputedData.computedSummary,
      ...state.computed.activeComputedData.meta,
    },
  }
);

export const mapActiveComputedToProps = (state: IReduxState): Pick<IComputedState, 'activeComputedData'> => (
  { activeComputedData: state.computed.activeComputedData }
);

export const mapUnitScopeToProps = (state: IReduxState): Pick<IUnitState, 'unitScope'> => ({
  unitScope: state.unit.unitScope,
});

export const mapCompareStateToProps = (state: IReduxState): ICompareState => (
  state.uiState.compareState
);

export const mapLandingPageOpenToProps = (state: IReduxState): Pick<IUiState, 'landingPageOpen'> => (
  { landingPageOpen: state.uiState.landingPageOpen }
);

export const mapHighlightCalculateButtonToProps = (state: IReduxState): Pick<IHighlightState, 'highlightCalculateButton'> => (
  { highlightCalculateButton: state.uiState.highlightState.highlightCalculateButton }
);

export const mapHighlightScenarioSelectToProps = (state: IReduxState): Pick<IHighlightState, 'highlightScenarioSelect'> => (
  { highlightScenarioSelect: state.uiState.highlightState.highlightScenarioSelect }
);

export const mapHighlightParameterSectionsToProps = (state: IReduxState): Pick<IHighlightState, 'highlightParameterSections'> => (
  { highlightParameterSections: state.uiState.highlightState.highlightParameterSections }
);

export const mapHighlightSaveButtonToProps = (state: IReduxState): Pick<IHighlightState, 'highlightSaveButton'> => (
  { highlightSaveButton: state.uiState.highlightState.highlightSaveButton }
);

export const mapGraphStyleToProps = (state: IReduxState): Pick<IUiState, 'graphStyle'> => (
  { graphStyle: state.uiState.graphStyle }
);


export const mapGraphResolutionToProps = (state: IReduxState): Pick<IUiState, 'graphResolution'> => (
  { graphResolution: state.uiState.graphResolution }
);

export const mapEMobilityParamsToProps = (state: IReduxState): IEMobility => (
  { additionalEMobilityEnergy: state.parameter.activeParams.endUser.additionalEMobilityEnergy }
);

export const mapPageCloseDialogParamsTopProps = (state: IReduxState): Pick<IParameterState, 'scenarios' | 'activeScenario'> => (
  { scenarios: state.parameter.scenarios, activeScenario: state.parameter.activeScenario }
);

// Combined mappers

export const mapUnitAndActiveComputedToProps = (state: IReduxState): IUnitState & Pick<IComputedState, 'activeComputedData'> => ({
  ...state.unit,
  activeComputedData: state.computed.activeComputedData,
});

export const mapUnitScopeAndActiveComputedToProps = (state: IReduxState): Pick<IUnitState, 'unitScope'> & Pick<IComputedState, 'activeComputedData'> => ({
  unitScope: state.unit.unitScope,
  activeComputedData: state.computed.activeComputedData,
});

export const mapParamsAndPreloadToProps = (state: IReduxState): IParameterState
& IPreloadDataCollection => ({
  ...state.parameter,
  ...state.data.preloadData,
});


// Calculated Mappers

export const mapPreComputedToProps = createSelector(
  [getPreComputedData, mapParamsToProps],
  (preComputed, params) => ({
    preComputedData: {
      ...preComputed,
      solar: {
        ...preComputed.solar,
        active: {
          ...preComputed.solar.active,
          isUpToDate: preComputed.solar.active.hash === generateHash(params.activeParams.solar),
        },
      },
    },
  }),
);


const getControllerState = (state: IReduxState): IControllerState => ({
  ...state.parameter,
  ...state.data,
  ...state.uiState,
  ...state.computed,
});

export const mapControllerStateToProps = createSelector(
  [getControllerState, mapPreComputedToProps],
  (controllerState, preComputed) => ({
    ...controllerState,
    ...preComputed,
  }),
);
