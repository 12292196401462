import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => createStyles({
  input: {
    display: 'none',
  },
  button: {
    // margin: theme.spacing.unit,
  },
}));


type Props = {
  text: string;
  title: string;
  open: boolean;
  onClose: () => void;
  handleInput: (files: FileList | null) => void;
  accept: string;
}

const GenericLoadFromFileDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    onClose, open, handleInput, title, text, accept,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {text.split('\n').map((line, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DialogContentText key={`dialogTextPart-${i}`}>
            {line}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>

        <label htmlFor="raised-button-file">
          <input
            accept={accept}
            className={classes.input}
            id="raised-button-file"
            type="file"
            onChange={(e) => handleInput(e.target.files)}
          />
          <Button
            variant="contained"
            component="span"
            className={classes.button}
            color="primary"
          >
            {t('selectFile')}
          </Button>
        </label>

      </DialogActions>
    </Dialog>
  );
};

export default GenericLoadFromFileDialog;
