import { IComputedSummary } from '../../../domain_model/ComputedDataCollection';
import { ScenarioData } from './Chart';

export const getChartData = (computed: IComputedSummary): ScenarioData => ({
  nuclear: { consumption: 0, production: computed.nuclearEnergyProduction },
  thermal: { consumption: 0, production: computed.thermalEnergyProduction },
  solar: { consumption: 0, production: computed.solarEnergyProduction },
  wind: { consumption: 0, production: computed.windEnergyProduction },
  river: { consumption: 0, production: computed.riverEnergyProduction },
  dam: { consumption: 0, production: computed.damEnergyProduction },
  pump: {
    consumption: computed.pumpEnergyConsumption,
    production: computed.pumpEnergyProduction,
  },
  battery: {
    consumption: computed.batteryEnergyConsumption,
    production: computed.batteryEnergyProduction,
  },
  custom: {
    consumption: computed.customEnergyConsumption,
    production: computed.customEnergyProduction,
  },
  importExport: {
    consumption: computed.importEnergy,
    production: computed.exportEnergy,
  },
  shortageExcess: {
    consumption: computed.excessEnergy,
    production: computed.shortageEnergy,
  },
  networkLoss: {
    consumption: computed.networkLossEnergyConsumption,
    production: 0,
  },
});
