import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';
import EqSlider from './EqSlider';
import EqLabel from './EqLabel';

const sliderWidth = 20;

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'grid',
    gridTemplateAreas: `"slider"
                        "label "`,
    gridTemplateRows: '1fr auto',
    maxHeight: '9em',
  },
  svg: {
    gridArea: 'slider',
    width: '100%',
    height: '100%',
    preserveAspectRatio: 'none',
    fill: red[200],
    fillOpacity: 0.75,
    stroke: red[200],
  },
  sliderContainer: {
    gridArea: 'slider',
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 -${sliderWidth / 2}px`,
  },
  labelContainer: {
    gridArea: 'label',
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 -${sliderWidth / 2}px`,
    paddingTop: '0.3em',
  },
}));

type Point = {
  x: number;
  y: number;
}

type Props = {
  values: number[];
  labels: string[];
  onValueChanged: (index: number, newValue: number) => void;
  className?: string;
}

const EqComponent = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    labels, onValueChanged, values, className,
  } = props;

  function generateSvgPoints(): string {
    let points: Point[] = [
      { x: 100, y: 100 },
      { x: 0, y: 100 },
    ];
    points = points.concat(values.map((value, i) => ({
      x: (100 / (values.length - 1)) * i, y: 100 - value,
    })));
    return points.map((p) => `${p.x},${p.y}`).join(' ');
  }

  return (
    <div className={clsx(classes.root, className)}>
      <svg className={classes.svg} viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points={generateSvgPoints()} />
      </svg>
      <div className={classes.sliderContainer}>
        {values.map((val, index) => ((
          <EqSlider
            key={index}
            val={val}
            setVal={(newVal) => onValueChanged(index, newVal)}
            sliderWidth={sliderWidth}
          />
        )))}
      </div>
      <div className={classes.labelContainer}>
        {labels.map((label, index) => (
          <EqLabel key={`label-${index}`} label={label} sliderWidth={sliderWidth} />
        ))}
      </div>
    </div>
  );
};

export default EqComponent;
