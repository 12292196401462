import React from 'react';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  SliderItem,
  GetHandleProps,
} from 'react-compound-slider';

const useStyles = makeStyles((theme: Theme) => {
  const colors = {
    primary: theme.palette.primary.main,
    thumbOutline: fade(theme.palette.primary.main, 0.16),
  };

  return createStyles({
    common: {
      position: 'absolute',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    },
    outer: {
      zIndex: 5,
      width: 20,
      height: 40,
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      backgroundColor: 'none',
    },
    inner: {
      zIndex: 2,
      width: 12,
      height: 12,
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
      backgroundColor: colors.primary,
    },
    active: {
      boxShadow: `0px 0px 0px 16px ${colors.thumbOutline}`,
    },
  });
});

type Props = {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  activeHandleID: string;
};

const Handle = (props: Props): JSX.Element => {
  const {
    activeHandleID,
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
  } = props;
  const classes = useStyles();

  const active = activeHandleID === id;

  return (
    <>
      <div
        className={clsx(classes.common, classes.outer)}
        style={{ left: `${percent}%` }}
        {...getHandleProps(id)}
      />
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className={clsx(
          classes.common,
          classes.inner,
          active && classes.active,
        )}
        style={{ left: `${percent}%` }}
      />
    </>
  );
};

export default Handle;
