import _ from 'lodash';
import { IParameterCollection } from '../Parameters';
import { toEnergy } from '../math/helpers';
import { getStatusQuo, MOST_CURRENT_YEAR_FALLBACK } from '../../predefinedScenarios';
import { NonLocalizedString } from './NonLocalizedString';
import Scenario, { ScenarioDTO } from './Scenario';
import {
  IParameterCollectionV0,
  IParameterCollectionV1,
  IParameterCollectionV2,
  IParameterCollectionV3,
  IParameterCollectionV4,
  IParameterCollectionV5,
  IParameterCollectionV6,
  IParameterCollectionV7, IParameterCollectionV8,
} from '../ParametersLegacy';
import { assertUnreachable } from '../../helper';

enum Version {
  LegacyV0 = 0,
  LegacyV1 = 1,
  LegacyV2 = 2,
  LegacyV3 = 3,
  LegacyV4 = 4,
  LegacyV5 = 5,
  LegacyV6 = 6,
  LegacyV7 = 7,
  LegacyV8 = 8,
  Current = 9,
}

export default class ScenarioConverter {
  static fromDTO(scenarioDTO: ScenarioDTO): Scenario {
    let { parameter } = scenarioDTO;
    let version: Version;
    if ((parameter as IParameterCollection).version > 1) {
      version = (parameter as IParameterCollection).version;
    } else if (
      Array.isArray((parameter as IParameterCollectionV0).customConsumption)
      || Array.isArray((parameter as IParameterCollectionV0).customProduction)
    ) {
      version = Version.LegacyV0;
    } else {
      version = Version.LegacyV1;
    }

    if (version === Version.LegacyV0) {
      parameter = ScenarioConverter.convertV0ToV1((parameter as IParameterCollectionV0));
      version = Version.LegacyV1;
    }
    if (version === Version.LegacyV1) {
      parameter = ScenarioConverter.convertV1ToV2((parameter as IParameterCollectionV1));
      version = Version.LegacyV2;
    }
    if (version === Version.LegacyV2) {
      parameter = ScenarioConverter.convertV2ToV3((parameter as IParameterCollectionV2));
      version = Version.LegacyV3;
    }
    if (version === Version.LegacyV3) {
      parameter = ScenarioConverter.convertV3ToV4((parameter as IParameterCollectionV3));
      version = Version.LegacyV4;
    }
    if (version === Version.LegacyV4) {
      parameter = ScenarioConverter.convertV4ToV5((parameter as IParameterCollectionV4));
      version = Version.LegacyV5;
    }
    if (version === Version.LegacyV5) {
      parameter = ScenarioConverter.convertV5ToV6((parameter as IParameterCollectionV5));
      version = Version.LegacyV6;
    }
    if (version === Version.LegacyV6) {
      parameter = ScenarioConverter.convertV6ToV7((parameter as IParameterCollectionV6));
      version = Version.LegacyV7;
    }
    if (version === Version.LegacyV7) {
      parameter = ScenarioConverter.convertV7ToV8((parameter as IParameterCollectionV7));
      version = Version.Current;
    }
    if (version === Version.LegacyV8) {
      parameter = ScenarioConverter.convertV8ToV9((parameter as IParameterCollectionV8));
      version = Version.Current;
    }

    if (version !== Version.Current) {
      assertUnreachable(version);
    }

    return new Scenario(
      new NonLocalizedString(scenarioDTO.name),
      new NonLocalizedString(''),
      parameter as IParameterCollection,
    );
  }

  static convertV0ToV1(v0Parameter: IParameterCollectionV0): IParameterCollectionV1 {
    return {
      ...v0Parameter,
      customProduction: {
        dataSeries: v0Parameter.customProduction,
        scaling: toEnergy(
          _.sum(v0Parameter.customProduction) || 0,
        ),
      },
      customConsumption: {
        dataSeries: v0Parameter.customConsumption,
        scaling: toEnergy(
          _.sum(v0Parameter.customConsumption) || 0,
        ),
      },
    };
  }

  static convertV1ToV2(v1Parameter: IParameterCollectionV1): IParameterCollectionV2 {
    return {
      ...v1Parameter,
      version: 2,
    };
  }

  static convertV2ToV3(v2Parameter: IParameterCollectionV2): IParameterCollectionV3 {
    return {
      ...v2Parameter,
      endUser: {
        ...v2Parameter.endUser,
        additionalEMobilityEnergy: 0,
      },
      version: 3,
    };
  }

  static convertV3ToV4(v3Parameter: IParameterCollectionV3): IParameterCollectionV4 {
    const statusQuo = getStatusQuo();
    return {
      ...v3Parameter,
      solar: {
        ...v3Parameter.solar,
        tilt: statusQuo.solar.tilt,
        azimuth: statusQuo.solar.azimuth,
      },
      version: 4,
    };
  }

  static convertV4ToV5(v4Parameter: IParameterCollectionV4): IParameterCollectionV5 {
    return {
      ...v4Parameter,
      solar: {
        ...v4Parameter.solar,
        version: 'EqualWeighted',
      },
      version: 5,
    };
  }

  static convertV5ToV6(v5Parameter: IParameterCollectionV5): IParameterCollectionV6 {
    return {
      ...v5Parameter,
      batteryStorage: {
        ...v5Parameter.batteryStorage,
        intakePowerInHours: v5Parameter.batteryStorage.maxCapacity
          / v5Parameter.batteryStorage.intakePower,
        outputPowerInHours: v5Parameter.batteryStorage.maxCapacity
          / v5Parameter.batteryStorage.outputPower,
      },
      version: 6,
    };
  }

  static convertV6ToV7(v6Parameter: IParameterCollectionV6): IParameterCollectionV7 {
    const statusQuo = getStatusQuo();
    const {
      endUser,
      customConsumption,
      customProduction,
      nuclear,
      thermal,
      wind,
      river,
      solar,
      dam,
      pumpStorage,
      batteryStorage,
    } = v6Parameter;
    return {
      endUser,
      import: {
        ...statusQuo.import,
        year: parseInt(statusQuo.import.yearOrSlug, 10) || MOST_CURRENT_YEAR_FALLBACK,
      },
      export: {
        ...statusQuo.export,
        year: parseInt(statusQuo.export.yearOrSlug, 10) || 2019,
      },
      customConsumption,
      customProduction,
      nuclear,
      thermal,
      wind,
      river,
      solar,
      waterStorage: {
        intakeEfficiency: pumpStorage.inputEfficiency,
        outputEfficiency: dam.outputEfficiency,
        dam: {
          year: dam.year,
          outputPower: dam.outputPower,
          initialUsableCapacity: dam.initialCapacity * dam.outputEfficiency,
          maxUsableCapacity: dam.maxCapacity * dam.outputEfficiency,
        },
        pumpStorage: {
          intakePower: pumpStorage.intakePower,
          outputPower: pumpStorage.outputPower,
          initialUsableCapacity: pumpStorage.initialCapacity * pumpStorage.outputEfficiency,
          maxUsableCapacity: pumpStorage.maxCapacity * pumpStorage.outputEfficiency,
        },
      },
      batteryStorage: {
        intakePowerInHours: batteryStorage.intakePowerInHours,
        outputPowerInHours: batteryStorage.outputPowerInHours,
        intakeEfficiency: batteryStorage.inputEfficiency,
        outputEfficiency: batteryStorage.outputEfficiency,
        initialUsableCapacity: batteryStorage.initialCapacity * batteryStorage.outputEfficiency,
        maxUsableCapacity: batteryStorage.maxCapacity * batteryStorage.outputEfficiency,
      },
      network: { lossFactor: 0.07 },
      version: 7,
    };
  }

  static convertV7ToV8(v7Parameter: IParameterCollectionV7): IParameterCollectionV8 {
    const statusQuo = getStatusQuo();
    const { waterStorage, waterStorage: { dam } } = v7Parameter;
    return {
      ...v7Parameter,
      waterStorage: {
        ...waterStorage,
        dam: {
          ...dam,
          minimalPower: statusQuo.waterStorage.dam.minimalPower,
        },
      },
      version: 8,
    };
  }

  static convertV8ToV9(v8Parameter: IParameterCollectionV8): IParameterCollection {
    return {
      ...v8Parameter,
      import: {
        scaling: v8Parameter.import.scaling,
        yearOrSlug: v8Parameter.import.year.toString(),
      },
      export: {
        scaling: v8Parameter.export.scaling,
        yearOrSlug: v8Parameter.export.year.toString(),
      },
      endUser: {
        scaling: v8Parameter.endUser.scaling,
        yearOrSlug: v8Parameter.endUser.year.toString(),
        additionalEMobilityEnergy: v8Parameter.endUser.additionalEMobilityEnergy,
      },
      nuclear: {
        yearOrSlug: v8Parameter.nuclear.year.toString(),
        scaling: v8Parameter.nuclear.scaling,
      },
      river: {
        scaling: v8Parameter.river.scaling,
        yearOrSlug: v8Parameter.river.year.toString(),
      },
      thermal: {
        yearOrSlug: v8Parameter.thermal.year.toString(),
        scaling: v8Parameter.thermal.scaling,
      },
      wind: {
        scaling: v8Parameter.wind.scaling,
        yearOrSlug: v8Parameter.wind.year.toString(),
      },
      solar: {
        yearOrSlug: v8Parameter.solar.year.toString(),
        scaling: v8Parameter.solar.scaling,
        azimuth: v8Parameter.solar.azimuth,
        tilt: v8Parameter.solar.tilt,
        version: v8Parameter.solar.version,
      },
      waterStorage: {
        ...v8Parameter.waterStorage,
        dam: {
          yearOrSlug: v8Parameter.waterStorage.dam.year.toString(),
          initialUsableCapacity: v8Parameter.waterStorage.dam.initialUsableCapacity,
          maxUsableCapacity: v8Parameter.waterStorage.dam.maxUsableCapacity,
          minimalPower: v8Parameter.waterStorage.dam.minimalPower,
          outputPower: v8Parameter.waterStorage.dam.outputPower,
        },
      },
      version: 9,
    };
  }
}
