import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapPageCloseDialogParamsTopProps } from '../redux/mappers';
import Scenario from '../domain_model/scenario/Scenario';

let onUnloadMessage = '';

const onUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = onUnloadMessage;
};

const PageCloseDialog = (props: PropsFromRedux) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { t } = useTranslation();
  const { scenarios, activeScenario } = props;

  // update unsavedChanges in the state. This runs on every change of activeScenario or scenarios
  useEffect(() => {
    const newState = activeScenario === null
      || scenarios.reduce((prev: boolean, curr: Scenario) => (prev || curr.mutable), false);
    if (newState !== unsavedChanges) {
      setUnsavedChanges(newState);
    }
  }, [activeScenario, scenarios, unsavedChanges]);

  // add or remove the EventListener
  // This runs only once per unsavedChanges change
  useEffect(() => {
    if (unsavedChanges) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
    }
  }, [unsavedChanges]);

  // set the displayed msg based on the current language
  // This runs only once per language change
  useEffect(() => {
    onUnloadMessage = t('onCloseMessage');
  }, [t]);

  return null;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const connector = connect(mapPageCloseDialogParamsTopProps, {});

export default connector(PageCloseDialog);
