import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect, ConnectedProps } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import {
  createStyles, FormControlLabel, makeStyles, Theme,
} from '@material-ui/core';
import { mapUiStateToProps } from '../../redux/mappers';
import {
  changeCalculationState,
  changeCalculationWarningState,
  changeHighlightState,
} from '../../redux/actions';
import { CalculationWarningState } from '../../redux/types/uiTypes';
import CalculationState from '../../domain_model/CalculationState';

const useStyles = makeStyles((theme: Theme) => createStyles({
  saveSettingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));


type Props = PropsFromRedux & {
  onClose: () => void;
}


const CalculationWarnigDialog = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    onClose,
    calculationWarningState,
    changeCalculationWarningState: _changeCalculationWarningState,
    changeCalculationState: _changeCalculationState,
    changeHighlightState: _changeHighlightState,
  } = props;
  const classes = useStyles();
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const isOpen = calculationWarningState === CalculationWarningState.Open;

  function handleOnClose() {
    if (isDisabled) {
      _changeCalculationWarningState(CalculationWarningState.Disabled);
    } else {
      _changeCalculationWarningState(CalculationWarningState.Closed);
    }
    onClose();
  }

  function handleStartCalculation() {
    _changeCalculationState('forActiveScenario', CalculationState.Requested);
    _changeHighlightState({
      highlightCalculateButton: false,
      highlightScenarioSelect: false,
      highlightParameterSections: false,
    });
    handleOnClose();
  }

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(event.target.checked);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={isOpen}
        onClose={handleOnClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('calculationWarningTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('calculationWarningText1')}
            <br />
            {t('calculationWarningText2')}

            <div className={classes.saveSettingContainer}>
              <FormControlLabel
                label={t('doNotShowAgain')}
                labelPlacement="end"
                value="disable warning"
                control={(
                  <Checkbox
                    checked={isDisabled}
                    onChange={handleCheckBoxChange}
                    className={classes.checkbox}
                    color="primary"
                  />
                )}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStartCalculation}
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            {t('startCalculation')}
          </Button>

          <Button
            onClick={handleOnClose}
            className={classes.button}
          >
            {t('close')}
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapToProps = createSelector(
  [mapUiStateToProps],
  (uiState) => ({
    calculationWarningState: uiState.calculationWarningState,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {
  changeCalculationWarningState,
  changeCalculationState,
  changeHighlightState,
});

export default connector(CalculationWarnigDialog);
