import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Decimal from 'decimal.js';
import { createSelector } from 'reselect';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import NumberInput from '../input-single/inputs/NumberInput';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(PARAMETER_SPACING),
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: '"left  ."',
  },
  materialInput: {
    gridArea: 'left',
  },
  input: {
    textAlign: 'end',
  },
}));

type Props = PropsFromRedux;

const NetworkLosses = (props: Props) => {
  const { networkLossFactor, changeParams: _changeParams } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const setValue = (newValue: number) => {
    _changeParams({ network: { lossFactor: newValue } });
  };

  return (
    <div className={classes.root}>
      <NumberInput
        className={classes.materialInput}
        label={t('paramsNetworkLosses')}
        value={new Decimal(networkLossFactor).mul(100).toNumber()}
        onNewValue={(val) => setValue(new Decimal(val).div(100).toNumber())}
        max={100}
        step={1}
        unit="%"
        alignRight
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapParamsToProps],
  (params) => ({
    networkLossFactor: params.activeParams.network.lossFactor,
  }),
);
const connector = connect(mapToProps, { changeParams });

export default connector(NetworkLosses);
