import CalculationState from '../../domain_model/CalculationState';


export const CHANGE_PARAM_SETTINGS_OPEN = 'CHANGE_PARAM_SETTINGS_OPEN';
export const CHANGE_PARAM_SCENARIO_SELECT_OPEN = 'CHANGE_PARAM_SCENARIO_SELECT_OPEN';
export const CHANGE_OVERVIEW_OPEN = 'CHANGE_OVERVIEW_OPEN';
export const CHANGE_CALCULATION_STATE = 'CHANGE_CALCULATION_STATE';
export const CHANGE_EXPANDED_SECTION = 'CHANGE_EXPANDED_SECTION';
export const CHANGE_COMPARE_OPEN = 'CHANGE_COMPARE_OPEN';
export const CHANGE_COMPARE_CHOOSER_OPEN = 'CHANGE_COMPARE_CHOOSER_OPEN';
export const SET_COMPARE_SCENARIOS = 'SET_COMPARE_SCENARIOS';
export const CHANGE_LANDING_PAGE_OPEN = 'CHANGE_LANDING_PAGE_OPEN';

export const CHANGE_HIGHLIGHT_STATE = 'CHANGE_HIGHLIGHT_STATE';

export const CHANGE_GRAPH_STYLE = 'CHANGE_GRAPH_STYLE';
export const CHANGE_GRAPH_RESOLUTION = 'CHANGE_GRAPH_RESOLUTION';
export const CHANGE_CALCULATION_WARNING_STATE = 'CHANGE_CALCULATION_WARNING_STATE';

export const RESOLVE_CONNECTION_ERROR_LOOP = 'RESOLVE_CONNECTION_ERROR_LOOP';
export const REGISTER_CONNECTION_ERROR_LOOP = 'REGISTER_CONNECTION_ERROR_LOOP';


export interface IUiState {
  paramsOpen: boolean;
  expandedSection: DrawerSection | false;
  landingPageOpen: boolean;
  calculationWarningState: CalculationWarningState;
  paramsScenarioSelectOpen: boolean;

  calculationStates: {
    activeScenario: CalculationState;
    comparedScenarios: CalculationState;
    solarLoadActivePreCalculation: CalculationState;
  };

  compareState: ICompareState;

  overviewOpen: boolean;

  highlightState: IHighlightState;

  graphStyle: GraphStyle;
  graphResolution: GraphResolution;

  connectionsInErrorLoop: number;
}

export const drawerSections = [
  'endUser',
  'importExport',
  'nuclear',
  'thermal',
  'solar',
  'wind',
  'river',
  'dam',
  'pump',
  'battery',
  'custom',
  'advanced',
] as const;
export type DrawerSection = typeof drawerSections[number];

export enum CalculationWarningState { Closed, Open, Disabled }

export type GraphStyle = 'table' | 'bar';
export type GraphResolution = 'continuous' | 'day' | 'month' | 'year';

export interface IHighlightState {
  highlightCalculateButton: boolean;
  highlightScenarioSelect: boolean;
  highlightParameterSections: boolean;
  highlightSaveButton: boolean;
}

export interface ICompareState {
  isComparisonActive: boolean;
  compareScenarioA: number | null;
  compareScenarioB: number | null;
  isCompareChooserOpen: boolean;
}

export type CalculationStateTargets =
  'forActiveScenario'
  | 'forCompareScenarios'
  | 'forSolarLoadPreCalc'


// Action Interfaces

interface IChangeParamSettingOpenAction {
  type: typeof CHANGE_PARAM_SETTINGS_OPEN;
  payload: boolean;
}

interface IChangeParamScenarioSelectOpenAction {
  type: typeof CHANGE_PARAM_SCENARIO_SELECT_OPEN;
  payload: boolean;
}

interface IChangeOverviewOpenAction {
  type: typeof CHANGE_OVERVIEW_OPEN;
  payload: boolean;
}

interface IChangeCalculationStateAction {
  type: typeof CHANGE_CALCULATION_STATE;
  payload: { targetState: CalculationStateTargets; stateValue: CalculationState };
}

interface IChangeExpandedSectionAction {
  type: typeof CHANGE_EXPANDED_SECTION;
  payload: DrawerSection | false;
}

interface IChangeCompareOpenAction {
  type: typeof CHANGE_COMPARE_OPEN;
  payload: boolean;
}

interface IChangeCompareChooserOpenAction {
  type: typeof CHANGE_COMPARE_CHOOSER_OPEN;
  payload: boolean;
}

interface ISetCompareScenariosAction {
  type: typeof SET_COMPARE_SCENARIOS;
  payload: { scenarioAIndex: number | null; scenarioBIndex: number | null };
}

interface IChangeLandingPageOpenAction {
  type: typeof CHANGE_LANDING_PAGE_OPEN;
  payload: boolean;
}

interface IChangeHighlightStateAction {
  type: typeof CHANGE_HIGHLIGHT_STATE;
  payload: Partial<IHighlightState>;
}

interface IChangeGraphStyleAction {
  type: typeof CHANGE_GRAPH_STYLE;
  payload: GraphStyle;
}

interface IChangeGraphResolutionAction {
  type: typeof CHANGE_GRAPH_RESOLUTION;
  payload: GraphResolution;
}

export interface IChangeCalculationWarningAction {
  type: typeof CHANGE_CALCULATION_WARNING_STATE;
  payload: CalculationWarningState;
}

export interface IRegisterConnectionErrorLoop {
  type: typeof REGISTER_CONNECTION_ERROR_LOOP;
}

export interface IResolveConnectionErrorLoop {
  type: typeof RESOLVE_CONNECTION_ERROR_LOOP;
}


export type UiActionTypes = (
  IChangeParamSettingOpenAction
  | IChangeParamScenarioSelectOpenAction
  | IChangeOverviewOpenAction
  | IChangeCalculationStateAction
  | IChangeExpandedSectionAction
  | IChangeCompareOpenAction
  | IChangeCompareChooserOpenAction
  | ISetCompareScenariosAction
  | IChangeLandingPageOpenAction
  | IChangeHighlightStateAction
  | IChangeGraphStyleAction
  | IChangeGraphResolutionAction
  | IChangeCalculationWarningAction
  | IRegisterConnectionErrorLoop
  | IResolveConnectionErrorLoop
);
