import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Chart } from 'highcharts';
import { deepPurple, teal } from '@material-ui/core/colors';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { Extremes, PlotDataSeries } from './ChartComponent';
import { mapActiveComputedToPropsFlat, mapUnitScopeToProps } from '../../redux/mappers';
import { customGreen } from '../../domain_model/Colors';
import { halfResolutionReducer } from '../helper';
import PowerOrEnergyChart from './PowerorEnergyChart';


type Props = PropsFromRedux & {
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
};


const StorageLevelChart = (props: Props) => {
  const {
    activeComputedData: computedData, chartCallback, onNewExtremes, onMouseMove, onMouseLeave,
  } = props;
  const { t } = useTranslation();

  const strings = {
    graphLevelsBattery: t('graphLevelsBattery'),
    graphLevelsPump: t('graphLevelsPump'),
    graphLevelsDam: t('graphLevelsDam'),
  };

  const plotData: PlotDataSeries[] = useMemo(() => {
    const plotDataArray: PlotDataSeries[] = [];
    if (_.some(computedData.batteryUsableStorageLevel, (x) => x !== 0)) {
      plotDataArray.push({
        type: 'area',
        data: computedData.batteryUsableStorageLevel.reduce(halfResolutionReducer, []),
        name: strings.graphLevelsBattery,
        color: deepPurple[200],
      });
    }
    if (_.some(computedData.pumpUsableStorageLevel, (x) => x !== 0)) {
      plotDataArray.push({
        type: 'area',
        data: computedData.pumpUsableStorageLevel.reduce(halfResolutionReducer, []),
        name: strings.graphLevelsPump,
        color: teal[200],
      });
    }
    if (_.some(computedData.damUsableStorageLevel, (x) => x !== 0)) {
      plotDataArray.push({
        type: 'area',
        data: computedData.damUsableStorageLevel.reduce(halfResolutionReducer, []),
        name: strings.graphLevelsDam,
        color: customGreen[500],
      });
    }
    return plotDataArray;
  }, [computedData, strings]);

  return (
    <PowerOrEnergyChart
      chartCallback={chartCallback}
      plotData={plotData}
      onNewExtremes={onNewExtremes}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      stack
      noOneBarResolution
      type="energy"
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapActiveComputedToPropsFlat, mapUnitScopeToProps],
  (computed, scope) => ({
    ...computed,
    ...scope,
  }),
);
const connector = connect(mapToProps, {});

export default connector(StorageLevelChart);
