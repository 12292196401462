import Column from './Column';

export default class Table {
  public columns: (Column<string> | Column<number>)[] = [];

  public getRows(): (string | number)[][] {
    const headerRows: (string | number)[][] = [];
    headerRows.push(this.getHeaders());

    const nrOfDataRows = this.columns.reduce<number>((prev, col) => {
      const dataRowLength = col.data.length;
      return dataRowLength > prev ? dataRowLength : prev;
    }, 0);
    const dataRows = Array.from(Array(nrOfDataRows)).map((_, rowIndex) => (
      this.columns.map((column) => (
        column.data.length > rowIndex ? column.data[rowIndex] : ''
      ))
    ));
    return headerRows.concat(dataRows);
  }

  private getHeaders() {
    return this.columns.map((col) => col.header);
  }
}
