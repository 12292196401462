import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createStyles } from '@material-ui/styles';
import { createSelector } from 'reselect';
import { mapActiveComputedToProps } from '../../redux/mappers';
import CompareButton from '../comparison/CompareButton';
import ExportResultDataButton from './ExportResultDataButton';

type Props = PropsFromRedux

const useStyles = makeStyles<Theme>((theme) => createStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  actionsContainer: {
    marginLeft: '3em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));


const ScenarioDescription = (props: Props) => {
  const { t } = useTranslation();
  const { scenarioName, scenarioDescription, notYetComputed } = props;

  const classes = useStyles(props);

  const getScenarioActions = () => (
    <div className={classes.actionsContainer}>
      <CompareButton className={classes.button} />
      <ExportResultDataButton className={classes.button} />
    </div>
  );

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant="h5">
          {`${t('scenario')}: ${scenarioName.getString(t)}`}
        </Typography>
        {!notYetComputed && getScenarioActions()}
      </div>
      <Typography variant="body1">
        {scenarioDescription.getString(t)}
      </Typography>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapActiveComputedToProps],
  (computed) => ({
    ...computed.activeComputedData.scenarioInfo,
    ...computed.activeComputedData.meta,
  }),
);
const connector = connect(mapToProps, {});

export default connector(ScenarioDescription);
