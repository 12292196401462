/**
 * Analytics Event
 *
 * These Events need to be registered on the plausible Page.
 */
export enum E {
  showStrg = 'show storage graphs',
  hideStrg = 'hide storage graphs',

  landingStart = 'landingPage: start calculation',
  landingShow = 'landingPage: show all scenarios',
  landingCreate = 'landingPage: create scenario',

  compare = 'open scenario comparison',
  scopePerson = 'set scope to per person',
  scopeSwitzerland = 'set scope back to all of switzerland',

  lang3minDe = 'language after 3min: german',
  lang3minEn = 'language after 3min: english',
}
