import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core';
import Copyright from './Copyright';
import Feedback from './Feedback';
import { getDokString } from '../../helper';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
}));

type MenuEntry = 'FEEDBACK' | 'COPYRIGHT'

function MiscMenu(): JSX.Element {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = React.useState<MenuEntry | false>(false);

  return (
    <>
      <div className={classes.root}>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => window.open(getDokString(i18n), '_blank')}
        >
          {t('documentation')}
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => setOpen('FEEDBACK')}
        >
          {t('feedback')}
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => setOpen('COPYRIGHT')}
        >
          {t('copyright')}
        </Button>
      </div>
      <Feedback open={open === 'FEEDBACK'} onClose={() => setOpen(false)} />
      <Copyright open={open === 'COPYRIGHT'} onClose={() => setOpen(false)} />
    </>
  );
}

export default MiscMenu;
