import { ComputedDataSets } from '../../domain_model/ComputedDataSets';
import {
  IComputedData,
  IPreComputedData,
  SolarLoadPreScalingData,
} from '../../domain_model/ComputedDataCollection';
import { ScenarioId } from '../../domain_model/scenario/Scenario';

export const ADD_COMPUTED_DATA = 'ADD_COMPUTED_DATA';
export const DELETE_COMPUTED_DATA = 'DELETE_COMPUTED_DATA';
export const SET_ACTIVE_COMPUTED_DATA = 'SET_ACTIVE_COMPUTED_DATA';
export const CHANGE_ACTIVE_PRE_COMPUTED_DATA = 'CHANGE_ACTIVE_PRE_COMPUTED_DATA';
export const CHANGE_COMPARE_PRE_COMPUTED_DATA = 'CHANGE_COMPARE_PRE_COMPUTED_DATA';
export const REQUEST_COMPARE_PRE_COMPUTED_DATA = 'REQUEST_COMPARE_PRE_COMPUTED_DATA';
export const ADD_PRE_COMPUTED_CACHE_ENTRY = 'ADD_PRE_COMPUTED_CACHE_ENTRY';

export interface IComputedState {
  computedDataSets: ComputedDataSets;
  activeComputedData: IComputedData;
  preComputedData: IPreComputedData;
}


// Action Interfaces

interface ISetActiveComputedDataAction {
  type: typeof SET_ACTIVE_COMPUTED_DATA;
  payload: { data: IComputedData };
}

interface IAddComputedDataAction {
  type: typeof ADD_COMPUTED_DATA;
  payload: { scenarioId: ScenarioId; data: IComputedData };
}

interface IDeleteComputedDataAction {
  type: typeof DELETE_COMPUTED_DATA;
  payload: { scenarioId: ScenarioId };
}

interface IChangeActivePreComputedDataAction {
  type: typeof CHANGE_ACTIVE_PRE_COMPUTED_DATA;
  payload: { data: SolarLoadPreScalingData; hash: string };
}

interface IChangeComparePreComputedDataAction {
  type: typeof CHANGE_COMPARE_PRE_COMPUTED_DATA;
  payload: { ab: 'a' | 'b'; data: SolarLoadPreScalingData; hash: string };
}

interface IRequestComparePreComputedDataAction {
  type: typeof REQUEST_COMPARE_PRE_COMPUTED_DATA;
  payload: { ab: 'a' | 'b'; scenarioId: number };
}

interface IAddPreComputedCacheEntryAction {
  type: typeof ADD_PRE_COMPUTED_CACHE_ENTRY;
  payload: { data: SolarLoadPreScalingData; hash: string };
}

export type ComputedActionTypes = (
  ISetActiveComputedDataAction
  | IAddComputedDataAction
  | IDeleteComputedDataAction
  | IChangeActivePreComputedDataAction
  | IChangeComparePreComputedDataAction
  | IRequestComparePreComputedDataAction
  | IAddPreComputedCacheEntryAction
);
