import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Divider,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { connect, ConnectedProps } from 'react-redux';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import SolarEqSliders from '../input-combined/eq-sliders/SolarEqSlider';
import CurveDiagram from '../CurveDiagram';
import { mapParamsToProps } from '../../../../redux/mappers';
import StorageEfficiencies from './StorageEfficiencies';
import { changeParams } from '../../../../redux/actions';
import { isSolarVersion, solarVersions } from '../../../../domain_model/Parameters';
import NetworkLosses from './NetworkLosses';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';
import NumberInput from '../input-single/inputs/NumberInput';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr',
  },
  section: {
    display: 'grid',
    position: 'relative',
    gridGap: theme.spacing(PARAMETER_SPACING / 2),
  },
  subSection: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING / 2),
    gridTemplateColumns: 'auto',
    marginBottom: '0.5em',
  },
  title: {
    fontSize: '1.2rem',
  },
  azimuth: {
    maxHeight: '10em',
    height: '10em',
  },
  tilt: {
    maxHeight: '10em',
    height: '10em',
  },
  graph: {
    height: '10em',
    position: 'relative',
    zIndex: 1,
    marginTop: '0.5em',
    marginBottom: '1.5em',
  },
  graphLegendContainer: {
    position: 'absolute',
    zIndex: 10,
    bottom: '-1.4em',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: grey[500],
  },
  infoButton: {
    position: 'absolute',
    right: 0,
    top: '-0.43em',
  },
  damContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
  },
}));

type Props = PropsFromRedux;

const solarInfoButtonProps: ClickableInfoButtonProps = {
  dokuPage: 'user-manual.html',
  paragraph: {
    de: 'benutzerdefiniert',
    // TODO: add en doku paragraph
    en: 'benutzerdefiniert',
  },
};

const waterStorageInfoButtonProps: ClickableInfoButtonProps = {
  dokuPage: 'user-manual.html',
  paragraph: {
    de: 'benutzerdefiniert',
    // TODO: add en doku paragraph
    en: 'benutzerdefiniert',
  },
};

const batteryInfoButtonProps: ClickableInfoButtonProps = {
  dokuPage: 'user-manual.html',
  paragraph: {
    de: 'benutzerdefiniert',
    // TODO: add en doku paragraph
    en: 'benutzerdefiniert',
  },
};

const gridInfoButtonProps: ClickableInfoButtonProps = {
  dokuPage: 'user-manual.html',
  paragraph: {
    de: 'benutzerdefiniert',
    // TODO: add en doku paragraph
    en: 'benutzerdefiniert',
  },
};

const AdvancedParams = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    activeParams: {
      solar: { yearOrSlug, scaling, version: activeVersion },
      waterStorage: {
        dam: { minimalPower },
      },
    },
    changeParams: _changeParams,
  } = props;

  return (
    <div className={classes.root}>

      <div className={classes.section}>
        <Typography variant="h5" className={classes.title}>
          {t('solarEnergy')}
        </Typography>
        <ClickableInfoButton
          {...solarInfoButtonProps}
          className={classes.infoButton}
        />
        <CurveDiagram
          className={classes.graph}
          scaling={scaling}
          yearOrSlug={yearOrSlug}
          section="solar"
          disableMaxLine
          showSolarWinterTotal
        />
        <div className={classes.subSection}>
          <Typography variant="overline">
            {t('paramsSolarAzimuthTitle')}
          </Typography>
          <SolarEqSliders className={classes.azimuth} repeatFirst type="azimuth" />
        </div>
        <div className={classes.subSection}>
          <Typography variant="overline">
            {t('paramsSolarElevationTitle')}
          </Typography>
          <SolarEqSliders className={classes.tilt} type="tilt" />
        </div>
        <div className={classes.subSection}>
          <Typography variant="overline">
            {t('paramsSolarLocationTitle')}
          </Typography>
          <RadioGroup
            aria-label="paramsSolarLocationWeights"
            name="paramsSolarLocationWeights"
            value={activeVersion}
            onChange={(_, newValue) => {
              if (isSolarVersion(newValue)) {
                _changeParams({ solar: { version: newValue } });
              }
            }}
          >
            {solarVersions.map((version, index) => (
              <FormControlLabel
                key={index}
                value={version}
                control={<Radio color="primary" />}
                label={t(`paramsSolarLocation${version}`)}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <Divider />

      <div className={classes.section}>
        <Typography variant="h5" className={classes.title}>
          {t('waterStorageEnergy')}
        </Typography>
        <ClickableInfoButton
          {...waterStorageInfoButtonProps}
          className={classes.infoButton}
        />
        <StorageEfficiencies section="waterStorage" />
        <Typography variant="overline">
          {t('paramsAdvancedDamTitle')}
        </Typography>
        <div className={classes.damContainer}>
          <NumberInput
            label={t('paramsAdvancedDamMinimalPower')}
            value={minimalPower}
            onNewValue={(val) => _changeParams(
              {
                waterStorage: { dam: { minimalPower: val } },
              },
            )}
            step={0.1}
            unit="GW"
            alignRight
          />
        </div>
      </div>
      <Divider />

      <div className={classes.section}>
        <Typography variant="h5" className={classes.title}>
          {t('batteryEnergy')}
        </Typography>
        <ClickableInfoButton
          {...batteryInfoButtonProps}
          className={classes.infoButton}
        />
        <StorageEfficiencies section="batteryStorage" />
      </div>
      <Divider />

      <div className={classes.section}>
        <Typography variant="h5" className={classes.title}>
          {t('electricityGrid')}
        </Typography>
        <ClickableInfoButton
          {...gridInfoButtonProps}
          className={classes.infoButton}
        />
        <NetworkLosses />
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(AdvancedParams);
