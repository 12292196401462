import React from 'react';
import { red } from '@material-ui/core/colors';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import MiniChartComponent from './MiniChartComponent';
import { PlotDataSeries } from '../../main-section/ChartComponent';
import { mapParamsAndPreloadToProps } from '../../../redux/mappers';
import Quantity from '../../../domain_model/math/Quantity';
import { CustomDataSeriesSections } from '../../../domain_model/SourceDataCollection';
import { DB_ENERGY_UNIT } from '../../../domain_model/math/EnergyUnit';
import { NumberConverter } from '../../../domain_model/math/Unit';

type Props = PropsFromRedux & {
  section: CustomDataSeriesSections;
  max: Quantity;
  scaling: number;
  className: string;
}

const UserDataCurveDiagram = (props: Props) => {
  const {
    max,
    section,
    scaling,
    activeParams,
    className,
  } = props;

  const { val: displayMaxValue, unit: displayUnit } = max;
  const convertToDb = displayUnit.getConverterTo(DB_ENERGY_UNIT) as NumberConverter;

  const dbMaxValue = convertToDb(displayMaxValue);

  const { dataSeries } = activeParams[section];

  const originalMaxValue = _.max(dataSeries) || 0;
  const calculatedMaxValue = originalMaxValue * (dbMaxValue / scaling);

  const plotData: PlotDataSeries[] = [
    {
      type: 'area',
      data: dataSeries,
      name: '',
      color: red[200],
    },
  ];

  return (
    <div className={className}>
      <MiniChartComponent plotData={plotData} max={calculatedMaxValue} />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsAndPreloadToProps, {});

export default connector(UserDataCurveDiagram);
