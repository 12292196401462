import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { mapLandingPageOpenToProps, mapSizeToProps } from '../redux/mappers';
import {
  changeCalculationState,
  changeExpandedSection,
  changeHighlightState,
  changeLandingPageOpen,
  changeParamScenarioSelectOpen,
  setActiveScenario,
} from '../redux/actions';
import SwissIllustration from '../icons/SwissIllustration';
import CalculationState from '../domain_model/CalculationState';
import { MEDIUM } from '../domain_model/GlobalStyleConstants';
import { IReduxState } from '../redux/types';
import { TrackedButton } from '../analytics/analytics';
import { sleep } from '../helper';
import { E } from '../analytics/types';

type Props = PropsFromRedux & {
  onCloseDrawer: () => void;
}


const useStyles = makeStyles<Theme, Props>((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    paddingLeft: '36em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerRoot: {
    pointerEvents: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: ({ currentSize }: Props) => (
      (currentSize && currentSize.isLessOrEqualTo(MEDIUM)) ? 'auto' : 'hidden'
    ),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(8),
    width: '66em',
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    marginTop: '-0.2em',
    marginBottom: '0.4em',
    fontSize: '3.3rem',
  },
  deck: {
    fontSize: '1.2rem',
    lineHeight: 1.25,
    marginBottom: '1em',
  },
  illustrationSection: {
    marginTop: '1em',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: '". . texts"',
    gridGap: '4em',
    height: '350px',
  },
  illustrationContainer: {
    position: 'absolute',
  },
  illustration: {
    width: '570px',
  },
  illustrationTexts: {
    gridArea: 'texts',
    height: '350px',
  },
  captionTitle: {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
    marginTop: '-0.2em',
  },
  captionBlock: {
    height: '7.4em',
  },
  subtitle: {
    fontSize: '2.3rem',
    fontWeight: 300,
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  columnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '4em',
  },
  column: {},
  subSubtitle: {
    marginBottom: '0.25em',
  },
  columnText: {
    marginBottom: '1em',
  },
  button: {
    marginTop: '-3em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    // maxWidth: 'unset',
    // width: 'auto',
  },
}));


const LandingPage = React.memo((props: Props) => {
  const classes = useStyles(props);
  const [startCalcDisabled, setStartCalcDisabled] = useState(false);
  const [showAllDisabled, setShowAllDisabled] = useState(false);
  const [createCustomDisabled, setCreateCustomDisabled] = useState(false);
  const { t } = useTranslation();

  const {
    landingPageOpen,
    onCloseDrawer,
    currentSize,
    changeLandingPageOpen: _changeLandingPageOpen,
    changeHighlightState: _changeHighlightState,
    changeCalculationState: _changeCalculationState,
    setActiveScenario: _setActiveScenario,
    changeParamScenarioSelectOpen: _changeScenarioSelectOpen,
    changeExpandedSection: _changeExpandedSection,
  } = props;

  const isModalDialog = currentSize && currentSize.isLessOrEqualTo(MEDIUM);

  function handleClose() {
    _changeLandingPageOpen(false);
  }

  if (!landingPageOpen) {
    return null;
  }

  function handleStartCalcClick() {
    if (isModalDialog) {
      handleClose();
    }
    setStartCalcDisabled(true);
    _changeHighlightState({
      highlightScenarioSelect: true,
      highlightCalculateButton: false,
      highlightParameterSections: false,
    });
    _setActiveScenario(0);
    sleep(1500).then(() => {
      _changeHighlightState({ highlightScenarioSelect: false });
      _changeHighlightState({ highlightCalculateButton: true });
      sleep(3000 - 1500 / 2).then(() => {
        _changeHighlightState({ highlightCalculateButton: false });
        setStartCalcDisabled(false);
        _changeCalculationState('forActiveScenario', CalculationState.Requested);
        onCloseDrawer();
      });
    });
  }

  function handleShowAllClick() {
    if (isModalDialog) {
      handleClose();
    }
    setShowAllDisabled(true);
    _changeHighlightState({
      highlightScenarioSelect: true,
      highlightCalculateButton: false,
      highlightParameterSections: false,
    });
    sleep(3000 - 1500 / 2).then(() => {
      setShowAllDisabled(false);
      _changeHighlightState({ highlightScenarioSelect: false });
      _changeScenarioSelectOpen(true);
      _changeHighlightState({ highlightCalculateButton: true });
    });
  }

  function handleCreateCustomClick() {
    if (isModalDialog) {
      handleClose();
    }
    setCreateCustomDisabled(true);
    _changeHighlightState({
      highlightCalculateButton: false,
      highlightScenarioSelect: false,
      highlightParameterSections: true,
    });
    sleep(3000).then(() => {
      setCreateCustomDisabled(false);
      _changeHighlightState({
        highlightParameterSections: false,
      });
      _changeExpandedSection('solar');
    });
  }

  const getLayout = () => (
    <Paper className={classes.paper}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h2" className={classes.title}>
        {t('landingPageTitle')}
      </Typography>
      <Typography variant="subtitle1" className={classes.deck}>
        {t('landingPageLead')}
      </Typography>
      <div className={classes.illustrationContainer}>
        <SwissIllustration className={classes.illustration} />
      </div>
      <div className={classes.illustrationSection}>
        <div className={classes.illustrationTexts}>
          <div className={classes.captionBlock}>
            <Typography variant="subtitle2" className={classes.captionTitle}>
              {t('landingPageCaptionConsumeTitle')}
            </Typography>
            <Typography variant="body2" className={classes.columnText}>
              {t('landingPageCaptionConsumeText')}
            </Typography>
          </div>
          <div className={classes.captionBlock}>
            <Typography variant="subtitle2" className={classes.captionTitle}>
              {t('landingPageCaptionProductionTitle')}
            </Typography>
            <Typography variant="body2" className={classes.columnText}>
              {t('landingPageCaptionProductionText')}
            </Typography>
          </div>
          <div className={classes.captionBlock}>
            <Typography variant="subtitle2" className={classes.captionTitle}>
              {t('landingPageCaptionStorageTitle')}
            </Typography>
            <Typography variant="body2" className={classes.columnText}>
              {t('landingPageCaptionStorageText')}
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant="h4" className={classes.subtitle}>
        {t('landingPageSubtitle')}
      </Typography>
      <div className={classes.columnContainer}>
        <div className={classes.column}>
          <Typography variant="h5" className={classes.subSubtitle}>
            {t('landingPageStatusQuoTitle')}
          </Typography>
          <Typography variant="body2" className={classes.columnText}>
            {t('landingPageStatusQuoText')}
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="h5" className={classes.subSubtitle}>
            {t('landingPageViewAllTitle')}
          </Typography>
          <Typography variant="body2" className={classes.columnText}>
            {t('landingPageViewAllText')}
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="h5" className={classes.subSubtitle}>
            {t('landingPageCustomTitle')}
          </Typography>
          <Typography variant="body2" className={classes.columnText}>
            {t('landingPageCustomText')}
          </Typography>
        </div>
        <TrackedButton
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          disabled={startCalcDisabled}
          analyticsEvent={E.landingStart}
          onClick={handleStartCalcClick}
        >
          {t('landingPageStatusQuoButton')}
        </TrackedButton>
        <TrackedButton
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          disabled={showAllDisabled}
          analyticsEvent={E.landingShow}
          onClick={handleShowAllClick}
        >
          {t('landingPageViewAllButton')}
        </TrackedButton>
        <TrackedButton
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          disabled={createCustomDisabled}
          analyticsEvent={E.landingCreate}
          onClick={handleCreateCustomClick}
        >
          {t('landingPageCustomButton')}
        </TrackedButton>
      </div>
    </Paper>
  );


  if (isModalDialog) {
    return (
      <Dialog
        open
        maxWidth={false}
        className={classes.dialog}
        onClose={handleClose}
      >
        {getLayout()}
      </Dialog>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        {getLayout()}
      </div>
    </div>
  );
});

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapSizeToProps(state),
  ...mapLandingPageOpenToProps(state),
});
const connector = connect(mapToProps, {
  changeLandingPageOpen,
  changeHighlightState,
  changeCalculationState,
  setActiveScenario,
  changeParamScenarioSelectOpen,
  changeExpandedSection,
});

export default connector(LandingPage);
