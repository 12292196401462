import { ListItemText, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { createStyles } from '@material-ui/styles';

type Props<KeyType extends string | number> = {
  onMenuItemClick?: ((newValue: KeyType) => void);
  entry: SelectEntry<KeyType>;
  disabled?: boolean;
};

export type SelectEntry<KeyType extends string | number> = {
  key: KeyType;
  label: string;
  subLabel?: string;
}

const useStyles = makeStyles(() => createStyles({
  listItemText: {
    whiteSpace: 'normal',
    maxWidth: '24em',
  },
}));

export default function DetailedListItem<KeyType extends string | number>(
  props: Props<KeyType>,
): JSX.Element {
  const {
    entry, onMenuItemClick, disabled,
  } = props;
  const classes = useStyles(props);

  return (
    <MenuItem
      value={entry.key}
      onClick={() => (onMenuItemClick ? onMenuItemClick(entry.key) : undefined)}
      component="li"
      disabled={disabled}
      button
    >
      <ListItemText
        className={classes.listItemText}
        primary={entry.label}
        secondary={entry.subLabel}
      />
    </MenuItem>
  );
}
