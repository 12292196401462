import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  percentOfProduction: number;
  className: string;
}

const useStyles = makeStyles<Theme, Props>(() => createStyles({
  export: {
    position: 'absolute',
    height: (props) => `${props.percentOfProduction}%`,
    width: '100%',
  },
}));


function ExportBar(props: Props): JSX.Element {
  const classes = useStyles(props);
  const { className } = props;
  return <div className={clsx(classes.export, className)} />;
}

export default ExportBar;
