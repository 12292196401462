import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Chart } from 'highcharts';
import {
  brown, deepPurple, grey, pink, red, teal, yellow,
} from '@material-ui/core/colors';
import { createSelector } from 'reselect';
import { Extremes, PlotDataSeries } from './ChartComponent';
import { mapActiveComputedToPropsFlat, mapGraphResolutionToProps } from '../../redux/mappers';
import { halfResolutionReducer } from '../helper';
import PowerOrEnergyChart from './PowerorEnergyChart';


type Props = PropsFromRedux & {
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
};

const ConsumptionChart = (props: Props) => {
  const {
    activeComputedData: computedData,
    graphResolution,
    chartCallback, onNewExtremes, onMouseMove, onMouseLeave,
  } = props;
  const { t } = useTranslation();

  const plotData: PlotDataSeries[] = [];

  if (computedData.totalEnergyProduction > 0) {
    plotData.push({
      type: 'line',
      data: computedData.totalProduction.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionTotalProduction'),
      color: grey[900],
    });
  }
  if (computedData.networkLossEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.networkLosses.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionNetworkLosses'),
      color: red[100],
    });
  }
  if (computedData.endUserEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.endUser.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionEndUser'),
      color: yellow[200],
    });
  }
  if (computedData.pumpEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.pumpConsumedPower.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionPump'),
      color: teal[200],
    });
  }
  if (computedData.batteryEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.batteryConsumedPower.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionBattery'),
      color: deepPurple[100],
    });
  }
  if (computedData.batteryEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.export.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionExport'),
      color: pink[300],
    });
  }
  if (computedData.excessEnergy > 0) {
    plotData.push({
      type: 'area',
      data: computedData.excessSeries.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionExcess'),
      color: brown[200],
    });
  }
  if (computedData.customEnergyConsumption > 0) {
    plotData.push({
      type: 'area',
      data: computedData.customConsumption.reduce(halfResolutionReducer, []),
      name: t('graphConsumptionCustom'),
      color: grey[300],
    });
  }

  return (
    <PowerOrEnergyChart
      chartCallback={chartCallback}
      plotData={plotData}
      onNewExtremes={onNewExtremes}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      stack
      type={graphResolution === 'year' ? 'energy' : 'power'}
      oneBar={graphResolution === 'year'}
    />
  );
};

const mapToProps = createSelector(
  [mapActiveComputedToPropsFlat, mapGraphResolutionToProps],
  (computed, resolution) => ({
    ...computed,
    graphResolution: resolution.graphResolution,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {});

export default connector(ConsumptionChart);
