import { combineReducers } from 'redux';
import unit from './unit';
import size from './size';
import uiState from './uiState';
import data from './data';
import parameter from './parameter';
import computed from './computed';

export default combineReducers({
  unit, size, uiState, data, parameter, computed,
});
