import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { changeParams } from '../../redux/actions';
import GenericLoadFromFileDialog from './GenericLoadFromFileDialog';
import parseCustomCSV from '../../business-logic/parseCustomCSV';
import { toEnergy } from '../../domain_model/math/helpers';
import PowerUnit, { DB_POWER_UNIT } from '../../domain_model/math/PowerUnit';
import { POTENCIES, UnitScopes } from '../../domain_model/math/constants';
import { NumberConverter } from '../../domain_model/math/Unit';

type Props = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
}

const csvUnit = PowerUnit.createPowerUnit(UnitScopes.SWITZERLAND, POTENCIES.KILO);
const convertToDb = csvUnit.getConverterTo(DB_POWER_UNIT) as NumberConverter;

const LoadConsumptionFromFileDialog: FunctionComponent<Props> = (props) => {
  const { onClose, open, changeParams: _changeParams } = props;
  const { t } = useTranslation();

  const handleInput = (files: FileList | null) => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const customData = parseCustomCSV(reader.result as string).map(convertToDb);
        _changeParams({
          customConsumption: {
            dataSeries: customData,
            scaling: toEnergy(_.sum(customData)),
          },
        });
      } catch (e) {
        // Todo handle parsing error
        // eslint-disable-next-line no-console
        console.log(`File could not be parsed: ${e}`);
      }
    };

    if (files) {
      reader.readAsText(files[0]);
    }

    onClose();
  };

  return (
    <GenericLoadFromFileDialog
      text={t('loadCustomEndUserFromFileDialogText')}
      title={t('loadCustomEndUserFromFileDialogTitle')}
      open={open}
      onClose={onClose}
      handleInput={handleInput}
      accept=".csv"
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(null, { changeParams });

export default connector(LoadConsumptionFromFileDialog);
