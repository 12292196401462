import React from "react";

const SvgBarChart12 = props => (
  <svg viewBox="0 0 23 23" {...props}>
    <path d="M-.5-.5h24v24h-24v-24z" fill="none" />
    <path d="M16.5 2.5h4v7.6h-4zM2.5 4.9h4v5.2h-4zM9.5 0h4v10.1h-4zM9.8 20.8H8.1v-6.7H6.6v-1.7h3.2zM16.8 15.7c0 .9-.8 1.7-1.7 1.7h-1.8v1.7h3.4v1.7h-5v-3.3c0-.9.8-1.7 1.7-1.7h1.7v-1.7h-3.3v-1.7h3.3c.9 0 1.7.8 1.7 1.7v1.6z" />
  </svg>
);

export default SvgBarChart12;
