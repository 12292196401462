/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

type Props = {
  percentage: number;
  valueString: string;
  border: string;
  topLabel?: boolean;
}

const useStyles = makeStyles<unknown, Props>(() => createStyles({
  line: {
    position: 'absolute',
    zIndex: 1,
    height: (props) => `${(100 - props.percentage).toFixed(2)}%`,
    width: '100%',
    borderBottom: (props) => props.border,
    top: (props) => (props.percentage === 100 ? '-1px' : 0),
  },
  label: {
    position: 'absolute',
    bottom: (props: Props) => (props.topLabel ? '0' : '-2em'),
    left: '0.5em',
  },
}));


function Line(props: Props): JSX.Element {
  const classes = useStyles(props);
  const { valueString } = props;
  return (
    <div className={classes.line}>
      <Typography variant="caption" className={classes.label}>
        {valueString}
      </Typography>
    </div>
  );
}

export default Line;
