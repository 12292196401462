import React, { ReactNode, useState } from 'react';
import { createStyles, makeStyles, TableRow } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  renderHeaderCells: (expanded: boolean) => ReactNode;
  renderChildRows: (childCellClassName: string) => ReactNode;
};

const useStyles = makeStyles(() => createStyles({
  parentRow: {
    cursor: 'pointer',
  },
  childCell: {
    transition: 'all 150ms ease',
    height: '0 !important',
  },
  collapsed: {
    lineHeight: '0 !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    borderWidth: '0 !important',
    overflow: 'hidden',
    opacity: 0,
    height: '0 !important',
  },
}));

function ExpandableRow(props: Props): JSX.Element {
  const {
    renderChildRows, renderHeaderCells,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles(expanded);

  const getCollapsedClassName = () => (!expanded ? classes.collapsed : '');

  return (
    <>
      <TableRow className={classes.parentRow} hover onClick={() => setExpanded(!expanded)}>
        {renderHeaderCells(expanded)}
      </TableRow>
      {renderChildRows(clsx(classes.childCell, getCollapsedClassName()))}
    </>
  );
}

export default ExpandableRow;
