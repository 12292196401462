import {
  DataSeriesSection,
  IDataSeriesDto,
  IPreloadDataDto,
  IPreloadSecondaryDataDto,
} from '../domain_model/SourceDataCollection';
import { assertUnreachable } from '../helper';

const API = '/api';
const GET = '/get';
const PRELOAD = '/preload';
const PRELOAD_SECONDARY = '/preloadSecondary';
const LATEST_YEAR = 'latest';

function fetchBody<T>(request: RequestInfo): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(request)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const text = await response.text();
        throw new Error(`${response.status} ${text.length < 50 ? `(${text})` : ''}`);
      })
      .then((body) => {
        resolve(body);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function mapDataSectionToUrl(dataSection: DataSeriesSection): string {
  switch (dataSection) {
    case 'endUser':
      return 'endUser';
    case 'import':
      return 'import';
    case 'export':
      return 'export';
    case 'nuclear':
      return 'nuclear';
    case 'river':
      return 'river';
    case 'solarDirectA':
      return 'solarDirectA';
    case 'solarDiffuseA':
      return 'solarDiffuseA';
    case 'solarDirectB':
      return 'solarDirectB';
    case 'solarDiffuseB':
      return 'solarDiffuseB';
    case 'solarDirectC':
      return 'solarDirectC';
    case 'solarDiffuseC':
      return 'solarDiffuseC';
    case 'thermal':
      return 'thermal';
    case 'wind':
      return 'wind';
    case 'dam':
      return 'damInflux';
    default:
      assertUnreachable(dataSection);
      throw new Error();
  }
}

export async function fetchDataSeries(
  dataSection: DataSeriesSection,
  yearOrSlug?: number | string,
): Promise<IDataSeriesDto> {
  return fetchBody<IDataSeriesDto>(`${API}${GET}/${mapDataSectionToUrl(dataSection)}/${yearOrSlug || LATEST_YEAR}`);
}

export async function fetchPreloadData(): Promise<IPreloadDataDto> {
  return fetchBody<IPreloadDataDto>(`${API}${GET}${PRELOAD}`);
}

export async function fetchPreloadSecondaryData(): Promise<IPreloadSecondaryDataDto> {
  return fetchBody<IPreloadSecondaryDataDto>(`${API}${GET}${PRELOAD_SECONDARY}`);
}
