import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ExpansionPanels from './parameter/ExpansionPannels';
import DrawerContentHeader from './DrawerContentHeader';

type Props = { onClose: (withCalculation?: boolean) => void }

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '38em',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflowX: 'hidden',
  },
  header: {
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const DrawerContent = (props: Props): JSX.Element => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <form
      className={classes.root}
      autoComplete="off"
      role="presentation"
    >
      <DrawerContentHeader className={classes.header} onClose={onClose} />
      <div className={classes.content}>
        <ExpansionPanels />
      </div>

    </form>
  );
};

export default DrawerContent;
