import { ILocalizableString } from './ILocalizableString';

export class NonLocalizedString implements ILocalizableString {
  private readonly str: string;

  public constructor(str: string) {
    this.str = str;
  }

  getString(): string {
    return this.str;
  }
}
