import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import Bar, { BarDataEntry } from './Bar';

type Props = SizeMeProps & {
  data: BarDataEntry[];
  heightValue: number;
};


const useStyles = makeStyles(() => createStyles({
  barContainer: {
    width: '100%',
    flexGrow: (props: Props) => props.heightValue,
    display: 'flex',
  },
}));

const ProductionBars: FunctionComponent<Props> = (props) => {
  const { data, children, size: { width } } = props;
  const classes = useStyles(props);

  const total = data.reduce((partial, current) => partial + current.value, 0);

  const shouldIconRender = (value: number) => (value / total) * (width || 0) > 28;

  return (
    <div className={classes.barContainer}>
      {data.map((entry, i) => (
        <Bar
          value={entry.value}
          color={entry.color}
          Icon={shouldIconRender(entry.value) ? entry.Icon : undefined}
          key={i}
        />
      ))}
      {children}
    </div>
  );
};

export default sizeMe()(ProductionBars);
