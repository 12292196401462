/* eslint-disable */
import { IParameterCollection } from './domain_model/Parameters';
import { IPreloadDataDto } from './domain_model/SourceDataCollection';
import { LocalizedString } from './domain_model/scenario/LocalizedString';
import Scenario from './domain_model/scenario/Scenario';
import { getMostCurrentYear, getScalingFunction } from './helper';

export const MOST_CURRENT_YEAR_FALLBACK = 2019; // Hier das aktuellste Daten-Jahr eintragen

export const getStatusQuo = (
  preloadData?: IPreloadDataDto,
  year?: string,
): IParameterCollection => {
  const usedYear = (year || getMostCurrentYear(MOST_CURRENT_YEAR_FALLBACK, preloadData)).toString();
  const getScaling = getScalingFunction(usedYear, preloadData);
  return {
    version: 9, /* Die Version der Datenstruktur. Dient dazu, die Kompatibilität mit Szenarien,
                   welche aus älteren PowerCheck Versionen exortiert wurdern, sicherzustellen. */
    endUser: {
      yearOrSlug: usedYear,
      scaling: getScaling('endUser'),
      additionalEMobilityEnergy: 0,
    },
    import: {
      yearOrSlug: usedYear,
      scaling: getScaling('import'),
    },
    export: {
      yearOrSlug: usedYear,
      scaling: getScaling('export'),
    },
    nuclear: {
      yearOrSlug: usedYear,
      scaling: getScaling('nuclear'),
    },
    thermal: {
      yearOrSlug: usedYear,
      scaling: getScaling('thermal'),
    },
    solar: {
      yearOrSlug: usedYear,
      scaling: 2.265,
      version: 'PopulationWeighted',
      azimuth: [
        {
          angle: 0,
          weight: 36 / 7.83,
          label: 'N',
        },
        {
          angle: 45,
          weight: 29 / 7.83,
          label: 'NE',
        },
        {
          angle: 90,
          weight: 101 / 7.83,
          label: 'E',
        },
        {
          angle: 135,
          weight: 331 / 7.83,
          label: 'SE',
        },
        {
          angle: 180,
          weight: 783 / 7.83,
          label: 'S',
        },
        {
          angle: 225,
          weight: 293 / 7.83,
          label: 'SW',
        },
        {
          angle: 270,
          weight: 134 / 7.83,
          label: 'W',
        },
        {
          angle: 315,
          weight: 46 / 7.83,
          label: 'NW',
        },
      ],
      tilt: [
        {
          angle: 0,
          weight: 123 / 9.38,
          label: '0°',
        },
        {
          angle: 15,
          weight: 938 / 9.38,
          label: '15°',
        },
        {
          angle: 30,
          weight: 564 / 9.38,
          label: '30°',
        },
        {
          angle: 45,
          weight: 121 / 9.38,
          label: '45°',
        },
        {
          angle: 60,
          weight: 0 / 9.38,
          label: '60°',
        },
        {
          angle: 75,
          weight: 0 / 9.38,
          label: '75°',
        },
        {
          angle: 90,
          weight: 17 / 9.38,
          label: '90°',
        },
      ],
    },
    wind: {
      yearOrSlug: usedYear,
      scaling: 0.0754,
    },
    river: {
      yearOrSlug: usedYear,
      scaling: getScaling('river'),
    },
    waterStorage: {
      outputEfficiency: 0.9,
      intakeEfficiency: 0.85,
      dam: {
        yearOrSlug: usedYear,
        outputPower: 8.41,
        maxUsableCapacity: 8850, // nutzbar
        initialUsableCapacity: 5917, // nutzbar
        minimalPower: 0.89,
      },
      pumpStorage: {
        intakePower: 2.64,
        outputPower: 3.18,
        maxUsableCapacity: 200, // nutzbar
        initialUsableCapacity: 0, // nutzbar
      },
    },
    batteryStorage: {
      intakePowerInHours: 1,
      outputPowerInHours: 1,
      maxUsableCapacity: 0.01, // nutzbar
      initialUsableCapacity: 0.0, // nutzbar
      outputEfficiency: 0.9,
      intakeEfficiency: 0.9,
    },
    customConsumption: {
      dataSeries: [],
      scaling: 0,
    },
    customProduction: {
      dataSeries: [],
      scaling: 0,
    },
    network: {
      lossFactor: 0.07,
    },
  };
};

const getPredefinedScenarios = (preloadData?: IPreloadDataDto): Scenario[] => {
  const parameter2019withoutImportExport = getStatusQuo(preloadData, '2019');
  const parameter2019 = getStatusQuo(preloadData, '2019');
  const parameter2018 = getStatusQuo(preloadData, '2018');
  const parameter2017 = getStatusQuo(preloadData, '2017');
  const parameter2016 = getStatusQuo(preloadData, '2016');
  const parameter2015 = getStatusQuo(preloadData, '2015');
  const parameter2014 = getStatusQuo(preloadData, '2014');
  const bfeZeroBasis2050 = getStatusQuo(preloadData, '2019');

  // Hier können paramerter einzelner Jahre geändert werden. ZB:
  //
  // parameter2018.batteryStorage.initialUsableCapacity = 0;
  //
  parameter2019withoutImportExport.import.scaling = 0;
  parameter2019withoutImportExport.export.scaling = 0;
  parameter2018.solar.scaling = 1.982;
  parameter2017.solar.scaling = 1.733;
  parameter2016.solar.scaling = 1.471;
  parameter2015.solar.scaling = 1.157;
  parameter2014.solar.scaling = 0.844;
  parameter2016.wind.scaling = 0.0624;
  parameter2015.wind.scaling = 0.06029;
  parameter2014.wind.scaling = 0.06029;

  parameter2018.waterStorage.dam.maxUsableCapacity = 8840;
  parameter2016.waterStorage.dam.maxUsableCapacity = 8820;
  parameter2014.waterStorage.dam.maxUsableCapacity = 8800;

  parameter2018.waterStorage.dam.outputPower = 8.35;
  parameter2016.waterStorage.dam.outputPower = 8.16;
  parameter2014.waterStorage.dam.outputPower = 8.27;

  parameter2018.waterStorage.dam.initialUsableCapacity = 4493;
  parameter2017.waterStorage.dam.initialUsableCapacity = 4594;
  parameter2016.waterStorage.dam.initialUsableCapacity = 4204;
  parameter2015.waterStorage.dam.initialUsableCapacity = 5549;
  parameter2014.waterStorage.dam.initialUsableCapacity = 5406;

  parameter2018.waterStorage.pumpStorage.outputPower = 3.14;
  parameter2017.waterStorage.pumpStorage.outputPower = 2.64;
  parameter2015.waterStorage.pumpStorage.outputPower = 1.89;

  parameter2017.waterStorage.pumpStorage.intakePower = 2.14;
  parameter2015.waterStorage.pumpStorage.intakePower = 1.47;


  bfeZeroBasis2050.endUser.scaling = 50100;
  bfeZeroBasis2050.endUser.additionalEMobilityEnergy = 13100;
  bfeZeroBasis2050.import.scaling = 9000;
  bfeZeroBasis2050.import.yearOrSlug = 'bfe_zero_basic_2050';
  bfeZeroBasis2050.export.scaling = 0;
  bfeZeroBasis2050.nuclear.scaling = 0;
  bfeZeroBasis2050.thermal.scaling = 0.35;
  bfeZeroBasis2050.river.scaling = 3.63;
  bfeZeroBasis2050.solar.scaling = 37.5;
  bfeZeroBasis2050.wind.scaling = 2.2;
  bfeZeroBasis2050.waterStorage.dam.yearOrSlug = '2018';
  bfeZeroBasis2050.waterStorage.dam.outputPower = 9;
  bfeZeroBasis2050.waterStorage.dam.maxUsableCapacity = 10000;
  bfeZeroBasis2050.waterStorage.dam.initialUsableCapacity = 5500;
  bfeZeroBasis2050.waterStorage.pumpStorage.intakePower = 6;
  bfeZeroBasis2050.waterStorage.pumpStorage.outputPower = 6;


  return [
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuoNoImpotExport'),
      new LocalizedString('scenarioDescriptionStatusQuoNoImpotExport'),
      parameter2019withoutImportExport,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2019'),
      new LocalizedString('scenarioDescriptionStatusQuo2019'),
      parameter2019,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2018'),
      new LocalizedString('scenarioDescriptionStatusQuo2018'),
      parameter2018,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2017'),
      new LocalizedString('scenarioDescriptionStatusQuo2017'),
      parameter2017,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2016'),
      new LocalizedString('scenarioDescriptionStatusQuo2016'),
      parameter2016,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2015'),
      new LocalizedString('scenarioDescriptionStatusQuo2015'),
      parameter2015,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleStatusQuo2014'),
      new LocalizedString('scenarioDescriptionStatusQuo2014'),
      parameter2014,
    ),
    new Scenario(
      new LocalizedString('scenarioTitleBfeZeroBasis2050'),
      new LocalizedString('scenarioDescriptionBfeZeroBasis2050'),
      bfeZeroBasis2050,
    ),
  ];
};

export default getPredefinedScenarios;
