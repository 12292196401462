import React from 'react';
import { IconButton } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/DeleteForever';
import { connect, ConnectedProps } from 'react-redux';
import { WHITE_BUTTON_THEME } from '../../domain_model/Themes';
import { mapParamsToProps } from '../../redux/mappers';
import { deleteComputedData, deleteScenario } from '../../redux/actions';

type Props = PropsFromRedux;

function DeleteScenarioButton(props: Props) {
  const {
    deleteScenario: _deleteScenario,
    activeScenario,
    scenarios,
    deleteComputedData: _deleteComputedData,
  } = props;

  function handleDelete() {
    if (activeScenario === null) {
      throw new Error('Delete failed: No scenario selected');
    }
    _deleteComputedData(scenarios[activeScenario].id);
    _deleteScenario(activeScenario);
  }

  return (
    <ThemeProvider theme={WHITE_BUTTON_THEME}>
      <IconButton onClick={handleDelete} aria-label="Save" href="">
        <SaveIcon />
      </IconButton>
    </ThemeProvider>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { deleteScenario, deleteComputedData });

export default connector(DeleteScenarioButton);
