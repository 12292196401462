import React from "react";

const SvgPump = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M2 4.3v1.9c1.4 0 2.2-.4 3-.8.6-.3 1.1-.6 2-.6.9 0 1.4.3 2.1.6.8.4 1.6.8 2.9.8s2.2-.4 2.9-.8c.7-.3 1.2-.6 2.1-.6s1.4.3 2 .6c.8.4 1.6.8 3 .8V4.3c-.9 0-1.4-.3-2-.6-.8-.4-1.6-.8-3-.8s-2.2.4-2.9.8c-.7.3-1.2.6-2.1.6-.9 0-1.4-.3-2.1-.6-.7-.4-1.5-.8-2.9-.8s-2.2.4-3 .8c-.6.3-1.1.6-2 .6zM4 7.8c-.6.3-1.1.6-2 .6v2c1.4 0 2.2-.4 3-.8.6-.4 1.1-.6 2-.6.9 0 1.4.3 2.1.6.8.4 1.6.8 2.9.8s2.2-.4 2.9-.8c.7-.4 1.2-.6 2.1-.6.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8v-2c-.9 0-1.4-.3-2-.6-.8-.4-1.6-.8-3-.8s-2.2.4-2.9.8c-.7.4-1.1.6-2.1.6s-1.4-.3-2.1-.6C9.2 7.4 8.4 7 7 7s-2.2.4-3 .8zM17 21.2l4-4h-3v-6h-2v6h-3zM3 15.2h3v6h2v-6h3l-4-4z" />
  </svg>
);

export default SvgPump;
