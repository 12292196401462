import React from 'react';
import { styled } from '@material-ui/core';
import { IComputedSummary } from '../domain_model/ComputedDataCollection';
import { customIconStyles } from '../domain_model/GlobalStyleConstants';

export const beautifyNumber = (
  x: number,
  fractionDigits = 0,
  noPlus?: boolean,
): string => {
  const outputNumber = ((+x.toFixed(fractionDigits)) || 0);
  return `${outputNumber > 0 && !noPlus ? '+' : ''}${outputNumber.toLocaleString(
    'de-CH',
    { maximumFractionDigits: fractionDigits },
  )}`;
};

export const calculateYearlyBalanceTotal = (computedSummary: IComputedSummary): number => (
  computedSummary.nuclearEnergyProduction
  + computedSummary.thermalEnergyProduction
  + computedSummary.solarEnergyProduction
  + computedSummary.windEnergyProduction
  + computedSummary.riverEnergyProduction
  + computedSummary.damEnergyProduction
  + computedSummary.pumpEnergyProduction
  - computedSummary.pumpEnergyConsumption
  + computedSummary.batteryEnergyProduction
  - computedSummary.batteryEnergyConsumption
  + computedSummary.shortageEnergy
  - computedSummary.excessEnergy
  + computedSummary.importEnergy
  - computedSummary.exportEnergy
  + computedSummary.customEnergyProduction
  - computedSummary.customEnergyConsumption
  - computedSummary.endUserEnergyConsumption
  - computedSummary.networkLossEnergyConsumption
);

export const halfResolutionReducer = (
  partial: number[],
  currentValue: number,
  index: number,
): number[] => {
  if (index % 2 === 0) {
    // eslint-disable-next-line no-param-reassign
    partial[index / 2] = currentValue / 2;
  } else {
    // eslint-disable-next-line no-param-reassign
    partial[(index - 1) / 2] += currentValue / 2;
  }
  return partial;
};

type CustomIconFactory = (iconComp: React.FunctionComponent) => (
  (props: { className: string }) => JSX.Element
)

export const customIconFactory: CustomIconFactory = (
  iconComponent,
) => (props: { className: string; }) => {
  const { className } = props;
  const Icon = styled(iconComponent)(customIconStyles);
  return (
    <div className={className}>
      <Icon />
    </div>
  );
};
