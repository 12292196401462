import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
  },
  select: {
    height: '2.5em',
    minWidth: '7em',
    overflow: 'hidden',

    '&:first-child': {
      '& div': {
        paddingRight: '2em',
      },
    },
  },
}));

type Props = {
  value: unknown;
  onChange: (newValue: unknown) => void;
  name: string;
}

const SimpleSelect: FunctionComponent<Props> = (props) => {
  const {
    children, value, onChange, name,
  } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    onChange(event.target.value);
  };

  return (
    <form className={classes.root} autoComplete="off">

      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          className={classes.select}
          value={value}
          onChange={handleChange}
          autoWidth
          inputProps={{
            name,
          }}
        >
          {children}
        </Select>
      </FormControl>

    </form>
  );
};

export default SimpleSelect;
