import React from "react";

const SvgImportExport = props => (
  <svg
    id="import_export_svg__Ebene_1"
    x={0}
    y={0}
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".import_export_svg__st2{display:inline}.import_export_svg__st4{fill:#fff}"
      }
    </style>
    <path fill="none" d="M0 0h24v24H0z" id="import_export_svg__Ebene_1_1_" />
    <path d="M21.6 5.5h-8.5C13 4.3 12 3.4 10.8 3.4H2.4C1.1 3.4.1 4.4.1 5.7v10.6c0 1.3 1 2.3 2.3 2.3H11.1c.2 1.1 1.1 2 2.3 2h8.2c1.3 0 2.3-1 2.3-2.3V7.8c0-1.3-1-2.3-2.3-2.3zM16.2 17v-3H11v2.3c0 .2-.1.3-.3.3H2.4c-.2 0-.3-.1-.3-.3V5.7c0-.2.1-.3.3-.3h8.3c.2 0 .3.1.3.3V10H7.6V7l-4 4 4 4v-3h8.7V9l4 4-4.1 4z" />
  </svg>
);

export default SvgImportExport;
