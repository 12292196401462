import React from 'react';
import { Drawer } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { mapUiStateToProps } from '../../redux/mappers';
import {
  changeCalculationWarningState,
  changeExpandedSection,
  changeParamSettingsOpen,
} from '../../redux/actions';
import DrawerContent from './DrawerContent';
import LandingPage from '../LandingPage';
import { CalculationWarningState } from '../../redux/types/uiTypes';
import CalculationWarnigDialog from './CalculationWarningDialog';

type Props = PropsFromRedux


const ScenarioDrawer = (props: Props) => {
  const {
    paramsOpen,
    calculationWarningState,
    changeParamSettingsOpen: setOpenState,
    changeExpandedSection: setExpanded,
    changeCalculationWarningState: setWarning,
  } = props;

  const calculationWarningIsDisabled = () => (
    calculationWarningState === CalculationWarningState.Disabled
  );

  const handleClose = (skipWarning?: boolean) => {
    if (skipWarning || calculationWarningIsDisabled()) {
      setExpanded(false);
      setOpenState(false);
    } else {
      // show warning
      setWarning(CalculationWarningState.Open);
    }
  };

  return (
    <div>
      <Drawer
        ModalProps={{ keepMounted: true }}
        open={paramsOpen}
        onClose={() => handleClose()}
      >
        {paramsOpen && <LandingPage onCloseDrawer={() => handleClose(true)} />}
        <DrawerContent onClose={handleClose} />
      </Drawer>
      <CalculationWarnigDialog onClose={() => handleClose(true)} />
    </div>
  );
};

const mapToProps = createSelector(
  [mapUiStateToProps],
  (uiState) => ({
    paramsOpen: uiState.paramsOpen,
    calculationWarningState: uiState.calculationWarningState,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {
  changeParamSettingsOpen,
  changeExpandedSection,
  changeCalculationWarningState,
});

export default connector(ScenarioDrawer);
