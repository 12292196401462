import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapCalcStatesToProps } from '../redux/mappers';
import CalculationState from '../domain_model/CalculationState';
import LoadingScreen from './shared/LoadingScreen';

type Props = PropsFromRedux;

const CalculationLoadingScreen = (props: Props) => {
  const {
    calculationStates: {
      activeScenario, comparedScenarios,
    },
  } = props;

  if (
    activeScenario === CalculationState.InProgress
    || activeScenario === CalculationState.Requested
    || comparedScenarios === CalculationState.InProgress
    || comparedScenarios === CalculationState.Requested
  ) {
    return <LoadingScreen />;
  }
  return null;
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapCalcStatesToProps, {});

export default connector(CalculationLoadingScreen);
