import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';


type Props = {
  open: boolean;
  onClose: () => void;
}

const Feedback = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { onClose, open } = props;

  const [formContent, setFormContent] = React.useState<{
    replyToAddress: string;
    feedbackText: string;
  }>({
    replyToAddress: '',
    feedbackText: '',
  });

  const [status, setStatus] = React.useState<'SUCCESS' | 'ERROR' | 'EDITING'>('EDITING');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormContent({
      ...formContent,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleRetry = () => {
    setStatus('EDITING');
  };

  const handleClose = () => {
    setStatus('EDITING');
    onClose();
  };

  const handleSuccessClose = () => {
    setFormContent({ replyToAddress: '', feedbackText: '' });
    handleClose();
  };

  const submitForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const form = ev.currentTarget;
    const data = new FormData();
    data.append('mail', formContent.replyToAddress);
    data.append('feedback-text', formContent.feedbackText);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  const inputContent = (
    <>
      <DialogContent>
        <DialogContentText>
          {t('feedbackText').split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
        <TextField
          onChange={handleChange}
          value={formContent.replyToAddress}
          label={t('yourEmailAddress')}
          name="replyToAddress"
          inputProps={{
            form: 'send-feedback-form',
          }}
          type="email"
          margin="dense"
          variant="outlined"
        />
        <TextField
          onChange={handleChange}
          value={formContent.feedbackText}
          label={t('yourFeedback')}
          name="feedbackText"
          inputProps={{
            form: 'send-feedback-form',
          }}
          type="text"
          autoFocus
          multiline
          rows={10}
          margin="dense"
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>

        <form
          id="send-feedback-form"
          action="https://formspree.io/mlqjprbx"
          method="POST"
          onSubmit={submitForm}
        >
          <Button
            type="submit"
            color="primary"
          >
            {t('send')}
          </Button>

        </form>

      </DialogActions>
    </>
  );

  const successContent = (
    <>
      <DialogContent>
        <DialogContentText>
          {t('feedbackSendSuccess').split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSuccessClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </>
  );

  const errorContent = (
    <>
      <DialogContent>
        <DialogContentText>
          {t('feedbackSendError').split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleRetry} color="primary">
          {t('retry')}
        </Button>
      </DialogActions>
    </>
  );

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('feedback')}</DialogTitle>
        {status === 'EDITING' && inputContent}
        {status === 'SUCCESS' && successContent}
        {status === 'ERROR' && errorContent}
      </Dialog>
    </div>
  );
};

export default Feedback;
