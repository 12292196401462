import React from 'react';
import {
  makeStyles, Paper, Theme, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { createSelector } from 'reselect';
import { Trans, useTranslation } from 'react-i18next';
import { mapActiveComputedToProps, mapSizeToProps } from '../../redux/mappers';
import { beautifyNumber } from '../helper';
import {
  ExcessText, LevelChangesText, TimeDeficitText, TippText,
} from './TrafficLightTexts';

type Props = PropsFromRedux;

const useStyles = makeStyles<Theme, Props>((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  container: {
    flexBasis: 0,
    flexGrow: 1,
    padding: theme.spacing(1.5),
    border: 'solid 0.016em',
    margin: '-0.030em',
    borderColor: 'rgb(224, 224, 224)',

    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    whiteSpace: 'nowrap',
  },
  textContainer: {
    flexBasis: '50em',
    flexGrow: 1,
    textAlign: 'left',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '& > p': {
      fontSize: '0.9rem',
    },
  },
}));


function TrafficLights(props: Props) {
  const {
    selfSufficiencyPercentage,
    usableStorageDifference,
    importMaxPower,
    importDurationH,
    totalEnergyProduction,
    totalEnergyConsumptionPlusExport,
    usableFinalStorageLevel,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  const nettNationalExcessEnergy = totalEnergyProduction - totalEnergyConsumptionPlusExport;
  const importDurationHRounded = Math.round(importDurationH);

  if (selfSufficiencyPercentage === 0 && importMaxPower === 0 && importDurationH === 0) {
    return null;
  }

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.container}>
          <Typography variant="caption">
            {t('trafficLightSelfSufficiencyFactor')}
          </Typography>
          <Typography variant="h5" className={classes.value}>
            {`${beautifyNumber(selfSufficiencyPercentage, 0, true)}`}
            &#8202;%
          </Typography>
        </div>
        <div className={classes.container}>
          <Typography variant="caption">
            {nettNationalExcessEnergy > 0
              ? (
                <Trans i18nKey="trafficLightNationalProductionExcess">
                  <strong>
                    Überschuss
                  </strong>
                  Inland-Produktion
                </Trans>
              )
              : (
                <Trans i18nKey="trafficLightNationalProductionShortage">
                  <strong>
                    Mangel
                  </strong>
                  Inland-Produktion
                </Trans>
              )}
          </Typography>
          <Typography variant="h5" className={classes.value}>
            {`${beautifyNumber(Math.abs(nettNationalExcessEnergy), 0, true)}`}
            &#8201;
            <small>GWh</small>
          </Typography>
        </div>
        <div className={classes.container}>
          <Typography variant="caption">
            {t('trafficLightLevelChanges')}
          </Typography>
          <Typography variant="h5" className={classes.value}>
            {`${beautifyNumber(usableStorageDifference, 0)}`}
            &#8201;
            <small>GWh</small>
          </Typography>
        </div>
        <div className={clsx(classes.textContainer, classes.container)}>
          <Typography>
            <TimeDeficitText XXX={importDurationHRounded} YYY={importMaxPower} />
            {' '}
            <ExcessText
              E_prod_inland={totalEnergyProduction}
              E_cons_tot={totalEnergyConsumptionPlusExport}
            />
            {' '}
            <LevelChangesText
              Delta_Speicher={usableStorageDifference}
              Cap_Speicher={usableFinalStorageLevel}
            />
            {' '}
            <TippText
              E_prod_inland={totalEnergyProduction}
              E_cons_tot={totalEnergyConsumptionPlusExport}
              Delta_Speicher={usableStorageDifference}
              Cap_Speicher={usableFinalStorageLevel}
            />
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapActiveComputedToProps, mapSizeToProps],
  (preComputed, sizeState) => ({
    ...sizeState,
    selfSufficiencyPercentage: preComputed.activeComputedData.computedSummary
      .selfSufficiencyPercentage,
    usableStorageDifference: preComputed.activeComputedData.computedSummary.usableStorageDifference,
    importDurationH: preComputed.activeComputedData.computedSummary.importDurationH,
    importMaxPower: preComputed.activeComputedData.computedSummary.importMaxPower,
    totalEnergyProduction: preComputed.activeComputedData.computedSummary.totalEnergyProduction,
    totalEnergyConsumptionPlusExport: preComputed.activeComputedData.computedSummary
      .totalEnergyConsumptionPlusExport,
    usableFinalStorageLevel: preComputed.activeComputedData.computedSummary.usableFinalStorageLevel,
  }),
);
const connector = connect(mapToProps, {});

export default connector(TrafficLights);
