import { ActionTypes } from '../types';
import CalculationState from '../../domain_model/CalculationState';
import {
  CalculationWarningState,
  CHANGE_CALCULATION_STATE,
  CHANGE_CALCULATION_WARNING_STATE,
  CHANGE_COMPARE_CHOOSER_OPEN,
  CHANGE_COMPARE_OPEN,
  CHANGE_EXPANDED_SECTION,
  CHANGE_GRAPH_RESOLUTION,
  CHANGE_GRAPH_STYLE,
  CHANGE_HIGHLIGHT_STATE,
  CHANGE_LANDING_PAGE_OPEN,
  CHANGE_OVERVIEW_OPEN,
  CHANGE_PARAM_SCENARIO_SELECT_OPEN,
  CHANGE_PARAM_SETTINGS_OPEN,
  IUiState,
  REGISTER_CONNECTION_ERROR_LOOP,
  RESOLVE_CONNECTION_ERROR_LOOP,
  SET_COMPARE_SCENARIOS,
} from '../types/uiTypes';
import { fireAnalyticsEvent, fireSectionViewEvent } from '../../analytics/analytics';
import { E } from '../../analytics/types';

const initialState: IUiState = {
  paramsOpen: true,
  paramsScenarioSelectOpen: false,
  landingPageOpen: true,
  overviewOpen: true,
  calculationWarningState: CalculationWarningState.Closed,
  calculationStates: {
    activeScenario: CalculationState.Idle,
    comparedScenarios: CalculationState.Idle,
    solarLoadActivePreCalculation: CalculationState.Requested,
  },
  expandedSection: false,
  compareState: {
    isComparisonActive: false,
    isCompareChooserOpen: false,
    compareScenarioA: null,
    compareScenarioB: null,
  },
  highlightState: {
    highlightCalculateButton: false,
    highlightParameterSections: false,
    highlightScenarioSelect: false,
    highlightSaveButton: false,
  },
  graphStyle: 'bar',
  graphResolution: 'year',
  connectionsInErrorLoop: 0,
};

export default uiState;

function uiState(state = initialState, action: ActionTypes): IUiState {
  switch (action.type) {
    case CHANGE_PARAM_SETTINGS_OPEN: {
      return {
        ...state,
        paramsOpen: action.payload,
      };
    }
    case CHANGE_OVERVIEW_OPEN: {
      return {
        ...state,
        overviewOpen: action.payload,
      };
    }
    case CHANGE_CALCULATION_STATE:
      switch (action.payload.targetState) {
        case 'forActiveScenario':
          return {
            ...state,
            calculationStates: {
              ...state.calculationStates,
              activeScenario: action.payload.stateValue,
            },
          };
        case 'forCompareScenarios':
          return {
            ...state,
            calculationStates: {
              ...state.calculationStates,
              comparedScenarios: action.payload.stateValue,
            },
          };
        case 'forSolarLoadPreCalc':
          return {
            ...state,
            calculationStates: {
              ...state.calculationStates,
              solarLoadActivePreCalculation: action.payload.stateValue,
            },
          };
        default:
          throw new Error('unknown CalculationState target');
      }
    case CHANGE_EXPANDED_SECTION:
      action.payload && fireSectionViewEvent(action.payload);
      return {
        ...state,
        expandedSection: action.payload,
      };
    case CHANGE_COMPARE_CHOOSER_OPEN:
      return {
        ...state,
        compareState: {
          ...state.compareState,
          isCompareChooserOpen: action.payload,
        },
      };
    case CHANGE_COMPARE_OPEN:
      action.payload && fireAnalyticsEvent(E.compare);
      return {
        ...state,
        compareState: {
          ...state.compareState,
          isComparisonActive: action.payload,
        },
      };
    case SET_COMPARE_SCENARIOS:
      return {
        ...state,
        compareState: {
          ...state.compareState,
          compareScenarioA: action.payload.scenarioAIndex,
          compareScenarioB: action.payload.scenarioBIndex,
        },
      };
    case CHANGE_HIGHLIGHT_STATE:
      return {
        ...state,
        highlightState: {
          ...state.highlightState,
          ...action.payload,
        },
      };
    case CHANGE_PARAM_SCENARIO_SELECT_OPEN:
      return {
        ...state,
        paramsScenarioSelectOpen: action.payload,
      };
    case CHANGE_LANDING_PAGE_OPEN:
      return {
        ...state,
        landingPageOpen: action.payload,
      };
    case CHANGE_GRAPH_STYLE:
      return {
        ...state,
        graphStyle: action.payload,
      };
    case CHANGE_GRAPH_RESOLUTION:
      return {
        ...state,
        graphResolution: action.payload,
      };
    case CHANGE_CALCULATION_WARNING_STATE:
      return {
        ...state,
        calculationWarningState: action.payload,
      };
    case REGISTER_CONNECTION_ERROR_LOOP:
      return {
        ...state,
        connectionsInErrorLoop: state.connectionsInErrorLoop + 1,
      };
    case RESOLVE_CONNECTION_ERROR_LOOP:
      return {
        ...state,
        connectionsInErrorLoop: state.connectionsInErrorLoop - 1,
      };
    default:
      return state;
  }
}
