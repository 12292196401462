import React, { Suspense } from 'react';
import './App.css';
import 'typeface-roboto';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import store from './redux/store';
import ScreenSizeUpdater from './screen_size_handling/ScreenSizeUpdater';
import PageLayout from './components/PageLayout';
import { MAIN_THEME } from './domain_model/Themes';
import DataInitialization from './controller/DataInitialization';
import DataController from './controller/DataController';
import NotificationProvider from './components/NotificationProvider';
import PageCloseDialog from './components/PageCloseDialog';
import HighlightController from './controller/HighlightController';
import PreCalculationActiveController from './controller/PreCalculationActiveController';
import PreCalculationCompareController from './controller/PreCalculationCompareController';
import { AnalyticController } from './analytics/analytics';


// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

export default function App(): JSX.Element {
  return (
    // i18n translations might still be loaded by the xhr backend
    // use react's Suspense
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <ThemeProvider theme={MAIN_THEME}>
          <NotificationProvider>
            <DataController />
            <PreCalculationActiveController />
            <PreCalculationCompareController />
            <DataInitialization />
            <HighlightController />
            <AnalyticController />
            <ScreenSizeUpdater />
            <PageCloseDialog />
            <CssBaseline />
            <PageLayout />
          </NotificationProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}
