import Size from './ScreenSize';

export default class ScreenSizeDefinitions {
  public readonly extraSmall: Size;
  public readonly small: Size;
  public readonly medium: Size;
  public readonly mediumLarge: Size;
  public readonly large: Size;
  public readonly extraLarge: Size;

  public readonly sizes: Size[];

  public constructor() {
    this.sizes = [];

    this.extraLarge = new Size('extraLarge', 2500);
    this.sizes.push(this.extraLarge);

    this.large = new Size('large', 1484, this.extraLarge);
    this.sizes.push(this.large);

    this.mediumLarge = new Size('mediumLarge', 1281, this.large);
    this.sizes.push(this.mediumLarge);

    this.medium = new Size('medium', 715, this.mediumLarge);
    this.sizes.push(this.medium);

    this.small = new Size('small', 347, this.medium);
    this.sizes.push(this.small);

    this.extraSmall = new Size('extraSmall', 0, this.small);
    this.sizes.push(this.extraSmall);
  }

  public getSizeByWidth(width: number): Size {
    for (let i = 0; i < this.sizes.length; i += 1) {
      if (this.sizes[i].isSize(width)) {
        return this.sizes[i];
      }
    }
    throw new Error('Width does not match any defined sizes');
  }
}
