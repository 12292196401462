import {
  FormControl, InputLabel, OutlinedInput, Select,
} from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import DetailedListItem from './DetailedListItem';

type Props<KeyType extends string | number> = {
  name: string;
  uid: string;
  managed?: Managed;
  onChange: (newValue: KeyType) => void;
  entries: SelectEntry<KeyType>[];
  activeEntry: KeyType | null;

  className?: string;
  labelClassName?: string;
  selectClassName?: string;
  selectMenuClassName?: string;
} ;

type Managed = {
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
}

export type SelectEntry<KeyType extends string | number> = {
  key: KeyType;
  label: string;
  subLabel?: string;
}

export default function ScenarioSelect<KeyType extends string | number>(
  props: Props<KeyType>,
): JSX.Element {
  const {
    name,
    uid,
    activeEntry,
    onChange,
    entries,
    className,
    labelClassName,
    selectClassName,
    selectMenuClassName,
    managed,
  } = props;

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [open, setOpen] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  function renderValue(value: unknown) {
    if (value === '') {
      return value;
    }
    const entry = _.find(entries, (x: SelectEntry<KeyType>) => x.key === value);
    return entry ? entry.label : '';
  }

  const isActiveEntryValid = activeEntry !== null
    && (entries.map((e) => e.key).includes(activeEntry));

  return (
    <FormControl
      variant="outlined"
      component="div"
      className={className}
    >
      <InputLabel ref={inputLabel} htmlFor={uid} className={labelClassName}>
        {name}
      </InputLabel>
      <Select
        value={isActiveEntryValid ? activeEntry : ''}
        className={selectClassName}
        classes={{ selectMenu: selectMenuClassName }}
        native={false}
        open={managed ? managed.open : open}
        onOpen={managed ? managed.onOpen : () => setOpen(true)}
        onClose={managed ? managed.onClose : () => setOpen(false)}
        renderValue={renderValue}
        input={(
          <OutlinedInput
            labelWidth={labelWidth}
            name={name}
            id={uid}
          />
        )}
      >
        {entries.map((entry) => (
          <DetailedListItem
            key={entry.key}
            entry={entry}
            onMenuItemClick={(x) => {
              managed ? managed.onClose() : setOpen(false);
              onChange(x);
            }}
            disabled={entry.key === activeEntry}
          />
        ))}
      </Select>
    </FormControl>
  );
}
