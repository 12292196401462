import { IPvOrientation, ISolarVersion, IYearOrSlug } from '../domain_model/Parameters';
import { sleep } from '../helper';

export function generateHash(solarParams: IYearOrSlug & IPvOrientation & ISolarVersion): string {
  return JSON.stringify({
    year: solarParams.yearOrSlug,
    azimuth: solarParams.azimuth,
    tilt: solarParams.tilt,
    version: solarParams.version,
  });
}

export async function startFetchLoop<DtoType>(
  fetchFn: () => Promise<DtoType>,
  onSuccess: (data: DtoType) => void,
  onFirstError: (err: string) => void,
  registerConnectionErrorLoop: () => void,
  resolveConnectionErrorLoop: () => void,
  retryInSeconds: number,
): Promise<void> {
  let errorOccurred = false;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const dto = await fetchFn();
      onSuccess(dto);
      if (errorOccurred) {
        resolveConnectionErrorLoop();
      }
      break;
    } catch (err) {
      if (!errorOccurred) {
        errorOccurred = true;
        registerConnectionErrorLoop();
        onFirstError(err);
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000 * retryInSeconds);
    }
  }
}
