import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import NuclearIcon from '@material-ui/icons/Adjust';
import ThermalIcon from '@material-ui/icons/Whatshot';
import SolarIcon from '@material-ui/icons/BrightnessLow';
import WindIcon from '@material-ui/icons/Toys';
import BatteryIcon from '@material-ui/icons/BatteryChargingFull';
import CustomIcon from '@material-ui/icons/PostAdd';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  amber,
  blue,
  brown,
  deepPurple,
  grey,
  pink,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import clsx from 'clsx';
import { mapUnitScopeToProps } from '../../../redux/mappers';
import { NumberConverter } from '../../../domain_model/math/Unit';
import { BaseUnits, POTENCIES, UnitScopes } from '../../../domain_model/math/constants';
import EnergyUnit, { DB_ENERGY_UNIT } from '../../../domain_model/math/EnergyUnit';
import { Potency } from '../../../domain_model/math/types';
import { BarDataEntry } from './Bar';
import ProductionBars from './ProductionBars';
import CustomRiverIcon from '../../../icons/River';
import ExportBar from './ExportBar';
import ImportBar from './ImportBar';
import CustomPumpIcon from '../../../icons/Pump';
import CustomDamIcon from '../../../icons/Dam';
import { STATUS_GREEN } from '../../../domain_model/Colors';
import ColorLabel, { LabelDataEntry } from './ColorLabel';
import Line from './Line';
import LineLabel from './LineLabel';
import { IComputedData } from '../../../domain_model/ComputedDataCollection';
import { beautifyNumber, customIconFactory } from '../../helper';

type Props = PropsFromRedux & {
  // eslint-disable-next-line react/no-unused-prop-types
  heightRelativeTo?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  height: number;
  computedData: IComputedData | undefined;
  className?: string;
  omitLegend?: boolean;
  completeLegend?: boolean;
};


const useStyles = makeStyles<Theme, Props>((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: (props) => (props.heightRelativeTo === undefined ? `${props.height}em` : 'auto'),
  },
  diagramContainer: {
    width: '100%',
    height: (props) => (props.heightRelativeTo === undefined ? '100%' : 'auto'),
    position: 'relative',
    marginTop: theme.spacing(3),
    borderBottom: '1px solid lightgrey',
  },
  barContainer: {
    width: '100%',
    height: (props: Props) => (
      props.heightRelativeTo && props.computedData
        ? `${
          (
            (props.computedData.computedSummary.totalEnergyProductionPlusImportAndShortage
              / props.heightRelativeTo
            )
            * props.height
          )
            .toFixed(2)
        }em`
        : '100%'
    ),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    lineHeight: '1.9em',
    marginBottom: theme.spacing(-0.5),
  },
  exportStyle: {
    opacity: 0.8,
    background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAALElEQVQYlWP4DwUMWMB/JMBAjCJcajAUYVU4ZBURGwz//0PDkZCi/////wcAksPHORGdHFAAAAAASUVORK5CYII=)',
  },
  label: {
    position: 'absolute',
    left: '0.5em',
    top: '-2em',
  },
}));

const Chart = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    unitScope,
    className,
    computedData,
    completeLegend,
    omitLegend,
  } = props;

  let potency: Potency;
  switch (unitScope) {
    case UnitScopes.PERSON:
      potency = POTENCIES.KILO;
      break;
    case UnitScopes.SWITZERLAND:
      potency = POTENCIES.GIGA;
      break;
    default:
      throw new Error('unknown unitScope');
  }
  const displayUnit = useMemo(() => (
    EnergyUnit.createEnergyUnit(unitScope, BaseUnits.WATT_HOUR, potency)
  ), [unitScope, potency]);
  const unitConverter = useMemo(() => (
    DB_ENERGY_UNIT.getConverterTo(displayUnit) as NumberConverter
  ), [displayUnit]);
  const unitName = t(displayUnit.name);
  const convertAndBeautify = (x: number) => `${beautifyNumber(unitConverter(x))} ${unitName}`;

  if (!computedData) {
    return <div className={clsx(classes.root, className || '')} />;
  }

  const {
    totalEnergyProductionPlusImportAndShortage,
    endUserEnergyConsumption,
    nuclearEnergyProduction,
    thermalEnergyProduction,
    solarEnergyProduction,
    windEnergyProduction,
    riverEnergyProduction,
    damEnergyProduction,
    pumpEnergyProduction,
    batteryEnergyProduction,
    customEnergyProduction,
    shortageEnergy,
    totalEnergyProduction,
    excessEnergy,
  } = computedData.computedSummary;

  const productionData: (BarDataEntry & LabelDataEntry)[] = [
    {
      label: t('graphProductionNuclear'),
      value: nuclearEnergyProduction,
      color: amber[200],
      Icon: NuclearIcon,
    },
    {
      label: t('graphProductionThermal'),
      value: thermalEnergyProduction,
      color: red[100],
      Icon: ThermalIcon,
    },
    {
      label: t('graphProductionSolar'),
      value: solarEnergyProduction,
      color: yellow[200],
      Icon: SolarIcon,
    },
    {
      label: t('graphProductionWind'),
      value: windEnergyProduction,
      color: grey[300],
      Icon: WindIcon,
    },
    {
      label: t('graphProductionRiver'),
      value: riverEnergyProduction,
      color: blue[100],
      Icon: customIconFactory(CustomRiverIcon),
    },
    {
      label: t('graphProductionDam'),
      value: damEnergyProduction,
      color: STATUS_GREEN,
      Icon: customIconFactory(CustomDamIcon),
    },
    {
      label: t('graphProductionPump'),
      value: pumpEnergyProduction,
      color: teal[200],
      Icon: customIconFactory(CustomPumpIcon),
    },
    {
      label: t('graphProductionBattery'),
      value: batteryEnergyProduction,
      color: deepPurple[100],
      Icon: BatteryIcon,
    },
    {
      label: t('graphProductionCustom'),
      value: customEnergyProduction,
      color: pink[200],
      Icon: CustomIcon,
    },
  ];

  const importData = {
    label: t('graphProductionShortage'),
    value: shortageEnergy,
    color: brown[200],
  };

  const exportData = {
    label: t('graphConsumptionExcess'),
    percentOfProduction: (excessEnergy / totalEnergyProduction) * 100,
    className: classes.exportStyle,
  };

  const totalData = {
    border: '2px dashed black',
    label: t('graphProductionTotalConsumption'),
    valueString: convertAndBeautify(totalEnergyProductionPlusImportAndShortage),
    percentage: 100,
  };

  const endUserData = {
    border: '2px dotted black',
    label: t('graphConsumptionEndUser'),
    valueString: convertAndBeautify(endUserEnergyConsumption),
    percentage: (endUserEnergyConsumption / totalEnergyProductionPlusImportAndShortage)
      * 100,
  };


  const diagram = (
    <div className={classes.barContainer}>
      <Line
        valueString={totalData.valueString}
        percentage={totalData.percentage}
        border={totalData.border}
        topLabel
      />
      <Line
        valueString={endUserData.valueString}
        percentage={endUserData.percentage}
        border={endUserData.border}
      />
      <ImportBar
        value={importData.value}
        color={importData.color}
      />
      <ProductionBars
        data={productionData}
        heightValue={totalEnergyProduction}
      >
        <ExportBar
          percentOfProduction={exportData.percentOfProduction}
          className={exportData.className}
        />
      </ProductionBars>
    </div>
  );


  const shouldLabelRender = (value: number) => completeLegend || value > 0;

  const productionLabels = productionData.map((entry) => (
    shouldLabelRender(entry.value) && <ColorLabel entry={entry} key={entry.label} />
  ));
  const importLabel = shouldLabelRender(importData.value)
    && <ColorLabel entry={importData} key={importData.label} />;
  const exportLabel = shouldLabelRender(exportData.percentOfProduction)
    && <ColorLabel entry={exportData} key={exportData.label} />;
  const totalLabel = <LineLabel entry={totalData} key={totalData.label} />;
  const endUserLabel = <LineLabel entry={endUserData} key={endUserData.label} />;

  return (
    <div className={clsx(classes.root, className || '')}>
      <div className={classes.diagramContainer}>
        {totalEnergyProductionPlusImportAndShortage > 0 && diagram}
      </div>
      {!omitLegend && (
        <div className={classes.legendContainer}>
          {totalLabel}
          {endUserLabel}
          {importLabel}
          {exportLabel}
          {productionLabels}
        </div>
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitScopeToProps, {});

export default connector(Chart);
