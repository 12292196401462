import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  createStyles, makeStyles, Paper, Theme,
} from '@material-ui/core';
import { mapOverviewOpenToProps } from '../../redux/mappers';
import { DESKTOP_SPACING } from '../../domain_model/GlobalStyleConstants';
import { changeOverviewOpen } from '../../redux/actions';
import VerticalBanner, { Direction } from './VerticalBanner';
import OverviewTable from './OverviewTable';
import UnitTabs from './UnitTabs';

type Props = PropsFromRedux & {
  onTransitioned: () => void;
};

const WIDTH = '18em';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    color: theme.palette.text.secondary,
    marginRight: -theme.spacing(DESKTOP_SPACING),
    display: 'flex',
    padding: '0px',
  },
  contentWrap: {
    width: (props: Props) => (props.overviewOpen ? WIDTH : 0),
    borderLeft: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    transition: 'width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
  },
  content: {
    width: WIDTH,
    paddingBottom: theme.spacing(3),
  },
}));


const Overview = (props: Props) => {
  const { overviewOpen, changeOverviewOpen: _changeOverviewOpen, onTransitioned } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <Paper className={classes.paper}>
      <VerticalBanner
        onClick={() => _changeOverviewOpen(!overviewOpen)}
        label={t('overview')}
        arrowDirection={overviewOpen ? Direction.Right : Direction.Left}
      />
      <div className={classes.contentWrap} onTransitionEnd={onTransitioned}>
        <div className={classes.content}>
          <UnitTabs />
          <OverviewTable />
        </div>
      </div>
    </Paper>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapOverviewOpenToProps, { changeOverviewOpen });

export default connector(Overview);
