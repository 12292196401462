import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import DetailedListItem, { SelectEntry } from '../shared/DetailedListItem';
import Scenario from '../../domain_model/scenario/Scenario';
import CalculationState from '../../domain_model/CalculationState';
import {
  mapActiveComputedToProps,
  mapCompareStateToProps,
  mapParamsToProps,
} from '../../redux/mappers';
import {
  changeCalculationState,
  changeCompareOpen,
  setCompareScenarios,
} from '../../redux/actions';

type Props = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
}

export type KeyType = number;

function CompareScenarioChooseDialog(props: Props) {
  const { t } = useTranslation();
  const {
    onClose,
    open,
    scenarios,
    scenarioId: activeComputedScenarioId,
    changeCompareOpen: _changeCompareOpen,
    setCompareScenarios: _setCompareScenarios,
    changeCalculationState: _changeCalculationState,
  } = props;

  const handleMenuItemClick = (newValue: KeyType) => {
    _setCompareScenarios(
      newValue,
      activeComputedScenarioId
        ? _.findIndex(
          scenarios,
          (scenario: Scenario) => scenario.id === activeComputedScenarioId,
        )
        : null,
    );
    _changeCalculationState('forCompareScenarios', CalculationState.Requested);
    onClose();
    _changeCompareOpen(true);
  };


  let scenarioEntries: (SelectEntry<KeyType> & { id: string })[] = scenarios.map((scenario, i) => ({
    label: scenario.name.getString(t),
    key: i,
    id: scenario.id,
    subLabel: scenario.description.getString(t),
  }));

  if (activeComputedScenarioId) {
    scenarioEntries = _.filter(
      scenarioEntries,
      (scenario: (SelectEntry<KeyType> & { id: string })) => (
        scenario.id !== activeComputedScenarioId
      ),
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          {t('scenarioComparisonTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('comparisonChooseScenarioText')}
          </DialogContentText>
          <Divider />
          <List>
            {scenarioEntries.map((entry) => (
              <DetailedListItem
                key={entry.key}
                entry={entry}
                onMenuItemClick={handleMenuItemClick}
              />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapParamsToProps, mapActiveComputedToProps, mapCompareStateToProps],
  (params, computed, compare) => ({
    ...params,
    ...computed.activeComputedData.scenarioInfo,
    ...compare,
  }),
);

const connector = connect(mapToProps, {
  changeCompareOpen,
  changeCalculationState,
  setCompareScenarios,
});

export default connector(CompareScenarioChooseDialog);
