import _ from 'lodash';
import { IParameterCollection } from '../Parameters';
import { ILocalizableString } from './ILocalizableString';
import { UnknownParameterCollection } from '../ParametersLegacy';

export type ScenarioDTO = {
  name: string;
  parameter: UnknownParameterCollection;
}

export type ScenarioId = string;

export default class Scenario {
  static highestIdNr = 0;
  public readonly id: string;
  public readonly name: ILocalizableString;
  public readonly description: ILocalizableString;
  public readonly parameters: IParameterCollection;
  public readonly mutable: boolean;

  public constructor(
    name: ILocalizableString,
    description: ILocalizableString,
    parameters: IParameterCollection,
    mutable?: boolean,
  ) {
    // eslint-disable-next-line no-plusplus
    this.id = `scenario_${++Scenario.highestIdNr}`;
    this.name = name;
    this.description = description;
    this.parameters = _.merge({}, parameters);
    this.mutable = mutable || false;
  }
}
