import React, { FunctionComponent } from 'react';
import {
  Button, createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import { ACCORDION_WIDTH } from '../../domain_model/GlobalStyleConstants';

type Props = {
  onClick(): void;
  label: string;
  arrowDirection: Direction;
  contained?: boolean;
  square?: boolean;
};

export enum Direction {
  Left,
  Right,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  opener: {
    width: theme.spacing(ACCORDION_WIDTH),
  },
  button: {
    height: '100%',
    width: '100%',
    minWidth: 'unset',
    overflow: 'hidden',
  },
  squareButton: {
    borderRadius: 'unset',
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    lineHeight: `${ACCORDION_WIDTH / 2}em`,
    marginTop: `${ACCORDION_WIDTH / 4 - 0.6}em`,
    textAlign: 'left',
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    fontSize: '1.2em',
  },
  icon: {
    marginTop: '0.5em',
    transform: 'rotate(180deg)',
  },
}));

const VerticalBanner: FunctionComponent<Props> = ({
  onClick, label, arrowDirection, contained, square,
}) => {
  const classes = useStyles();

  // the 'writingMode-VerticalRl'-class is defined in App.css
  // It's needed for Edge because of a JSS bug
  return (
    <div className={classes.opener}>
      <Button
        focusRipple
        className={square ? clsx(classes.button, classes.squareButton) : classes.button}
        onClick={onClick}
        color={contained ? 'primary' : undefined}
        variant={contained ? 'contained' : 'text'}
      >
        <Typography className={clsx(classes.label, 'writingMode-VerticalRl')} variant="overline">
          {label}
          {arrowDirection === Direction.Left
            ? <KeyboardArrowLeft className={classes.icon} />
            : <KeyboardArrowRight className={classes.icon} />}
        </Typography>
      </Button>
    </div>
  );
};

export default VerticalBanner;
