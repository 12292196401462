import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Tooltip, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { DeepPartial } from 'redux';
import Quantity from '../../../../domain_model/math/Quantity';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import NumberInput from '../input-single/inputs/NumberInput';
import CapacityDuoSlider from '../input-single/sliders/CapacityDuoSlider';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import { DB_ENERGY_UNIT } from '../../../../domain_model/math/EnergyUnit';
import { INumberConverterTwoWay } from '../../../../domain_model/math/Unit';
import { calculateStep } from '../helper';
import { IParameterCollection, IUsableCapacity } from '../../../../domain_model/Parameters';
import { assertUnreachable } from '../../../../helper';

const MAX_ALLOWED_DIGITS = 4;

type Props = PropsFromRedux & {
  section: 'dam' | 'pumpStorage' | 'batteryStorage';
  maxEnergy: number;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    gridArea: 'title',
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  container: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title     title   "
                        "initInput maxInput"
                        "slider    slider  "`,
  },
  initInput: {
    gridArea: 'initInput',
  },
  maxInput: {
    gridArea: 'maxInput',
  },
  slider: {
    gridArea: 'slider',
  },
}));


const CapacityInitialAndMax = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { section, maxEnergy } = props;
  const {
    changeParams: _changeParams,
  } = props;
  let initialCapacityDbValue;
  let maxCapacityDbValue;
  if (section === 'batteryStorage') {
    initialCapacityDbValue = props.activeParams[section].initialUsableCapacity;
    maxCapacityDbValue = props.activeParams[section].maxUsableCapacity;
  } else {
    initialCapacityDbValue = props.activeParams.waterStorage[section].initialUsableCapacity;
    maxCapacityDbValue = props.activeParams.waterStorage[section].maxUsableCapacity;
  }

  const max = new Quantity(maxEnergy, DB_ENERGY_UNIT, false);

  const { unit: displayUnit, val: maxValue } = max;
  const { name: displayUnitName } = displayUnit;

  const step = calculateStep(MAX_ALLOWED_DIGITS, maxValue);

  const {
    toThis: convertToDisplay, toOther: convertToDb,
  } = displayUnit.getConvertersTwoWay(DB_ENERGY_UNIT) as INumberConverterTwoWay;

  const initialCapacityDisplayValue = convertToDisplay(initialCapacityDbValue);
  const maxCapacityDisplayValue = convertToDisplay(maxCapacityDbValue);

  const setValue = (name: keyof IUsableCapacity, newValue: number) => {
    let paramChanges: DeepPartial<IParameterCollection> = {};
    switch (section) {
      case 'dam':
      case 'pumpStorage':
        paramChanges = {
          waterStorage: {
            [section]: {
              [name]: convertToDb(newValue),
            },
          },
        };
        break;
      case 'batteryStorage':
        paramChanges = {
          [section]: {
            [name]: convertToDb(newValue),
          },
        };
        break;
      default:
        assertUnreachable(section);
    }
    _changeParams(paramChanges);
  };

  const onNewSliderValues = (newDisplayValues: readonly number[]) => {
    if (newDisplayValues[0] !== initialCapacityDisplayValue) {
      setValue('initialUsableCapacity', newDisplayValues[0]);
    }
    if (newDisplayValues[1] !== maxCapacityDisplayValue) {
      setValue('maxUsableCapacity', newDisplayValues[1]);
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="overline">
        {t('storageCapacities')}
      </Typography>
      <Tooltip
        arrow
        placement="top"
        title={t('paramsStorageInitialCapacityDescription')}
      >
        <NumberInput
          className={classes.initInput}
          label={t('initialCapacity')}
          value={initialCapacityDisplayValue}
          onNewValue={(val) => setValue('initialUsableCapacity', val)}
          max={maxCapacityDisplayValue}
          step={step}
          unit={displayUnitName}
        />
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title={t('paramsStorageMaxCapacityDescription')}
      >
        <NumberInput
          className={classes.maxInput}
          label={t('maxCapacity')}
          value={maxCapacityDisplayValue}
          onNewValue={(val) => setValue('maxUsableCapacity', val)}
          step={step}
          unit={displayUnitName}
        />
      </Tooltip>
      <div className={classes.slider}>
        <CapacityDuoSlider
          values={[initialCapacityDisplayValue, maxCapacityDisplayValue]}
          onNewValues={onNewSliderValues}
          step={step * 100}
          max={maxValue}
        />
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(CapacityInitialAndMax);
