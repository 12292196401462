import * as React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { createStyles, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { PlotDataSeries } from '../../main-section/ChartComponent';

const useStyles = makeStyles(() => createStyles({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: grey[400],
    borderWidth: 1,
    overflow: 'hidden',
  },
  highCharts: {
    boxSizing: 'content-box',
    height: '100%',
    width: '100%',
  },
}));

type Props = {
  plotData: PlotDataSeries[];
  max: number;
}

const getOptions = (max: number): Highcharts.Options => ({
  chart: {
    spacing: [0, 0, 0, 0],
    style: { transform: 'scaleX(1.001)' },
    animation: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
    ceiling: max,
    max,
    endOnTick: false,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    area: {
      lineWidth: 1,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      dataGrouping: {
        approximation: 'high',
      },
      stacking: 'normal',
    },
    line: {
      enableMouseTracking: false,
      lineWidth: 0.75,
      shadow: false,
      dashStyle: 'LongDash',
    },
  },
  scrollbar: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  series: [],
});

const MiniChartComponent = React.memo((props: Props) => {
  const {
    plotData, max,
  } = props;
  const { highCharts, root } = useStyles();

  const options = getOptions(max);
  options.series = plotData;

  return (
    <div className={root}>
      <HighchartsReact
        constructorType="stockChart"
        highcharts={Highcharts}
        options={options}
        containerProps={{
          className: highCharts,
        }}
      />
    </div>
  );
});

export default MiniChartComponent;
