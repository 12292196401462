import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import NumberInput from '../input-single/inputs/NumberInput';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import Quantity from '../../../../domain_model/math/Quantity';
import { calculateSliderStep, calculateStep } from '../helper';
import { INumberConverterTwoWay } from '../../../../domain_model/math/Unit';
import { DB_POWER_UNIT } from '../../../../domain_model/math/PowerUnit';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const MAX_ALLOWED_DIGITS = 4;

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    gridArea: 'title',
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  infoButton: {
    gridArea: 'info',
    placeSelf: 'center end',
  },
  powerContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title            info             "
                        "inputPowerInput  outputPowerInput "
                        "inputPowerSlider outputPowerSlider"`,
  },
  inputPowerInput: {
    gridArea: 'inputPowerInput',
  },
  outputPowerInput: {
    gridArea: 'outputPowerInput',
  },
  inputPowerSlider: {
    gridArea: 'inputPowerSlider',
  },
  outputPowerSlider: {
    gridArea: 'outputPowerSlider',
  },
}));

type Props = PropsFromRedux & {
  maxPower: number;
  infoButtonProps?: ClickableInfoButtonProps;
};

const PumpStoragePower = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { maxPower, infoButtonProps } = props;
  const {
    changeParams: _changeParams, activeParams: {
      waterStorage: {
        pumpStorage: { intakePower: intakePowerDbValue, outputPower: outputPowerDbValue },
      },
    },
  } = props;

  const max = new Quantity(maxPower, DB_POWER_UNIT, false);

  const { unit: displayUnit, val: maxValue } = max;
  const { name: displayUnitName } = displayUnit;

  const step = calculateStep(MAX_ALLOWED_DIGITS, maxValue);
  const stepSlider = calculateSliderStep(maxValue);

  const {
    toThis: convertToDisplay, toOther: convertToDb,
  } = displayUnit.getConvertersTwoWay(DB_POWER_UNIT) as INumberConverterTwoWay;

  const intakePowerDisplayValue = convertToDisplay(intakePowerDbValue);
  const outputPowerDisplayValue = convertToDisplay(outputPowerDbValue);

  const setValue = (name: 'intakePower' | 'outputPower', newValue: number) => {
    _changeParams({
      waterStorage: {
        pumpStorage: {
          [name]: convertToDb(newValue),
        },
      },
    });
  };

  return (
    <div className={classes.powerContainer}>
      <Typography className={classes.title} variant="overline">
        {t('inputOutputPowerTitle')}
      </Typography>
      {infoButtonProps && (
        <ClickableInfoButton
          {...infoButtonProps}
          className={classes.infoButton}
        />
      )}
      <NumberInput
        className={classes.inputPowerInput}
        label={t('inputPower')}
        value={intakePowerDisplayValue}
        onNewValue={(val) => setValue('intakePower', val)}
        step={step}
        unit={displayUnitName}
      />
      <SimpleSlider
        className={classes.inputPowerSlider}
        value={intakePowerDisplayValue}
        onNewValue={(val) => setValue('intakePower', val)}
        max={maxValue}
        step={stepSlider}
      />
      <NumberInput
        className={classes.outputPowerInput}
        label={t('outputPower')}
        value={outputPowerDisplayValue}
        onNewValue={(val) => setValue('outputPower', val)}
        step={step}
        unit={displayUnitName}
      />
      <SimpleSlider
        className={classes.outputPowerSlider}
        value={outputPowerDisplayValue}
        onNewValue={(val) => setValue('outputPower', val)}
        max={maxValue}
        step={stepSlider}
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(PumpStoragePower);
