// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
import i18next from 'i18next';
import { DataSeriesSection, IPreloadDataDto } from './domain_model/SourceDataCollection';
import { toEnergy } from './domain_model/math/helpers';

export function assertUnreachable(__: never): never {
  throw new Error('Didn\'t expect to get here');
}

export const sleep = (ms: number): Promise<unknown> => (
  new Promise((resolve) => setTimeout(resolve, ms))
);

export const getMostCurrentYear = (fallback: number, preloadData?: IPreloadDataDto): number => {
  const year = preloadData ? _.last(preloadData.data.availableYearsInApi) : undefined;
  return year || fallback;
};

type Section = Extract<DataSeriesSection,
'endUser' | 'import' | 'export' | 'nuclear' | 'river' | 'thermal'>

export function getScalingFunction(
  mostCurrentYear: string,
  preloadData?: IPreloadDataDto,
): (section: Section) => number {
  return scalingFn;

  function scalingFn(section: Section) {
    switch (section) {
      case 'endUser':
      case 'import':
      case 'export':
        return (
          (
            preloadData
            && +toEnergy(preloadData.data.sumSectionDataCollection[section][mostCurrentYear] || 0)
              .toFixed(0)
          ) || 0
        );
      case 'nuclear':
      case 'river':
      case 'thermal':
        return (preloadData && preloadData.data.maxSectionDataCollection[section][mostCurrentYear])
          || 0;
      default:
        assertUnreachable(section);
        throw new Error();
    }
  }
}

export function getDokString(i18n: i18next.i18n): string {
  const lang = i18n.languages.length > 0 ? i18n.languages[0] : 'de';
  switch (lang) {
    case 'en':
      return '/docs/en/';
    case 'de':
    default:
      return '/docs/de/';
  }
}
