import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type BarDataEntry = {
  value: number;
  color: string;
  Icon?: FunctionComponent<{ className: string }>;
};

type Props = BarDataEntry;

const useStyles = makeStyles((theme: Theme) => createStyles({
  bar: {
    backgroundColor: (props) => props.color,
    flexGrow: (props: Props) => props.value,
    overflow: 'hidden',
  },
  iconContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'white',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Bar = (props: Props): JSX.Element => {
  const { Icon } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.bar)}>
      <div className={classes.iconContainer}>
        {Icon && <Icon className={classes.icon} />}
      </div>
    </div>
  );
};

export default (Bar);
