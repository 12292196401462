import { Button, ButtonProps } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerSection } from '../redux/types/uiTypes';
import { assertUnreachable, sleep } from '../helper';
import { E } from './types';
import { fireEvent } from './plausible';

export function fireSectionViewEvent(section: DrawerSection): void {
  fireEvent(`view parameterSection ${section}`);
}

export function fireAnalyticsEvent(analyticsEvent: E): void {
  fireEvent(analyticsEvent);
}

/**
 * Material UI Button with Analytics-Tracker
 *
 * @param props Button Props with extra Field analyticsEvent: E
 * @return Material UI Button
 */
export function TrackedButton(props: ButtonProps & { analyticsEvent: E }): JSX.Element {
  return (
    <Button
      onClick={(e) => {
        fireAnalyticsEvent(props.analyticsEvent);
        props.onClick && props.onClick(e);
      }}
      {...props}
    />
  );
}

function fireLang3min(lang: string) {
  switch (lang) {
    case 'de':
      fireAnalyticsEvent(E.lang3minDe);
      break;
    case 'en':
      fireAnalyticsEvent(E.lang3minEn);
      break;
    default:
  }
}

export const AnalyticController = (): null => {
  const [state, setState] = useState<'UNINITIALIZED' | 'IDLE' | 'FIRE_REQUESTED' >('UNINITIALIZED');
  const { i18n } = useTranslation();

  switch (state) {
    case 'UNINITIALIZED':
      setState('IDLE');
      sleep(1000 * 60 * 3).then(() => setState('FIRE_REQUESTED'));
      break;
    case 'FIRE_REQUESTED':
      setState('IDLE');
      fireLang3min(i18n.languages[0]);
      break;
    case 'IDLE':
      break;
    default:
      assertUnreachable(state);
  }
  return null;
};
