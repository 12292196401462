import { DATA_POINT_DURATION_H } from '../../business-logic/constants';

export function numDigits(x: number): number {
  // eslint-disable-next-line no-bitwise
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
}

export const toEnergy = (
  power: number,
  time: number = DATA_POINT_DURATION_H,
): number => power * time;

export const toPower = (
  energy: number,
  time: number = DATA_POINT_DURATION_H,
): number => energy / time;
