import { numDigits } from '../../../domain_model/math/helpers';

const stepCalculator = (availableSpace: number, nrOfDigits: number): number => (
  10 ** -(availableSpace - nrOfDigits)
);

export const calculateStep = (availableSpace: number, maxValue: number): number => {
  const nrOfDigits = numDigits(maxValue - 0.00000001);
  return stepCalculator(availableSpace, nrOfDigits);
};

export const calculateSliderStep = (maxValue: number): number => (
  stepCalculator(3, numDigits(maxValue - 0.00000001))
);

export const roundToStep = (x: number, step: number): number => Math.ceil((x) / step) * step;
