import { createMuiTheme } from '@material-ui/core';
import { PRIMARY, PRIMARY_LIGHT, SECONDARY } from './Colors';

export const MAIN_THEME = createMuiTheme({
  palette: {
    primary: { main: PRIMARY, light: PRIMARY_LIGHT },
    secondary: { main: SECONDARY },
  },
});

export const WHITE_BUTTON_THEME = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: SECONDARY,
    },
  },
});

export const DARK_THEME = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: PRIMARY, light: PRIMARY_LIGHT },
    secondary: { main: SECONDARY },
  },
});
