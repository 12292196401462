import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import UnitScopeSwitch from './UnitScopeSwitch';
import LanguageSwitch from './LanguageSwitch';
import MiscMenu from './MiscMenu';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'grid',
    gridTemplateAreas: '"title switches menu"',
    gridTemplateColumns: '1fr auto 3fr',
    alignItems: 'center',
    gridColumnGap: '2em',
  },
  '@media (max-width: 1120px)': {
    root: {
      gridTemplateAreas: `"menu  .     switches"
                          "title title title   "`,
      gridTemplateColumns: 'auto 1fr   auto',
    },
  },
  title: {
    gridArea: 'title',
    whiteSpace: 'nowrap',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    marginRight: '1em',
  },
  switches: {
    gridArea: 'switches',
    display: 'flex',
  },
  menu: {
    gridArea: 'menu',
    marginLeft: '-1em',
  },
  space: {
    width: '2em',
  },
}));

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          {t('siteTitle')}
        </Typography>
        <div className={classes.switches}>
          <UnitScopeSwitch />
          <div className={classes.space} />
          <LanguageSwitch />
        </div>
        <div className={classes.menu}>
          <MiscMenu />
        </div>
      </div>
    </>
  );
};

export default Header;
