// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParameterCollection extends IParameterCollectionV9 {
}

/**
 * Changes:
 *
 * change from year(number) to yearOrSlug(string)
 */
export interface IParameterCollectionV9 {
  version: 9;
  endUser: IYearOrSlug & IScalable & IEMobility;
  import: IYearOrSlug & IScalable;
  export: IYearOrSlug & IScalable;
  customConsumption: IDataSeries & IScalable;
  customProduction: IDataSeries & IScalable;
  nuclear: IYearOrSlug & IScalable;
  thermal: IYearOrSlug & IScalable;
  wind: IYearOrSlug & IScalable;
  river: IYearOrSlug & IScalable;
  solar: IYearOrSlug & IScalable & IPvOrientation & ISolarVersion;
  waterStorage: IStorageEfficiencies & {
    dam: IYearOrSlug & IUsableCapacity & IPassiveStoragePower & IMinimalPower
    pumpStorage: IUsableCapacity & IActiveStoragePowers
  }
  batteryStorage: IUsableCapacity & IStorageEfficiencies & IActiveStoragePowersHours;
  network: INetwork;
}

export interface IMinimalPower {
  minimalPower: GW;
}

export interface IStorageEfficiencies {
  intakeEfficiency: Factor;
  outputEfficiency: Factor;
}

export interface INetwork {
  lossFactor: Factor;
}

export interface IDataSeries {
  dataSeries: number[];
}

export interface IYearOrSlug {
  yearOrSlug: YearOrSlug;
}

export interface IScalable {
  scaling: Factor;
}

export interface IUsableCapacity {
  maxUsableCapacity: GWh;
  initialUsableCapacity: GWh;
}

export interface IPassiveStoragePower {
  outputPower: GW;
}

export interface IActiveStoragePowers extends IPassiveStoragePower {
  intakePower: GW;
}


export interface IPassiveStoragePowerHours {
  outputPowerInHours: Hours;
}

export interface IActiveStoragePowersHours extends IPassiveStoragePowerHours {
  intakePowerInHours: Hours;
}

export interface IEMobility {
  additionalEMobilityEnergy: GWh;
}

export interface IPvOrientation {
  azimuth: WeightedAngle[];
  tilt: WeightedAngle[];
}

export type WeightedAngle = {
  angle: number;
  weight: number;
  label?: string;
}

export interface ISolarVersion {
  version: SolarVersion;
}

export const solarVersions = ['PopulationWeighted', 'EqualWeighted', 'SouthWeighted'] as const;
export type SolarVersion = typeof solarVersions[number];
export const isSolarVersion = (x: string): x is SolarVersion => (
  // widen formats to string[] so indexOf(x) works
  (solarVersions as readonly string[]).indexOf(x) >= 0
);

export type YearOrSlug = string;
export type GWh = number;
export type GW = number;
export type Hours = number;
export type Factor = number;
