import { BaseUnits, UnitScopes } from '../../domain_model/math/constants';
import { ActionTypes } from '../types';
import { CHANGE_BASE_UNIT, CHANGE_UNIT_SCOPE, IUnitState } from '../types/miscTypes';
import { fireAnalyticsEvent } from '../../analytics/analytics';
import { E } from '../../analytics/types';

const initialState: IUnitState = {
  baseUnit: BaseUnits.WATT_HOUR,
  unitScope: UnitScopes.SWITZERLAND,
};

export default unit;

function unit(state = initialState, action: ActionTypes): IUnitState {
  switch (action.type) {
    case CHANGE_BASE_UNIT: {
      return {
        ...state,
        baseUnit: action.payload,
      };
    }
    case CHANGE_UNIT_SCOPE: {
      fireAnalyticsEvent(
        action.payload === UnitScopes.PERSON ? E.scopePerson : E.scopeSwitzerland,
      );
      return {
        ...state,
        unitScope: action.payload,
      };
    }
    default:
      return state;
  }
}
