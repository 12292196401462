import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import { connect, ConnectedProps } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { WHITE_BUTTON_THEME } from '../../domain_model/Themes';
import {
  mapHighlightSaveButtonToProps,
  mapParamsOpenToProps,
  mapParamsToProps,
} from '../../redux/mappers';
import ScenarioNameInput from './ScenarioNameInput';
import Scenario from '../../domain_model/scenario/Scenario';
import {
  addScenario,
  changeHighlightState,
  deleteComputedData,
  updateScenario,
} from '../../redux/actions';
import { NonLocalizedString } from '../../domain_model/scenario/NonLocalizedString';
import { IReduxState } from '../../redux/types';
import { sleep } from '../../helper';

type Props = PropsFromRedux;

const useStyles = makeStyles(() => (
  createStyles({
    dialogContent: {
      width: '30em',
    },
    inputContainer: {
      marginBottom: '1em',
    },
    highlightedButton: {
      animation: '$pulse 2s infinite',
    },
    '@keyframes pulse': {
      '50%': {
        backgroundColor: 'rgb(255,170,0)',
      },
    },
    tooltip: {
      maxWidth: 155,
    },
  })
));

enum ValidationState {
  NEW,
  OVERWRITING_EXISTING_ENTRY,
  ILLEGAL_IMMUTABLE,
  ILLEGAL_EMPTY,
}

const SaveScenarioButton = (props: Props) => {
  const classes = useStyles();
  const {
    activeParams,
    scenarios,
    addScenario: _addScenario,
    updateScenario: _updateScenario,
    deleteComputedData: _deleteComputedData,
    highlightSaveButton,
    changeHighlightState: _changeHighlightState,
    paramsOpen,
  } = props;
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [input, setInput] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // opens tooltip when the button is highlighted
  useEffect(() => {
    if (highlightSaveButton) {
      if (paramsOpen) {
        // sleep is necessary for the tooltip do render correctly when params are reopened
        sleep(500).then(() => {
          setTooltipOpen(true);
        });
      } else {
        setTooltipOpen(false);
      }
    }
  }, [highlightSaveButton, paramsOpen, setTooltipOpen]);

  const getScenarioIndex = (newInput: string) => (
    scenarios.map((s) => (s.name.getString(t).toLowerCase()))
      .indexOf(newInput.toLowerCase())
  );

  function validate() {
    if (input === '') {
      return ValidationState.ILLEGAL_EMPTY;
    }
    const indexInScenarioList = getScenarioIndex(input);
    if (indexInScenarioList < 0) {
      return ValidationState.NEW;
    }
    if (scenarios[indexInScenarioList].mutable) {
      return ValidationState.OVERWRITING_EXISTING_ENTRY;
    }
    return ValidationState.ILLEGAL_IMMUTABLE;
  }

  function handleSave() {
    const newScenario = new Scenario(
      new NonLocalizedString(input),
      new NonLocalizedString(''),
      activeParams,
      true,
    );
    const validationState = validate();
    if (validationState === ValidationState.NEW) {
      _addScenario(newScenario, true);
    } else if (validationState === ValidationState.OVERWRITING_EXISTING_ENTRY) {
      const scenarioIndex = getScenarioIndex(input);
      _deleteComputedData(scenarios[scenarioIndex].id);
      _updateScenario(scenarioIndex, newScenario, true);
    }
    setDialogOpen(false);
    setInput('');
  }

  function handleClose() {
    setDialogOpen(false);
    setInput('');
  }

  function handleOpen() {
    setDialogOpen(true);
  }

  const scenarioInfoList = scenarios.map(
    (scenario: Scenario) => ({
      mutable: scenario.mutable,
      label: scenario.name.getString(t),
    }),
  );

  function renderNotificationText() {
    switch (validate()) {
      case ValidationState.OVERWRITING_EXISTING_ENTRY:
        return <DialogContentText>{t('existingScenarioOverride')}</DialogContentText>;
      case ValidationState.ILLEGAL_IMMUTABLE:
        return <DialogContentText>{t('scenarioIsImmutable')}</DialogContentText>;
      default:
        return null;
    }
  }

  function isSaveButtonDisabled() {
    switch (validate()) {
      case ValidationState.NEW:
      case ValidationState.OVERWRITING_EXISTING_ENTRY:
        return false;
      default:
        return true;
    }
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          {t('saveScenarioDialogTitle')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.inputContainer}>
            <ScenarioNameInput
              input={input}
              onNewInput={setInput}
              scenarioNameList={scenarioInfoList}
            />
          </div>
          {renderNotificationText()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" disabled={isSaveButtonDisabled()}>
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={WHITE_BUTTON_THEME}>
        <Tooltip
          title={t('saveTooltip')}
          arrow
          classes={{ tooltip: classes.tooltip }}
          open={tooltipOpen}
          onClose={() => {
            setTooltipOpen(false);
            _changeHighlightState({ highlightSaveButton: false });
          }}
          onOpen={() => setTooltipOpen(true)}
        >
          <IconButton
            onClick={handleOpen}
            aria-label="Save"
            href=""
            className={highlightSaveButton ? classes.highlightedButton : ''}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </ThemeProvider>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapHighlightSaveButtonToProps(state),
  ...mapParamsToProps(state),
  ...mapParamsOpenToProps(state),
});
const connector = connect(mapToProps, {
  addScenario,
  updateScenario,
  deleteComputedData,
  changeHighlightState,
});

export default connector(SaveScenarioButton);
