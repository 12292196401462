import { ILocalizableString } from './ILocalizableString';

export class LocalizedString implements ILocalizableString {
  private readonly stringIdentifier: string;

  public constructor(stringIdentifier: string) {
    this.stringIdentifier = stringIdentifier;
  }

  getString(t: (str: string) => string): string {
    return t(this.stringIdentifier);
  }
}
