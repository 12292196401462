import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GraphResolution } from '../../redux/types/uiTypes';
import { changeGraphResolution } from '../../redux/actions';
import { mapGraphResolutionToProps } from '../../redux/mappers';
import BarChart1Icon from '../../icons/BarChart1';
import BarChart12Icon from '../../icons/BarChart12';
import BarChart365Icon from '../../icons/BarChart365';
import LineChartIcon from '../../icons/LineChart';

type Props = PropsFromRedux & {
  disableOneBar?: boolean;
};

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
  },
}));

const ChangeGraphResolutionButtons = (props: Props) => {
  const { changeGraphResolution: change, graphResolution, disableOneBar } = props;
  const classes = useStyles();

  const handleClick = (resoluton: GraphResolution) => {
    change(resoluton);
  };

  const btns = [];

  if (!disableOneBar) {
    btns.push(
      (
        <IconButton
          disabled={graphResolution === 'year'}
          onClick={() => handleClick('year')}
          aria-label="show yearly average"
          key="show yearly average"
          href=""
        >
          <SvgIcon>
            <BarChart1Icon />
          </SvgIcon>
        </IconButton>
      ),
    );
  }
  btns.push(
    (
      <IconButton
        disabled={graphResolution === 'month' || (disableOneBar && graphResolution === 'year')}
        onClick={() => handleClick('month')}
        aria-label="show monthly average"
        key="show monthly average"
        href=""
      >
        <SvgIcon>
          <BarChart12Icon />
        </SvgIcon>
      </IconButton>
    ),
    (
      <IconButton
        disabled={graphResolution === 'day'}
        onClick={() => handleClick('day')}
        aria-label="show daily average"
        key="show daily average"
        href=""
      >
        <SvgIcon>
          <BarChart365Icon />
        </SvgIcon>
      </IconButton>
    ),
    (
      <IconButton
        disabled={graphResolution === 'continuous'}
        onClick={() => handleClick('continuous')}
        aria-label="show continous graph"
        key="show continous graph"
        href=""
      >
        <SvgIcon>
          <LineChartIcon />
        </SvgIcon>
      </IconButton>
    ),
  );

  return (
    <div className={classes.root}>
      {btns}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapGraphResolutionToProps, { changeGraphResolution });

export default connector(ChangeGraphResolutionButtons);
