import { Potency } from './types';

const SWISS_POPULATION = 8.42e6;

export const POTENCIES = {
  MILLI: {
    name: 'm',
    hierarchy: -1,
    arrayIndex: 0,
  },
  NONE: {
    name: '',
    hierarchy: 0,
    arrayIndex: 1,
  },
  KILO: {
    name: 'K',
    hierarchy: 1,
    arrayIndex: 2,
  },
  MEGA: {
    name: 'M',
    hierarchy: 2,
    arrayIndex: 3,
  },
  GIGA: {
    name: 'G',
    hierarchy: 3,
    arrayIndex: 4,
  },
  TERRA: {
    name: 'T',
    hierarchy: 4,
    arrayIndex: 5,
  },
};

const generatePotArray = (): Potency[] => (
  Object.values(POTENCIES).reduce((partial: Potency[], val) => {
    // eslint-disable-next-line no-param-reassign
    partial[val.arrayIndex] = val;
    return partial;
  }, [])
);

export const POTENCIES_ARRAY = generatePotArray();

export enum UnitScopes {
  SWITZERLAND = SWISS_POPULATION,
  PERSON = 1,
}

export enum BaseUnits {
  WATT_HOUR = 1,
  JOULE = 3600,
}
