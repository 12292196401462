import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import clsx from 'clsx';
import ComparisonTable from './ComparisionTable';
import ComplexChart from '../main-section/complex-summary-chart/Chart';
import { ComputedDataSets } from '../../domain_model/ComputedDataSets';
import { IComputedData } from '../../domain_model/ComputedDataCollection';
import Scenario from '../../domain_model/scenario/Scenario';
import { mapGraphStyleToProps } from '../../redux/mappers';
import CompareChart from '../main-section/simple-summary-chart/CompareChart';
import ChangeGraphStyleButton from '../shared/ChangeGraphStyleButton';

type Props = PropsFromRedux & {
  computedDataSets: ComputedDataSets;
  compareScenarioA: number | null;
  compareScenarioB: number | null;
  activeComputedData: IComputedData;
  scenarios: Scenario[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    display: 'grid',
    maxWidth: '100em',
    gridTemplateColumns: '33em 1fr',
    gridTemplateAreas: '"table diagram"',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `"diagram"
                          "table  "`,
    },
    justifyItems: 'center',
    gridGap: theme.spacing(4),
  },
  changeStyleButtonOuterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '0px',
  },
  changeStyleButtonInnerContainer: {
    margin: theme.spacing(1),
  },
  diagramSectionContainer: {
    width: '100%',
    gridArea: 'diagram',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  diagramSection: {
    backgroundColor: 'white',
    padding: '2em',
    paddingBottom: 0,
  },
  tableContainer: {
    maxWidth: '50em',
    gridArea: 'table',
    flexShrink: 0,
    backgroundColor: 'white',
    padding: '1.5em',
  },
  diagram: {
    paddingBottom: theme.spacing(4),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    fontWeight: 500,
  },
  divider: {
    gridArea: 'divider',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

function ComparisonLayout(props: Props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    computedDataSets,
    compareScenarioA,
    compareScenarioB,
    activeComputedData,
    scenarios,
    graphStyle,
  } = props;

  const scenarioA: undefined | IComputedData = compareScenarioA === null
    ? activeComputedData
    : computedDataSets[scenarios[compareScenarioA].id];

  const scenarioB: undefined | IComputedData = compareScenarioB === null
    ? activeComputedData
    : computedDataSets[scenarios[compareScenarioB].id];

  const maxOfAOrB = Math.max(
    scenarioA ? scenarioA.computedSummary.totalEnergyProductionPlusImportAndShortage : 0,
    scenarioB ? scenarioB.computedSummary.totalEnergyProductionPlusImportAndShortage : 0,
  );

  const getComplexDiagrams = () => (
    <>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {scenarioA && scenarioA.scenarioInfo.scenarioName.getString(t)}
      </Typography>
      <ComplexChart
        computedData={scenarioA}
        height={18}
        className={classes.diagram}
        heightRelativeTo={maxOfAOrB}
        omitLegend
      />
      <Typography variant="subtitle1" className={classes.subtitle}>
        {scenarioB && scenarioB.scenarioInfo.scenarioName.getString(t)}
      </Typography>
      <ComplexChart
        computedData={scenarioB}
        height={18}
        className={classes.diagram}
        heightRelativeTo={maxOfAOrB}
        completeLegend
      />
    </>
  );

  const getSimpleDiagrams = () => (
    <>
      <CompareChart
        height={45}
        className={clsx(classes.diagram, classes.marginTop)}
        computedA={scenarioA}
        computedB={scenarioB}
      />
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <ComparisonTable scenarioA={scenarioA} scenarioB={scenarioB} />
      </div>
      <div className={classes.diagramSectionContainer}>
        <div className={classes.changeStyleButtonOuterContainer}>
          <div className={classes.changeStyleButtonInnerContainer}>
            <ChangeGraphStyleButton />
          </div>
        </div>
        <div className={classes.diagramSection}>
          {graphStyle === 'table' ? (
            getComplexDiagrams()
          ) : (
            getSimpleDiagrams()
          )}
        </div>
      </div>
    </div>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapGraphStyleToProps);

export default connector(ComparisonLayout);
