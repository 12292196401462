import React, { FunctionComponent } from 'react';
import {
  createStyles, IconButton, makeStyles, Theme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getDokString } from '../../helper';

type AppLanguage = 'de' | 'en';

type DocumentationPage
  = 'quick-start.html'
  | 'user-manual.html'
  | 'intro.html'
  | 'calculation-methodology.html'
  | 'producers-consumers.html'
  | 'storage.html'
  | 'conclusions.html'
  | 'future.html'
  | 'sources.html';

export type ClickableInfoButtonProps = {
  dokuPage: DocumentationPage;
  paragraph?: { [key in AppLanguage]: string | null };
  className?: string;
}

type Props = ClickableInfoButtonProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    color: theme.palette.grey[500],
  },
}));

function createUrl(
  i18n: i18next.i18n,
  dokuPage: DocumentationPage,
  paragraph?: { [key in AppLanguage]: string | null },
) {
  return `${getDokString(i18n)}${dokuPage}${
    paragraph ? `#${paragraph[i18n.languages[0] as AppLanguage]}` : ''
  }`;
}

const ClickableInfoButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const { i18n } = useTranslation();
  const {
    dokuPage, paragraph, className,
  } = props;

  return (
    <IconButton
      className={clsx(classes.icon, className || '')}
      onClick={() => window.open(createUrl(i18n, dokuPage, paragraph), '_blank')}
    >
      <InfoIcon />
    </IconButton>
  );
};


export default ClickableInfoButton;
