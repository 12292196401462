import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DEFINED_SCREEN_SIZES } from '../domain_model/GlobalStyleConstants';
import { changeSize } from '../redux/actions';
import { mapSizeToProps } from '../redux/mappers';

type Props = PropsFromRedux

class ScreenSizeUpdater extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.resize = this.resize.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  private resize() {
    const { currentSize: oldSize } = this.props;
    const { innerWidth: windowWidth } = window;
    const newSize = DEFINED_SCREEN_SIZES.getSizeByWidth(windowWidth);
    if (!newSize.isEqualTo(oldSize)) {
      const { changeSize: _changeSize } = this.props;
      _changeSize(newSize);
    }
  }

  public render() {
    return null;
  }
}

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapSizeToProps, { changeSize });

export default connector(ScreenSizeUpdater);
