import { ActionTypes } from '../types';
import SourceDataCollection, { initBySection } from '../../domain_model/SourceDataCollection';
import {
  ADD_DATA_SERIES,
  ADD_PRELOAD_DATA,
  ADD_PRELOAD_SECONDARY_DATA,
  IDataState,
  SET_DATA_SERIES_FETCHING_FLAG,
} from '../types/dataTypes';

const initialState: IDataState = {
  data: new SourceDataCollection(),
  preloadData: {
    availableYearsInApi: [],
    lowResSectionDataCollection: initBySection({}),
    maxSectionDataCollection: initBySection({}),
    sumSectionDataCollection: initBySection({}),
    arbitraryWithoutData: [],
  },
  preloadSecondaryData: {
    thetaA: [],
    thetaE: [],
    thetaZ: [],
  },
};

export default data;

function data(state = initialState, action: ActionTypes): IDataState {
  switch (action.type) {
    case ADD_DATA_SERIES: {
      const { dataSection, yearOrSlug, dataSeries } = action.payload;
      return {
        ...state,
        data: state.data.copy().addDataSeries(dataSection, yearOrSlug, dataSeries),
      };
    }
    case SET_DATA_SERIES_FETCHING_FLAG: {
      const { dataSection, yearOrSlug } = action.payload;
      return {
        ...state,
        data: state.data.copy().setFetchingFlag(dataSection, yearOrSlug),
      };
    }
    case ADD_PRELOAD_DATA: {
      return {
        ...state,
        preloadData: action.payload,
      };
    }
    case ADD_PRELOAD_SECONDARY_DATA: {
      return {
        ...state,
        preloadSecondaryData: action.payload,
      };
    }
    default:
      return state;
  }
}
