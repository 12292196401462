import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { mapParamsToProps } from '../redux/mappers';
import { changeHighlightState } from '../redux/actions';

type Props = PropsFromRedux;

enum Status {
  notReady,
  ready,
  fired,
}

const HighlightController = (props: Props) => {
  const { changeHighlightState: _changeHighlightState, activeScenario, scenarios } = props;
  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.notReady);

  // Save button highlighting
  // Fires once the user changes a parameter
  useEffect(() => {
    switch (saveButtonStatus) {
      case Status.notReady:
        if (activeScenario !== null) {
          setSaveButtonStatus(Status.ready);
        }
        break;
      case Status.ready:
        if (activeScenario === null) {
          _changeHighlightState({ highlightSaveButton: true });
          setSaveButtonStatus(Status.fired);
        }
        break;
      default:
    }
  }, [scenarios, activeScenario, _changeHighlightState, setSaveButtonStatus, saveButtonStatus]);

  return null;
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeHighlightState });

export default connector(HighlightController);
