/* eslint-disable */
// Add plausible analytics script on production
import preval from 'preval.macro';

export default function () {
  // Load Plausible script (Tracks page views)

  if (preval`module.exports = process.env.CONTEXT === 'production';`) {
    // <script async defer data-domain="powercheck.ch" src="https://plausible.io/js/plausible.js"></script>
    // Custom domain version:
    // <script async defer data-domain="powercheck.ch" src="https://plausible.powercheck.ch/js/index.js"></script>
    const analyticsScriptTag = document.createElement('script');
    analyticsScriptTag.async = true;
    analyticsScriptTag.defer = true;
    analyticsScriptTag['data-domain'] = 'powercheck.ch';
    analyticsScriptTag.src = 'https://plausible.powercheck.ch/js/index.js';
    document.getElementsByTagName('head')[0].appendChild(analyticsScriptTag);


    // Plausible Events Setup (To track button clicks & stuff)

    // <script>
    //   window.plausible = window.plausible
    //     || function() {
    //       (window.plausible.q = window.plausible.q || []).push(arguments)
    //     }
    // </script>
    // eslint-disable-next-line func-names
    window.plausible = window.plausible || function (...args) {
      (window.plausible.q = window.plausible.q || []).push(args);
    };
  }
}
