import React from "react";

const SvgLineChart = props => (
  <svg viewBox="0 0 23 23" {...props}>
    <path d="M-.5-1.5h24v24h-24v-24z" fill="none" />
    <path d="M3.5 15.5l-1.7-1.8 8-7.8 3.7 3.7 5.9-6.5 1.9 1.7-7.7 8.4-3.8-3.8z" />
    <path d="M20.2 8.5L13.4 16l-3.7-3.7-7.1 6.9v.1h17.6z" />
  </svg>
);

export default SvgLineChart;
