import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_POWER_UNIT } from '../../../../domain_model/math/PowerUnit';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import YearAndScaling from '../input-combined/YearAndScaling';
import { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const GRAPH_LIMIT = new Quantity(10, DB_POWER_UNIT, false);

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr',
  },
  subSection: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING / 2),
    gridTemplateColumns: 'auto',
  },
  azimuth: {
    maxHeight: '10em',
    height: '10em',
  },
  tilt: {
    maxHeight: '10em',
    height: '10em',
  },
}));

const SolarParams = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const infoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'solarenergie',
      // TODO: add en doku paragraph
      en: 'solarenergie',
    },
  };

  return (
    <div className={classes.root}>
      <YearAndScaling
        section="solar"
        scalingLabel={t('paramsMaxPower')}
        graphLimit={GRAPH_LIMIT}
        originalUnit={DB_POWER_UNIT}
        infoButtonProps={infoButtonProps}
        yearTooltip={t('infoSolarYear')}
        scalingTootltip={t('infoSolarScaling')}
      />
    </div>
  );
};

export default SolarParams;
