import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  addPreloadData,
  addPreloadSecondaryData,
  addScenario,
  registerConnectionErrorLoop,
  resolveConnectionErrorLoop,
  setActiveScenario,
} from '../redux/actions';
import { IPreloadDataDto, IPreloadSecondaryDataDto } from '../domain_model/SourceDataCollection';
import { fetchPreloadData, fetchPreloadSecondaryData } from '../data/fetchData';
import getPredefinedScenarios from '../predefinedScenarios';
import calculateThetaZ from '../business-logic/calculateThetaZ';
import { startFetchLoop } from './helper';

type Props = PropsFromRedux

const DataInitialization = (props: Props) => {
  const {
    addPreloadData: _addPreloadData,
    addPreloadSecondaryData: _addPreloadSecondaryData,
    addScenario: _addScenario,
    setActiveScenario: _setActiveScenario,
    registerConnectionErrorLoop: _registerConnectionErrorLoop,
    resolveConnectionErrorLoop: _resolveConnectionErrorLoop,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const onReceivePreloadData = (preloadDataDto: IPreloadDataDto) => {
    _addPreloadData({
      ...preloadDataDto.data,
      availableYearsInApi: preloadDataDto.data.availableYearsInApi.map((x) => x.toString()),
    });
    getPredefinedScenarios(preloadDataDto).forEach((scenario) => {
      _addScenario(scenario);
    });
    _setActiveScenario(0);
  };

  const onReceivePreloadSecondaryData = (preloadDataDto: IPreloadSecondaryDataDto) => {
    _addPreloadSecondaryData({
      thetaA: preloadDataDto.data.thetaA,
      thetaE: preloadDataDto.data.thetaE,
      thetaZ: calculateThetaZ(preloadDataDto.data.thetaE),
    });
  };

  const fetchAllData = () => {
    startFetchLoop(
      fetchPreloadData,
      onReceivePreloadData,
      (err) => {
        enqueueSnackbar(
          `Initial data could not be loaded: ${err}`,
          { variant: 'error', preventDuplicate: true, autoHideDuration: 1000 * 30 },
        );
      },
      _registerConnectionErrorLoop,
      _resolveConnectionErrorLoop,
      20,
    ).then();
    startFetchLoop(
      fetchPreloadSecondaryData,
      onReceivePreloadSecondaryData,
      (err) => {
        enqueueSnackbar(
          `Secondary data could not be loaded: ${err}`,
          { variant: 'error', preventDuplicate: true, autoHideDuration: 1000 * 30 },
        );
      },
      _registerConnectionErrorLoop,
      _resolveConnectionErrorLoop,
      20,
    ).then();
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return null;
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(null, {
  addPreloadData,
  addPreloadSecondaryData,
  addScenario,
  setActiveScenario,
  registerConnectionErrorLoop,
  resolveConnectionErrorLoop,
});

export default connector(DataInitialization);
