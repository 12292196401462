import React, { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { changeParams } from '../../../../redux/actions';
import CurveDiagram from '../CurveDiagram';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import NumberInput from '../input-single/inputs/NumberInput';
import { DataSeriesSection } from '../../../../domain_model/SourceDataCollection';
import Quantity from '../../../../domain_model/math/Quantity';
import { INumberConverterTwoWay, Unit } from '../../../../domain_model/math/Unit';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import { calculateStep } from '../helper';
import { toEnergy } from '../../../../domain_model/math/helpers';
import { IReduxState } from '../../../../redux/types';
import { assertUnreachable } from '../../../../helper';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';
import YearOrSlugSelect from '../input-single/selects/YearOrSlugSelect';

const MAX_ALLOWED_DIGITS = 4;

type Section = 'solar' | Exclude<DataSeriesSection, 'dam' | 'solarDirectA' | 'solarDiffuseA'
| 'solarDirectB' | 'solarDiffuseB' | 'solarDirectC' | 'solarDiffuseC'>;

type OuterProps = {
  section: Section;
  scalingLabel: string;
  graphLimit: Quantity;
  originalUnit: Unit;
  infoButtonProps?: ClickableInfoButtonProps;
  scalingTootltip?: string;
  yearTooltip?: string;
}

type Props = PropsFromRedux & OuterProps

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr 1.3fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"innerGrid innerGrid info "
                        "innerGrid innerGrid input"`,
  },
  innerGrid: {
    gridArea: 'innerGrid',
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto 10em',
    gridTemplateAreas: `"year  .     "
                        "graph slider"`,
  },
  input: {
    width: '100%',
    gridArea: 'input',
    alignSelf: 'end',
  },
  slider: {
    gridArea: 'slider',
  },
  graph: {
    gridArea: 'graph',
    position: 'relative',
  },
  infoButton: {
    gridArea: 'info',
    placeSelf: 'start end',
    marginTop: '0.45em',
  },
  clickableInfoButton: {
    gridArea: 'info',
    placeSelf: 'start end',
    marginTop: '0.15em',
  },
}));

const YearAndScalingComponent = React.memo((props: Props) => {
  const classes = useStyles();
  const {
    section,
    scalingLabel,
    graphLimit,
    originalUnit,
    maxSectionDataCollection,
    sumSectionDataCollection,
    scaling: dbValue,
    yearOrSlug,
    changeParams: _changeParams,
    infoButtonProps,
    scalingTootltip,
    yearTooltip,
  } = props;

  const { unit: displayUnit, val: displayMaxValue } = graphLimit;
  const { name: displayUnitName } = displayUnit;

  const step = useMemo(() => (
    calculateStep(MAX_ALLOWED_DIGITS, displayMaxValue)
  ), [displayMaxValue]);
  const stepSlider = useMemo(() => (
    (new Decimal(step)).times(10).toNumber()
  ), [step]);

  const {
    toThis: convertToDisplay, toOther: convertToDb,
  } = useMemo(() => (
    displayUnit.getConvertersTwoWay(originalUnit) as INumberConverterTwoWay
  ), [displayUnit, originalUnit]);

  const displayValue = useMemo(() => (
    convertToDisplay(dbValue)
  ), [convertToDisplay, dbValue]);

  const dbMaxValue = useMemo(() => (
    convertToDb(displayMaxValue)
  ), [convertToDb, displayMaxValue]);

  const setScaling = (newValue: number) => {
    _changeParams({
      [section]: {
        scaling: convertToDb(newValue),
      },
    });
  };

  const setYearOrSlug = (newValue: string) => {
    let scaling;
    switch (section) {
      case 'endUser': // Intellisense Bug: Branch IS reachable
        scaling = toEnergy(sumSectionDataCollection.endUser[newValue] || 0);
        break;
      case 'import': // Intellisense Bug: Branch IS reachable
        scaling = toEnergy(sumSectionDataCollection.import[newValue] || 0);
        break;
      case 'export': // Intellisense Bug: Branch IS reachable
        scaling = toEnergy(sumSectionDataCollection.export[newValue] || 0);
        break;
      case 'solar':
      case 'wind': // Intellisense Bug: Branch IS reachable
        // Dont change the scaling value
        scaling = dbValue;
        break;
      case 'nuclear':
      case 'thermal':
      case 'river':
        scaling = maxSectionDataCollection[section][newValue];
        break;
      default:
        assertUnreachable(section);
    }
    _changeParams({
      [section]: {
        yearOrSlug: newValue,
        scaling,
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerGrid}>

        <YearOrSlugSelect
          section={section}
          selected={yearOrSlug}
          onNewSelected={setYearOrSlug}
          tooltip={yearTooltip}
        />

        <Tooltip
          placement="bottom"
          arrow
          enterDelay={1000}
          open={scalingTootltip === undefined ? false : undefined}
          title={scalingTootltip}
        >
          <SimpleSlider
            className={classes.slider}
            value={displayValue}
            onNewValue={setScaling}
            max={displayMaxValue}
            step={stepSlider}
            vertical
          />
        </Tooltip>

        <CurveDiagram
          className={classes.graph}
          scaling={dbValue}
          yearOrSlug={yearOrSlug}
          max={dbMaxValue}
          section={section}
        />

      </div>
      {infoButtonProps && (
        <ClickableInfoButton
          {...infoButtonProps}
          className={classes.clickableInfoButton}
        />
      )}
      <Tooltip
        className={classes.input}
        placement="bottom"
        arrow
        enterDelay={1000}
        open={scalingTootltip === undefined ? false : undefined}
        title={scalingTootltip}
      >
        <NumberInput
          value={displayValue}
          onNewValue={setScaling}
          unit={displayUnitName}
          label={scalingLabel}
          step={step}
        />
      </Tooltip>
    </div>
  );
});

// TODO: IMPORTANT!
// TODO: Use createSelector and create a factory, see "Sharing Selectors Across Multiple Components"
// TODO: on https://redux.js.org/recipes/computing-derived-data.
// TODO: If u don't do it, the component will render unnecessary
const select = (state: IReduxState, props: OuterProps) => ({
  scaling: state.parameter.activeParams[props.section].scaling,
  yearOrSlug: state.parameter.activeParams[props.section].yearOrSlug,
  maxSectionDataCollection: state.data.preloadData.maxSectionDataCollection,
  sumSectionDataCollection: state.data.preloadData.sumSectionDataCollection,
});


type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(select, { changeParams });

export default connector(YearAndScalingComponent);
