import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Tooltip, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_POWER_UNIT } from '../../../../domain_model/math/PowerUnit';
import CapacityInitialAndMax from '../input-combined/CapacityInitialAndMax';
import NumberInput from '../input-single/inputs/NumberInput';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import { INumberConverterTwoWay } from '../../../../domain_model/math/Unit';
import { calculateSliderStep, calculateStep } from '../helper';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';
import YearOrSlugSelect from '../input-single/selects/YearOrSlugSelect';

const MAX_ALLOWED_DIGITS = 4;
const MAX_POWER = 16;

const useStyles = makeStyles((theme: Theme) => createStyles({
  firstContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  infoButton: {},
  powerContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"year outputPowerInput "
                        ".    outputPowerSlider"`,
  },
  outputPowerInput: {
    gridArea: 'outputPowerInput',
  },
  outputPowerSlider: {
    gridArea: 'outputPowerSlider',
  },
}));

type Props = PropsFromRedux;

const DamParams = (props: Props) => {
  const {
    changeParams: _changeParams, activeParams: {
      waterStorage: {
        dam: {
          yearOrSlug, outputPower: outputPowerDbValue,
        },
      },
    },
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const max = new Quantity(MAX_POWER, DB_POWER_UNIT, false);
  const { unit: displayUnit, val: maxValue } = max;
  const { name: displayUnitName } = displayUnit;
  const {
    toThis: convertToDisplay, toOther: convertToDb,
  } = displayUnit.getConvertersTwoWay(DB_POWER_UNIT) as INumberConverterTwoWay;

  const step = calculateStep(MAX_ALLOWED_DIGITS, maxValue);
  const stepSlider = calculateSliderStep(maxValue);


  const outputPowerDisplayValue = convertToDisplay(outputPowerDbValue);

  const setYearOrSlug = (newValue: string) => {
    _changeParams({
      waterStorage: {
        dam: {
          yearOrSlug: newValue,
        },
      },
    });
  };

  const setValue = (newValue: number) => {
    _changeParams({
      waterStorage: {
        dam: {
          outputPower: convertToDb(newValue),
        },
      },
    });
  };


  const infoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'kernkraftwerke',
      // TODO: add en doku paragraph
      en: 'kernkraftwerke',
    },
  };

  return (
    <>
      <div className={classes.firstContainer}>
        <Typography className={classes.title} variant="overline">
          {t('InflowAndPowerTitle')}
        </Typography>
        <ClickableInfoButton
          {...infoButtonProps}
          className={classes.infoButton}
        />
      </div>
      <div className={classes.powerContainer}>
        <YearOrSlugSelect
          section="dam"
          label={t('damInfluxYear')}
          selected={yearOrSlug}
          onNewSelected={setYearOrSlug}
          tooltip={t('infoDamYear')}
        />
        <Tooltip
          placement="right"
          arrow
          enterDelay={1000}
          title={t('infoDamPower')}
        >
          <NumberInput
            className={classes.outputPowerInput}
            label={t('outputPower')}
            value={outputPowerDisplayValue}
            onNewValue={(val) => setValue(val)}
            step={step}
            unit={displayUnitName}
          />
        </Tooltip>
        <Tooltip
          placement="right"
          arrow
          enterDelay={1000}
          title={t('infoDamPower')}
        >
          <SimpleSlider
            className={classes.outputPowerSlider}
            value={outputPowerDisplayValue}
            onNewValue={(val) => setValue(val)}
            max={maxValue}
            step={stepSlider}
          />
        </Tooltip>
      </div>
      <CapacityInitialAndMax section="dam" maxEnergy={10000} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(DamParams);
