// This File should be kept in sync with the equivalent one in the projects:
// powercheck-admin src/sharedTypes.ts
// powercheck-backend: src/data-model/sharedTypes.ts
// powercheck-frontend: src/dara/sharedTypes.ts

// eslint-disable-next-line no-shadow
export enum ArbitrarySection {
  endUser = 'endUser',
  import = 'import',
  export = 'export',
  nuclear = 'nuclear',
  thermal = 'thermal',
  river = 'river',
  damInflux = 'damInflux',
  wind = 'wind',
}

export type MeasuredSeriesWithoutData = {
  year: number;
}

export type ArbitrarySeriesWithoutData = {
  slug: string;
  order: number;
}

export type ArbitrarySeries = ArbitrarySeriesWithoutData & {
  dataSeries: number[];
}

export type SectionWithoutData = {
  name: ArbitrarySection;
  measuredSeries: MeasuredSeriesWithoutData[];
  arbitrarySeries: ArbitrarySeriesWithoutData[];
}

export function isMeasuredSeries(
  series: MeasuredSeriesWithoutData | ArbitrarySeriesWithoutData,
): series is MeasuredSeriesWithoutData {
  return (series as MeasuredSeriesWithoutData).year !== undefined;
}

export const apiStateLiterals = [
  'OK', 'CALCULATING', 'OK_CALCULATION_NEEDED', 'ERROR',
] as const;
export type ApiState = typeof apiStateLiterals[number];

export type AdminStuffDto = {
  sections: SectionWithoutData[];
  apiState: ApiState;
}

export type ArbitrarySeriesDto = ArbitrarySeries & {
  section: ArbitrarySection;
}

export type DeleteArbitrarySeriesDto = {
  section: ArbitrarySection;
  slug: string;
}

export function isDeleteArbitrarySeriesDto(
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  dto: any,
): dto is DeleteArbitrarySeriesDto {
  return (
    Object.values(ArbitrarySection).includes(dto.section)
    && typeof dto.slug === 'string'
  );
}

export function isArbitrarySeriesDto(
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  dto: any,
): dto is ArbitrarySeriesDto {
  return (
    Object.values(ArbitrarySection).includes(dto.section)
    && typeof dto.slug === 'string'
    && typeof dto.order === 'number'
    && Array.isArray(dto.dataSeries)
  );
}

export function isValidSlug(slug: string): boolean {
  const regex = RegExp('^[a-z][a-zA-Z0-9_]*$');
  return regex.test(slug);
}
