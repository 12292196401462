import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import SimpleSlider from '../../input-single/sliders/SimpleSlider';

const useStyles = makeStyles<Theme, Props>(() => createStyles({
  root: {
    width: (props) => `${props.sliderWidth}px`,
    display: 'grid',
    gridTemplateRows: '1fr auto',
    textAlign: 'center',
  },
}));

type Props = {
  val: number;
  setVal: (val: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  sliderWidth: number;
}

const EqSlider = (props: Props): JSX.Element => {
  const classes = useStyles(props);
  const { val, setVal } = props;

  return (
    <div className={classes.root}>
      <SimpleSlider
        value={val}
        onNewValue={setVal}
        max={100}
        step={1}
        vertical
      />
    </div>
  );
};

export default EqSlider;
