import React from 'react';
import { useTranslation } from 'react-i18next';
import SummaryChart from './Chart';
import { IComputedData } from '../../../domain_model/ComputedDataCollection';
import { getChartData } from './helper';

type Props = {
  computedA: IComputedData | undefined;
  computedB: IComputedData | undefined;
  height: number;
  className?: string;
};

const CompareChart = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    computedA,
    computedB,
    className,
    height,
  } = props;

  return (
    <SummaryChart
      scenarioAData={computedA && {
        name: computedA.scenarioInfo.scenarioName.getString(t),
        data: getChartData(computedA.computedSummary),
      }}
      scenarioBData={computedB && {
        name: computedB.scenarioInfo.scenarioName.getString(t),
        data: getChartData(computedB.computedSummary),
      }}
      height={height}
      className={className}
    />
  );
};

export default (CompareChart);
