import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTranslation } from 'react-i18next';
import ComparisonLayout from './ComparisonLayout';
import { mapCompareStateToProps, mapComputedToProps, mapParamsToProps } from '../../redux/mappers';
import {
  changeCalculationState,
  changeCompareOpen,
  setCompareScenarios,
} from '../../redux/actions';
import ScenarioSelect, { SelectEntry } from '../shared/ScenarioSelect';
import CalculationState from '../../domain_model/CalculationState';
import { IReduxState } from '../../redux/types';

type Props = PropsFromRedux;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: 'unset',
    width: '104em',
  },
  titleContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  scenarioInput: {
    width: '16em',
    marginTop: '0.1em',
    marginLeft: '1em',
    marginRight: '1em',
  },
  select: {
    fontWeight: 'normal',
    // fontSize: '1.25rem !important',
  },
  dialogContent: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

type KeyType = number | 'activeNotSavedScenario';

const getActiveKey = (activeScenario: number | null) => (
  activeScenario !== null ? activeScenario : 'activeNotSavedScenario'
);

const ComparisonDialog = (props: Props) => {
  const {
    scenarios,
    activeComputedData,
    isComparisonActive,
    compareScenarioA,
    compareScenarioB,
    computedDataSets,
    changeCompareOpen: _changeCompareOpen,
    setCompareScenarios: _setCompareScenarios,
    changeCalculationState: _changeCalculationState,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const classes = useStyles(props);

  const scenarioEntries: SelectEntry<KeyType>[] = scenarios.map((scenario, i) => ({
    label: scenario.name.getString(t),
    key: i,
    subLabel: scenario.description.getString(t),
  }));

  const savedScenarioIds = scenarios.map((scenario) => scenario.id);

  if (
    !activeComputedData.scenarioInfo.scenarioId
    || !savedScenarioIds.includes(activeComputedData.scenarioInfo.scenarioId)
  ) {
    scenarioEntries.push({
      label: t('scenarioTitleCustom'),
      key: 'activeNotSavedScenario',
    });
  }

  function handleChange(aOrB: 'A' | 'B', newValue: KeyType | null) {
    let newCompareScenario: number | null;
    if (newValue === 'activeNotSavedScenario') {
      newCompareScenario = null;
    } else {
      newCompareScenario = newValue;
    }
    _setCompareScenarios(
      aOrB === 'A' ? newCompareScenario : compareScenarioA,
      aOrB === 'B' ? newCompareScenario : compareScenarioB,
    );
    _changeCalculationState('forCompareScenarios', CalculationState.Requested);
  }


  function handleClose() {
    _changeCompareOpen(false);
  }

  if (!isComparisonActive) {
    return null;
  }

  return (
    <Dialog
      open={isComparisonActive}
      onClose={handleClose}
      fullScreen={fullScreen}
      PaperProps={{ className: classes.root }}
    >
      <DialogTitle>
        <div className={classes.titleContainer}>
          Szenarien-Vergleich:
          <ScenarioSelect<KeyType>
            name={t('scenarioA')}
            uid="select-scenario-a"
            entries={scenarioEntries}
            onChange={(x) => handleChange('A', x)}
            activeEntry={getActiveKey(compareScenarioA)}
            className={classes.scenarioInput}
            selectClassName={classes.select}
          />
          &
          <ScenarioSelect
            name={t('scenarioB')}
            uid="select-scenario-b"
            entries={scenarioEntries}
            onChange={(x) => handleChange('B', x)}
            activeEntry={getActiveKey(compareScenarioB)}
            className={classes.scenarioInput}
            selectClassName={classes.select}
          />
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <ComparisonLayout
          activeComputedData={activeComputedData}
          compareScenarioA={compareScenarioA}
          compareScenarioB={compareScenarioB}
          computedDataSets={computedDataSets}
          scenarios={scenarios}
        />
      </DialogContent>
    </Dialog>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapParamsToProps(state),
  ...mapComputedToProps(state),
  ...mapCompareStateToProps(state),
});
const connector = connect(mapToProps, {
  changeCompareOpen,
  changeCalculationState,
  setCompareScenarios,
});

export default connector(ComparisonDialog);
