import React, { FunctionComponent, ReactNode } from 'react';
import {
  makeStyles, Theme, createStyles, withStyles,
} from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PARAMETER_SPACING } from '../../../domain_model/GlobalStyleConstants';
import { DrawerSection } from '../../../redux/types/uiTypes';

const useStyles = makeStyles((theme: Theme) => createStyles({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginLeft: theme.spacing(2),
    flexShrink: 0,
  },
  details: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    width: '100%',
  },
  highlighted: {
    animation: '$pulse 1.5s infinite',
  },
  '@keyframes pulse': {
    '30%': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    minHeight: '3em',
    transition: 'all 150ms ease',
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[100],
    },
    '&$expanded': {
      color: theme.palette.grey[800],
      minHeight: '5em',
      backgroundColor: 'unset',
    },
    paddingRight: theme.spacing(4),
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))(MuiExpansionPanelDetails);

type Props = {
  id: DrawerSection;
  title: string;
  expanded: string | false;
  setExpanded: (expanded: DrawerSection | false) => void;
  icon: ReactNode;
  highlighted?: boolean;
}

const Panel: FunctionComponent<Props> = (props) => {
  const {
    children, expanded, id, setExpanded, title, icon, highlighted,
  } = props;
  const classes = useStyles();

  const handleChange = (panel: DrawerSection) => (
    _: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
      square
      expanded={expanded === id}
      onChange={handleChange(id)}
      className={highlighted ? classes.highlighted : ''}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
      >
        {icon}
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.details}>
          {children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Panel;
