/* eslint-disable func-names */
import * as React from 'react';
import Highcharts from 'highcharts/highstock';
import highchartsBullet from 'highcharts/modules/bullet';
import HighchartsReact from 'highcharts-react-official';
import { Chart, CSSObject } from 'highcharts';
import { createStyles, makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { DataSeries } from '../../domain_model/SourceDataCollection';
import { chartColors } from '../../domain_model/Colors';
import { mapGraphResolutionToProps } from '../../redux/mappers';
import { beautifyNumber } from '../helper';

highchartsBullet(Highcharts);

const useStyles = makeStyles(() => createStyles({
  root: {
    height: '17em',
  },
  highCharts: {
    height: '100%',
  },
}));

const legendItemStyle: CSSObject = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '0.75rem',
  fontWeight: '400',
  letterSpacing: '0.03333em',
};

export type Extremes = {
  min: number;
  max: number;
};

export type PlotDataSeries = {
  type: 'area' | 'line' | 'column';
  data: DataSeries;
  name: string;
  color?: string;
}


type Props = PropsFromRedux & {
  plotData: PlotDataSeries[];
  unit: string;
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
  stack?: boolean;
  centerZero?: boolean;
  noOneBarResolution?: boolean;
}

const getOptions = (
  onNewExtremes: (extremes: Extremes) => void,
  unit: string,
  stack: boolean,
  centerZero: boolean,
): Highcharts.Options => ({
  chart: {
    spacing: [10, 0, 0, 0],
    zoomType: 'x',
    // Keep for resolution switch
    inverted: false,
  },
  colors: chartColors,
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      month: {
        main: '%b',
      },
    },
    events: {
      afterSetExtremes: onNewExtremes,
    },
    labels: {
      // Keep for resolution change
      enabled: true,
    },
  },
  // @ts-ignore
  yAxis: {
    title: {
      text: '',
    },
    // Only Used for the 1-bar StorageInOut-Graph (centers the 0 point)
    tickPositioner: centerZero ? function () {
      // @ts-ignore
      const maxDeviation = Math.ceil(Math.max(Math.abs(this.dataMax), Math.abs(this.dataMin)));
      // @ts-ignore
      return this.getLinearTickPositions(this.tickInterval, -maxDeviation, maxDeviation);
    } : undefined,
    labels: {
      align: 'left',
      x: 0,
      y: -2,
      formatter() {
        return `${beautifyNumber(this.value, 2, true)} ${unit}`;
      },
      reserveSpace: undefined,
      step: undefined,
      autoRotation: undefined,
    },
    opposite: false,
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
    outside: true,
    padding: 1,
    formatter() {
      return this.points && this.points.reduce((partial, point, i) => (
        `${partial}${i % 2 === 0 ? '<br />' : ' | '}${
          point.series.name.split(' ')[0].length > 10
            ? `${point.series.name.split(' ')[0].substring(0, 9)}.`
            : point.series.name.split(' ')[0]
        }: ${(function () {
          // @ts-ignore
          const value = point.point.target
            // @ts-ignore
            ? point.point.target
            : point.y;
          return beautifyNumber(value, value < 10 ? 3 : 0, true);
        }())} ${unit}`
      ), '');
    },
  },
  legend: {
    enabled: true,
    reversed: true,
    align: 'left',
    alignColumns: false,
    padding: 0,
    margin: 12,
    itemMarginTop: 8,
    itemMarginBottom: 4,
    itemStyle: legendItemStyle,
  },
  rangeSelector: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    // Options for all types
    series: {
      pointStart: 0,
      pointInterval: 2 * 0.25 * 3600 * 1000,
      dataGrouping: {
        approximation: 'average',
        // Don't delete! Without undefined, the value does not get unset on resolution change
        units: undefined,
        forced: false,
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    column: {
      stacking: 'normal',
    },
    area: {
      stacking: stack ? 'normal' : undefined,
      lineWidth: 1,
    },
    line: {
      color: '#000000',
      lineWidth: 0.75,
      shadow: false,
    },
  },
  scrollbar: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  // Keep for resolution switch
  series: [],
});

const ChartComponent = React.memo((props: Props) => {
  const {
    plotData: plotDataOriginal,
    chartCallback,
    onNewExtremes,
    onMouseMove,
    onMouseLeave,
    unit,
    stack,
    graphResolution,
    centerZero,
    noOneBarResolution,
  } = props;
  const { highCharts, root } = useStyles();
  const options = getOptions(onNewExtremes, unit, stack || false, centerZero || false);
  const plotData = _.reverse(_.clone(plotDataOriginal));
  let changes: Highcharts.Options;
  switch (noOneBarResolution && graphResolution === 'year' ? 'month' : graphResolution) {
    case 'year':
      changes = {
        chart: { inverted: true },
        xAxis: {
          labels: { enabled: false },
        },
        yAxis: {
          opposite: true,
          labels: {
            reserveSpace: true,
            autoRotation: false,
          },
        },
        series: plotData.map((x): PlotDataSeries => ({
          ...x,
          // @ts-ignore
          type: x.type === 'area' ? 'column' : 'bullet',
        })),
      };
      _.merge(options, changes);
      break;
    case 'month':
      changes = {
        plotOptions: {
          series: {
            dataGrouping: {
              forced: true,
              units: [['month', [1]]],
            },
          },
        },
        series: plotData.map((x): PlotDataSeries => ({
          ...x,
          type: x.type === 'area' ? 'column' : x.type,
        })),
      };
      _.merge(options, changes);
      break;
    case 'day':
      changes = {
        plotOptions: {
          series: {
            dataGrouping: {
              forced: true,
              units: [['day', [1]], ['week', [1]]],
            },
          },
        },
        series: plotData.map((x): PlotDataSeries => ({
          ...x,
          type: x.type === 'area' ? 'column' : x.type,
        })),
      };
      _.merge(options, changes);
      break;
    case 'continuous':
      changes = {
        plotOptions: {
          series: {
            dataGrouping: undefined,
          },
        },
        series: plotData,
      };
      _.merge(options, changes);
      break;
    default:
      throw new Error('unknown graphResolution');
  }
  return (
    <div className={root}>
      <HighchartsReact
        constructorType="stockChart"
        highcharts={Highcharts}
        options={options}
        callback={chartCallback}
        containerProps={{
          className: highCharts,
          onMouseMove,
          onTouchMove: onMouseMove,
          onTouchStart: onMouseMove,
          onMouseLeave,
        }}
      />
    </div>
  );
});

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapGraphResolutionToProps);

export default connector(ChartComponent);
