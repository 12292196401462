import React from "react";

const SvgRiver = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M17 14.8c-1.4 0-2.2.4-2.9.8-.6.3-1.2.6-2.1.6-.9 0-1.4-.3-2.1-.6-.8-.4-1.6-.8-2.9-.8s-2.2.4-2.9.8c-.7.3-1.2.6-2 .6v2c1.4 0 2.2-.4 3-.8.7-.3 1.2-.6 2-.6s1.4.3 2.1.6c.8.4 1.6.8 2.9.8s2.2-.4 2.9-.8c.6-.3 1.2-.6 2.1-.6.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8v-2c-.9 0-1.4-.3-2-.6-.9-.4-1.7-.8-3.1-.8zM17 10.3c-1.4 0-2.2.4-2.9.8-.6.3-1.2.6-2.1.6-.9 0-1.4-.3-2.1-.6-.8-.4-1.6-.8-2.9-.8s-2.2.4-2.9.8c-.7.3-1.2.6-2 .6v1.9c1.4 0 2.2-.4 3-.8.7-.4 1.2-.6 2-.6s1.4.3 2.1.6c.8.4 1.6.8 2.9.8s2.2-.4 2.9-.8c.6-.4 1.2-.6 2.1-.6s1.4.3 2 .6c.8.4 1.6.8 3 .8v-1.9c-.9 0-1.4-.3-2-.6-.9-.4-1.7-.8-3.1-.8zM17 5.9c-1.4 0-2.2.4-2.9.8-.7.3-1.2.6-2.1.6s-1.4-.3-2.1-.6c-.7-.4-1.5-.8-2.9-.8s-2.2.4-2.9.8c-.7.3-1.2.6-2.1.6v1.9c1.4 0 2.2-.4 3-.8.7-.3 1.2-.6 2-.6s1.4.3 2.1.6c.8.4 1.6.8 2.9.8s2.2-.4 2.9-.8c.6-.3 1.2-.6 2.1-.6.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8v-2c-.9 0-1.4-.3-2-.6-.8-.3-1.6-.7-3-.7z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

export default SvgRiver;
