import { connect, ConnectedProps } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax,import/extensions
import Worker from 'worker-loader!../CalculationWorker.ts';
import { mapControllerStateToProps } from '../redux/mappers';
import CalculationState from '../domain_model/CalculationState';
import {
  DataSeries,
  DataSeriesSection,
  dataSeriesSections,
  DataStatus,
  IDataSeriesDto,
} from '../domain_model/SourceDataCollection';
import { fetchDataSeries } from '../data/fetchData';
import {
  addComputedData,
  addDataSeries,
  changeCalculationState,
  registerConnectionErrorLoop,
  requestComparePreComputedData,
  resolveConnectionErrorLoop,
  setActiveComputedData,
  setFetchingFlag,
} from '../redux/actions';
import { NonLocalizedString } from '../domain_model/scenario/NonLocalizedString';
import { ScenarioId } from '../domain_model/scenario/Scenario';
import { ILocalizableString } from '../domain_model/scenario/ILocalizableString';
import { IParameterCollection } from '../domain_model/Parameters';
import { IComputedDataWithoutInfo } from '../domain_model/ComputedDataCollection';
import { CalculationInputDTO } from '../CalculationWorker';
import { generateHash, startFetchLoop } from './helper';
import { sleep } from '../helper';

type Props = PropsFromRedux


const DataController = (props: Props) => {
  const { t } = useTranslation();

  const {
    data,
    preloadData,
    preloadSecondaryData,
    activeParams,
    activeScenario,
    scenarios,
    calculationStates: {
      activeScenario: calcStateOfActiveScenario,
      comparedScenarios: calcStateOfComparedScenarios,
    },
    compareState: {
      compareScenarioB,
      compareScenarioA,
    },
    addDataSeries: _addDataSeries,
    setFetchingFlag: _setFetchingFlag,
    changeCalculationState: _changeCalculationState,
    setActiveComputedData: _setActiveComputedData,
    addComputedData: _addComputedData,
    computedDataSets,
    preComputedData: {
      solar: {
        active: {
          isUpToDate: solarPreCalcIsUpToDate,
          data: {
            highRes: solarPreCalcData,
          },
        },
        compare: preComputedSolarAB,
      },
    },
    requestComparePreComputedData: _requestComparePreComputedData,
    registerConnectionErrorLoop: _registerConnectionErrorLoop,
    resolveConnectionErrorLoop: _resolveConnectionErrorLoop,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  function loadIfSeriesForActiveParamsIsMissing(
    section: DataSeriesSection,
    params: IParameterCollection,
  ): boolean {
    let yearOrSlug: string;
    if (
      section === 'solarDiffuseA'
      || section === 'solarDirectA'
      || section === 'solarDiffuseB'
      || section === 'solarDirectB'
      || section === 'solarDiffuseC'
      || section === 'solarDirectC'
    ) {
      yearOrSlug = params.solar.yearOrSlug;
    } else if (section === 'dam') {
      yearOrSlug = params.waterStorage.dam.yearOrSlug;
    } else {
      yearOrSlug = params[section].yearOrSlug;
    }
    switch (data.getOneYearDataSeriesStatus(section, yearOrSlug)) {
      case DataStatus.Available:
        return false;
      case DataStatus.FetchInProgress:
        return true;
      case DataStatus.NotRequested:
        _setFetchingFlag(section, yearOrSlug);
        startFetchLoop<IDataSeriesDto>(
          () => fetchDataSeries(section, yearOrSlug),
          (dto) => _addDataSeries(section, yearOrSlug, dto.data),
          (err) => {
            enqueueSnackbar(
              `Data Series could not be loaded: ${err}`,
              { variant: 'error', preventDuplicate: true, autoHideDuration: 1000 * 30 },
            );
          },
          _registerConnectionErrorLoop,
          _resolveConnectionErrorLoop,
          30,
        ).then();
        return true;
      default:
        throw new Error('unknown DataStatus');
    }
  }

  const isDataCached = (scenarioId: ScenarioId) => scenarioId in computedDataSets;

  async function calculateWithWorker(
    params: IParameterCollection,
    solarPreCalc: DataSeries,
  ): Promise<IComputedDataWithoutInfo> {
    return new Promise((resolve) => {
      const worker = new Worker();
      worker.addEventListener('message', (event: { data: IComputedDataWithoutInfo }) => {
        resolve(event.data);
      });
      const dto: CalculationInputDTO = {
        srcData: data.toDTO(),
        params,
        solarPreCalc,
        srcPreloadData: preloadData,
      };
      worker.postMessage(dto);
    });
  }

  async function computeAndSaveData(
    scenarioId: ScenarioId,
    scenarioName: ILocalizableString,
    scenarioDescription: ILocalizableString,
    params: IParameterCollection,
    solarPreCalc: DataSeries,
    setActive: boolean,
  ) {
    let result;
    if (scenarioId in computedDataSets) { // Load from cache
      result = computedDataSets[scenarioId];
      await sleep(200);
    } else {
      result = {
        scenarioInfo: {
          scenarioId: scenarioId || undefined,
          scenarioName,
          scenarioDescription,
        },
        ...await calculateWithWorker(params, solarPreCalc),
      };
      if (scenarioId) {
        _addComputedData(scenarioId, result);
      }
    }
    setActive && _setActiveComputedData(result);
  }

  const [isActiveCalculationRunning, setIsActiveCalculationRunning] = useState(false);

  async function onCalcInProgressForActiveScenario() {
    setIsActiveCalculationRunning(true);
    const scenarioName = (activeScenario !== null)
      ? scenarios[activeScenario].name
      : new NonLocalizedString(t('scenarioTitleCustom'));
    const scenarioDescription = (activeScenario !== null)
      ? scenarios[activeScenario].description
      : new NonLocalizedString('');
    const scenarioId = (activeScenario !== null)
      ? scenarios[activeScenario].id
      : '';
    await computeAndSaveData(
      scenarioId,
      scenarioName,
      scenarioDescription,
      activeParams,
      solarPreCalcData,
      true,
    );
    setIsActiveCalculationRunning(false);
    _changeCalculationState('forActiveScenario', CalculationState.Idle);
  }

  function onCalcRequestForActiveScenario() {
    enqueueSnackbar(
      t('calculationStarted'),
      { variant: 'info' },
    );
    _changeCalculationState('forActiveScenario', CalculationState.InProgress);
  }

  const [isCompareCalculationRunning, setIsCompareCalculationRunning] = useState(false);

  async function computeAndSaveCompareData(
    scenarioIndex: number,
    solarPreCalc: DataSeries,
  ) {
    const scenarioName = scenarios[scenarioIndex].name;
    const scenarioDescription = scenarios[scenarioIndex].description;
    const scenarioId = scenarios[scenarioIndex].id;
    if (isDataCached(scenarioId)) {
      _changeCalculationState('forCompareScenarios', CalculationState.InProgressNoLoadingScreen);
    } else {
      _changeCalculationState('forCompareScenarios', CalculationState.InProgress);
      await sleep(0);
    }
    await computeAndSaveData(
      scenarioId,
      scenarioName,
      scenarioDescription,
      scenarios[scenarioIndex].parameters,
      solarPreCalc,
      false,
    );
  }

  function getPreCalcOrElseRequest(ab: 'a' | 'b', compareScenario: number): DataSeries | null {
    const preComputed = preComputedSolarAB[ab];
    switch (preComputed.state) {
      case 'CALCULATED':
        if (preComputed.hash !== generateHash(scenarios[compareScenario].parameters.solar)) {
          _requestComparePreComputedData(ab, compareScenario);
          return null;
        }
        return preComputed.data.highRes;
      case 'REQUESTED':
        if (preComputed.scenarioId !== compareScenario) {
          _requestComparePreComputedData(ab, compareScenario);
        }
        return null;
      case 'NOT_INITIALIZED':
        _requestComparePreComputedData(ab, compareScenario);
        return null;
      default:
        throw new Error('unhandled case');
    }
  }

  function onCalcRequestForCompareScenarios() {
    if (compareScenarioA !== null) {
      getPreCalcOrElseRequest('a', compareScenarioA);
    }
    if (compareScenarioB !== null) {
      getPreCalcOrElseRequest('b', compareScenarioB);
    }
    _changeCalculationState('forCompareScenarios', CalculationState.InProgress);
  }

  async function onCalcInProgressForCompareScenarios() {
    let preCalcA: DataSeries | null = null;
    let preCalcB: DataSeries | null = null;
    if (compareScenarioA !== null) {
      preCalcA = getPreCalcOrElseRequest('a', compareScenarioA);
    }
    if (compareScenarioB !== null) {
      preCalcB = getPreCalcOrElseRequest('b', compareScenarioB);
    }

    // if any preCalc is still missing then return
    if ((compareScenarioA !== null && !preCalcA)
      || (compareScenarioB !== null && !preCalcB)) return;

    setIsCompareCalculationRunning(true);
    if (compareScenarioA !== null && preCalcA) {
      await computeAndSaveCompareData(compareScenarioA, preCalcA);
    }
    if (compareScenarioB !== null && preCalcB) {
      await computeAndSaveCompareData(compareScenarioB, preCalcB);
    }
    setIsCompareCalculationRunning(false);
    _changeCalculationState('forCompareScenarios', CalculationState.Idle);
  }

  useEffect(() => {
    let dataMissing = false;
    dataMissing = preloadSecondaryData.thetaA.length <= 0 || dataMissing;
    dataSeriesSections.forEach((section): void => {
      dataMissing = loadIfSeriesForActiveParamsIsMissing(section, activeParams) || dataMissing;
      if (compareScenarioA !== null) {
        dataMissing = loadIfSeriesForActiveParamsIsMissing(
          section,
          scenarios[compareScenarioA].parameters,
        ) || dataMissing;
      }
      if (compareScenarioB !== null) {
        dataMissing = loadIfSeriesForActiveParamsIsMissing(
          section,
          scenarios[compareScenarioB].parameters,
        ) || dataMissing;
      }
    });
    if (dataMissing) {
      return;
    }

    switch (calcStateOfActiveScenario) {
      case CalculationState.Requested:
        onCalcRequestForActiveScenario();
        break;
      case CalculationState.InProgress:
        solarPreCalcIsUpToDate
        && !isActiveCalculationRunning
        && onCalcInProgressForActiveScenario();
        break;
      default:
    }

    switch (calcStateOfComparedScenarios) {
      case CalculationState.Requested:
        onCalcRequestForCompareScenarios();
        break;
      case CalculationState.InProgress:
        !isCompareCalculationRunning
        && onCalcInProgressForCompareScenarios();
        break;
      default:
    }
  });

  return null;
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapControllerStateToProps, {
  addDataSeries,
  setFetchingFlag,
  changeCalculationState,
  setActiveComputedData,
  addComputedData,
  requestComparePreComputedData,
  registerConnectionErrorLoop,
  resolveConnectionErrorLoop,
});

export default connector(DataController);
