import React from 'react';
import { useTranslation } from 'react-i18next';
import YearAndScaling from '../input-combined/YearAndScaling';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_POWER_UNIT } from '../../../../domain_model/math/PowerUnit';
import { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const GRAPH_LIMIT = new Quantity(7, DB_POWER_UNIT, false);

const NuclearParams = (): JSX.Element => {
  const { t } = useTranslation();

  const infoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'kernkraftwerke',
      // TODO: add en doku paragraph
      en: 'kernkraftwerke',
    },
  };

  return (
    <YearAndScaling
      section="nuclear"
      scalingLabel={t('paramsMaxPower')}
      graphLimit={GRAPH_LIMIT}
      originalUnit={DB_POWER_UNIT}
      infoButtonProps={infoButtonProps}
      scalingTootltip={t('infoNuclearScaling')}
      yearTooltip={t('infoNuclearYear')}
    />
  );
};

export default NuclearParams;
