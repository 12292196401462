import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import ScreenSizeDefinitions from '../screen_size_handling/ScreenSizeDefinitions';

export const DEFINED_SCREEN_SIZES = new ScreenSizeDefinitions();
export const SMALL = DEFINED_SCREEN_SIZES.small;
export const MEDIUM = DEFINED_SCREEN_SIZES.medium;
export const MEDIUM_LARGE = DEFINED_SCREEN_SIZES.mediumLarge;
export const LARGE = DEFINED_SCREEN_SIZES.large;
export const EXTRA_LARGE = DEFINED_SCREEN_SIZES.extraLarge;
export const DESKTOP_SPACING = 6;
export const ACCORDION_WIDTH = 6;
export const PARAMETER_SPACING = 3;

export const customIconStyles: CreateCSSProperties = {
  fill: 'currentColor',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fontSize: '1.5rem',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  flexShrink: 0,
  userSelect: 'none',
};
