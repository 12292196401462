import React from 'react';
import {
  createStyles, Divider, Grid, Theme, Typography,
} from '@material-ui/core';
import { Chart } from 'highcharts/highstock';
import { WithStyles, withStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import Overview from '../overview/Overview';
import { DESKTOP_SPACING } from '../../domain_model/GlobalStyleConstants';
import ProductionChart from './ProductionChart';
import { Extremes } from './ChartComponent';
import MainCard from './MainCard';
import ConsumptionChart from './ConsumptionChart';
import StorageInOutChart from './StorageInOutChart';
import StorageLevelChart from './StorageLevelsChart';
import TrafficLights from './TrafficLights';
import ScenarioDescription from './ScenarioDescription';
import ChangeGraphResolutionButtons from '../shared/ChangeGraphResolutionButtons';
import { mapGraphResolutionToProps } from '../../redux/mappers';
import { TrackedButton } from '../../analytics/analytics';
import { E } from '../../analytics/types';

type Props = WithStyles<typeof styles> & WithTranslation & PropsFromRedux;


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  leftContent: {
    alignContent: 'flex-start',
    overflow: 'auto',
  },
  space: {
    width: theme.spacing(DESKTOP_SPACING),
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'stretch',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: '3em',
    marginRight: '3em',
    minWidth: '7em',
  },
  divider: {
    flex: 1,
  },
});

type State = {
  extendedStorage: boolean;
}

class Content extends React.PureComponent<Props, State> {
  private charts: Chart[] = [];
  private extremes: Extremes = { min: 0, max: 0 };

  constructor(props: Props) {
    super(props);
    this.state = {
      extendedStorage: false,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { graphResolution } = this.props;
    if (prevProps.graphResolution !== graphResolution && graphResolution === 'year') {
      this.onNewExtremes({ min: 0, max: 0 });
    }
  }

  private onTransitioned = () => {
    this.charts.filter((c) => c.xAxis !== undefined).forEach((chart) => {
      chart.reflow();
    });
  };

  private onNewExtremes = (newExtremes: Extremes) => {
    const { min, max } = newExtremes;
    if (this.extremes.min !== min || this.extremes.max !== max) {
      this.extremes = newExtremes;
      this.charts.filter((c) => c.xAxis !== undefined).forEach((chart) => {
        const { graphResolution } = this.props;
        if (graphResolution === 'year') {
          chart.xAxis[0].setExtremes(0, undefined);
        } else if (
          (chart.xAxis[0].min !== undefined && chart.xAxis[0].min !== min)
          || (chart.xAxis[0].max !== undefined && chart.xAxis[0].max !== max)
        ) {
          chart.xAxis[0].setExtremes(min, max);
        }
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onMouseMove = (e: any, chartIndex: number) => {
    const { graphResolution } = this.props;
    if (graphResolution === 'year') {
      return;
    }
    const sourceChart = this.charts[chartIndex];
    const event = sourceChart.pointer.normalize(e);
    const x = event.chartX;
    this.charts.filter((c) => c.xAxis !== undefined).forEach((chart, i) => {
      // remove old plot line and draw new plot line (crosshair)
      const xAxis1 = chart.xAxis[0];
      xAxis1.removePlotLine('myPlotLineId');
      if (i !== chartIndex) {
        xAxis1.addPlotLine({
          // @ts-ignore
          value: chart.xAxis[0].translate(x, true),
          width: 0.5,
          color: 'grey',
          id: 'myPlotLineId',
        });
      }
    });
  };

  private onMouseOut = (_: MouseEvent, chartIndex: number) => {
    this.charts.filter((c) => c.xAxis !== undefined).forEach((chart, i) => {
      if (i !== chartIndex) {
        const xAxis1 = chart.xAxis[0];
        xAxis1.removePlotLine('myPlotLineId');
      }
    });
  };

  private setChart = (chart: Chart, index: number) => {
    this.charts[index] = chart;
  };

  public render() {
    const { classes, t } = this.props;
    const { extendedStorage } = this.state;
    return (
      <>
        <main className={classes.root}>
          <Grid container spacing={DESKTOP_SPACING} className={classes.leftContent}>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <ScenarioDescription />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6} className={classes.flexEnd}>
              <TrafficLights />
            </Grid>

            <Grid item xs={12} lg={extendedStorage ? 6 : 12}>
              <MainCard
                title="graphProductionTitle"
                button={<ChangeGraphResolutionButtons />}
              >
                <ProductionChart
                  chartCallback={(chart) => this.setChart(chart, 0)}
                  onNewExtremes={this.onNewExtremes}
                  onMouseMove={(e) => this.onMouseMove(e, 0)}
                  onMouseLeave={(e) => this.onMouseOut(e, 0)}
                />
              </MainCard>
            </Grid>
            <Grid item xs={12} lg={extendedStorage ? 6 : 12}>
              <MainCard
                title="graphConsumptionTitle"
                button={<ChangeGraphResolutionButtons />}
              >
                <ConsumptionChart
                  chartCallback={(chart) => this.setChart(chart, 2)}
                  onNewExtremes={this.onNewExtremes}
                  onMouseMove={(e) => this.onMouseMove(e, 2)}
                  onMouseLeave={(e) => this.onMouseOut(e, 2)}
                />
              </MainCard>
            </Grid>
            {extendedStorage ? (
              <>
                <Grid item xs={12} lg={12}>
                  <div className={classes.titleContainer}>
                    <Typography variant="h5">
                      {t('storageSectionTitle')}
                    </Typography>
                    <TrackedButton
                      color="primary"
                      variant="outlined"
                      className={classes.button}
                      analyticsEvent={E.hideStrg}
                      onClick={() => this.setState({ extendedStorage: false })}
                    >
                      {t('hide')}
                    </TrackedButton>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainCard
                    title="graphLevelsTitle"
                    button={<ChangeGraphResolutionButtons disableOneBar />}
                  >
                    <StorageLevelChart
                      chartCallback={(chart) => this.setChart(chart, 1)}
                      onNewExtremes={this.onNewExtremes}
                      onMouseMove={(e) => this.onMouseMove(e, 1)}
                      onMouseLeave={(e) => this.onMouseOut(e, 1)}
                    />
                  </MainCard>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainCard
                    title="graphStorageInOutTitle"
                    button={<ChangeGraphResolutionButtons />}
                  >
                    <StorageInOutChart
                      chartCallback={(chart) => this.setChart(chart, 3)}
                      onNewExtremes={this.onNewExtremes}
                      onMouseMove={(e) => this.onMouseMove(e, 3)}
                      onMouseLeave={(e) => this.onMouseOut(e, 3)}
                    />
                  </MainCard>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} lg={12}>
                <div className={classes.titleContainer}>
                  <Typography variant="h5">
                    {t('storageSectionTitle')}
                  </Typography>
                  <TrackedButton
                    className={classes.button}
                    color="primary"
                    variant="outlined"
                    analyticsEvent={E.showStrg}
                    onClick={() => this.setState({ extendedStorage: true })}
                  >
                    {t('show')}
                  </TrackedButton>
                  <Divider orientation="horizontal" className={classes.divider} />
                </div>
              </Grid>
            )}
          </Grid>
          <div className={classes.space} />
          <Overview onTransitioned={this.onTransitioned} />
        </main>
      </>
    );
  }
}

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapGraphResolutionToProps);

export default connector(withStyles(styles)(withTranslation()(Content)));
