import React from 'react';
import { createStyles, makeStyles, Slider } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => createStyles({
  vertical: {
    margin: '0 -9px',
  },
  horizontal: {
    margin: '-9px 0',
  },
}));

type Props = {
  className?: string;
  color?: 'secondary' | 'primary';
  value: number;
  onNewValue: (value: number) => void;
  max: number;
  step: number;
  vertical?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SimpleSlider = React.forwardRef((props: Props, ref: any) => {
  const classes = useStyles();
  const {
    className, value, onNewValue, max, step, vertical, disabled, color,
  } = props;

  const handleSliderChange = (_: unknown, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onNewValue(newValue);
    }
  };

  return (
    <div
      ref={ref}
      className={clsx(className, vertical ? classes.vertical : classes.horizontal)}
    >
      <Slider
        color={color || 'primary'}
        value={value}
        max={max}
        step={step}
        onChange={handleSliderChange}
        orientation={vertical ? 'vertical' : 'horizontal'}
        aria-labelledby="input-slider"
        disabled={disabled}
      />
    </div>
  );
});

export default SimpleSlider;
