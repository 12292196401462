import React from 'react';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import ExpandArrowDownIcon from '@material-ui/icons/ExpandMore';
import ExpandableRow from './ExpandableRow';

type Props = {
  tableClassName: string;
  tableDataArray: TableData[];
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  extraLeftSpacing?: number;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    tableLayout: 'fixed',
    '& td, & th': {
      borderWidth: '1px',
      borderStyle: 'none',
      borderColor: 'rgb(224, 224, 224);',
    },
    '& thead': {
      '& th': {
        paddingBottom: theme.spacing(2),
      },
      '& tr:last-of-type': {
        '& th': {
          borderBottomStyle: 'solid',
        },
      },
    },
    '& tbody': {
      '& tr:first-of-type': {
        '& td': {
          paddingTop: theme.spacing(2),
        },
      },
      '& tr:last-of-type': {
        '& td': {
          paddingBottom: theme.spacing(2),
        },
      },
    },
    '& tfoot': {
      '& td': {
        fontSize: '0.875rem',
        color: theme.palette.text.primary,
      },
      '& tr:first-of-type': {
        '& td': {
          paddingTop: theme.spacing(2),
          borderTopStyle: 'solid',
        },
      },
    },
  },
  firstColumnCell: {
    paddingLeft: (props: Props) => theme.spacing(3 + (props.extraLeftSpacing || 0)),
    height: '2em',
  },
  invisibleCell: {
    lineHeight: '0 !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    borderWidth: '0 !important',
    overflow: 'hidden',
    opacity: 0,
    height: '0 !important',
  },
  alignLeft: {
    textAlign: 'left',
    paddingRight: 0,
  },
  alignRight: {
    textAlign: 'right',
    paddingLeft: 0,
  },
  alignCenter: {
    textAlign: 'center',
  },
  childRowLabelCell: {
    '&&': {
      paddingLeft: (props: Props) => theme.spacing(6 + (props.extraLeftSpacing || 0)),
    },
  },
  expandIconContainer: {
    position: 'relative',
  },
  expandIcon: {
    width: '0.75em',
    position: 'absolute',
    left: (props: Props) => theme.spacing(0.5 + (props.extraLeftSpacing || 0) / 2),
    bottom: '0',
    height: '100%',
    paddingBottom: '0.1em',
    transition: 'all 150ms ease',
  },
  rotate90: {
    transform: 'rotate(-90deg)',
  },
}));

export type SimpleRow = {
  label: string;
  values: string[];
}

type HeadingCell = {
  label: string;
  colspan: number;
  align?: 'right' | 'center';
}

type Row = SimpleRow & {
  childRows?: SimpleRow[];
};

type HeaderRow = {
  headingCells: HeadingCell[];
  variant: 'heading1' | 'heading2' | 'invisible';
};

export type TableData = {
  head: HeaderRow[];
  body: Row[];
  foot: Row[];
}


const MultiFunctionalTable = (props: Props): JSX.Element => {
  const classes = useStyles(props);
  const { tableClassName, tableDataArray } = props;

  const renderSimpleRow = (row: SimpleRow) => (
    <TableRow>
      <TableCell className={classes.firstColumnCell}>{row.label}</TableCell>
      {row.values.map((value, i) => (
        <TableCell className={classes.alignRight} key={i}>{value}</TableCell>
      ))}
    </TableRow>
  );

  const renderChildRows = (
    rows: SimpleRow[],
    childCellClassName: string,
  ) => (
    rows.map((row) => (
      <TableRow key={row.label}>
        <TableCell
          className={clsx(
            classes.firstColumnCell,
            classes.childRowLabelCell,
            childCellClassName,
          )}
        >
          {row.label}
        </TableCell>
        {row.values.map((value, i) => (
          <TableCell
            className={clsx(
              classes.alignRight,
              childCellClassName,
            )}
            key={i}
          >
            {value}
          </TableCell>
        ))}
      </TableRow>
    ))
  );

  const renderExpandableRow = (headerRow: Required<Row>) => (
    <ExpandableRow
      renderHeaderCells={
        (expanded: boolean) => (
          <>
            <TableCell
              className={clsx(
                classes.expandIconContainer,
                classes.firstColumnCell,
              )}
            >
              <div>
                <ExpandArrowDownIcon
                  className={clsx(classes.expandIcon, expanded ? '' : classes.rotate90)}
                />
                {headerRow.label}
              </div>
            </TableCell>
            {
              headerRow.values.map((val, i) => (
                <TableCell
                  className={classes.alignRight}
                  key={i}
                >
                  {expanded ? '' : val}
                </TableCell>
              ))
            }
          </>
        )
      }
      renderChildRows={(className: string) => renderChildRows(headerRow.childRows, className)}
    />
  );

  const renderRow = (row: Row, key: string) => (
    <React.Fragment key={key}>
      {row.childRows ? renderExpandableRow(row as Required<Row>) : renderSimpleRow(row)}
    </React.Fragment>
  );

  function getAlignClassName(align?: 'right' | 'center') {
    switch (align) {
      case 'right':
        return classes.alignRight;
      case 'center':
        return classes.alignCenter;
      default:
        return classes.alignLeft;
    }
  }

  const renderHeaderRow = (row: HeaderRow, key: string) => (
    <TableRow key={key}>
      {row.headingCells.map((cell, i) => (
        <TableCell
          variant={row.variant === 'heading2' && i > 0 ? 'footer' : 'head'}
          className={clsx(
            row.variant === 'invisible' ? classes.invisibleCell : '',
            i === 0 ? classes.firstColumnCell : '',
            getAlignClassName(cell.align),
          )}
          colSpan={cell.colspan}
          key={i}
        >
          {cell.label}
        </TableCell>
      ))}
    </TableRow>
  );


  function renderTableHead(headerRows: HeaderRow[]) {
    if (headerRows.length > 0) {
      return (
        <TableHead>
          {headerRows.map((row, i) => renderHeaderRow(row, i.toString()))}
        </TableHead>
      );
    }
    return null;
  }

  function renderTableBody(bodyRows: Row[]) {
    if (bodyRows.length > 0) {
      return (
        <TableBody>
          {bodyRows.map((row, i) => renderRow(row, i.toString()))}
        </TableBody>
      );
    }
    return null;
  }

  function renderTableFoot(bodyRows: Row[]) {
    if (bodyRows.length > 0) {
      return (
        <TableFooter>
          {bodyRows.map((row, i) => renderRow(row, i.toString()))}
        </TableFooter>
      );
    }
    return null;
  }

  const renderTable = (tableData: TableData, key: string) => (
    <Table className={clsx(tableClassName, classes.table)} size="small" key={key}>
      {renderTableHead(tableData.head)}
      {renderTableBody(tableData.body)}
      {renderTableFoot(tableData.foot)}
    </Table>
  );

  return (
    <>
      {tableDataArray.map((tableData, i) => renderTable(tableData, i.toString()))}
    </>
  );
};

export default MultiFunctionalTable;
