import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { mapUnitToProps } from '../../redux/mappers';
import { changeUnitScope } from '../../redux/actions';
import { UnitScopes } from '../../domain_model/math/constants';
import SimpleSelect from './simpleSelect';
import LoadingScreen from '../shared/LoadingScreen';
import { sleep } from '../../helper';

type Props = PropsFromRedux

const UnitScopeSwitch = ({ unitScope, changeUnitScope: _changeUnitScope }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (newValue: unknown) => {
    if (typeof newValue === 'number') {
      switch (newValue) {
        case UnitScopes.SWITZERLAND:
          setLoading(true);
          enqueueSnackbar(t('valueScopeSwitzerlandLong'));
          sleep(100).then(() => {
            _changeUnitScope(UnitScopes.SWITZERLAND);
            sleep(100).then(() => {
              setLoading(false);
            });
          });
          break;
        case UnitScopes.PERSON:
          setLoading(true);
          enqueueSnackbar(t('valueScopePersonLong'));
          sleep(100).then(() => {
            _changeUnitScope(UnitScopes.PERSON);
            sleep(100).then(() => {
              setLoading(false);
            });
          });
          break;
        default:
          throw new Error('Unknown UnitScope');
      }
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <SimpleSelect onChange={onChange} value={unitScope} name={t('valueScope')}>
        <MenuItem value={UnitScopes.SWITZERLAND}>{t('valueScopeSwitzerland')}</MenuItem>
        <MenuItem value={UnitScopes.PERSON}>{t('valueScopePerson')}</MenuItem>
      </SimpleSelect>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitToProps, { changeUnitScope });

export default connector(UnitScopeSwitch);
