import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import downloadFile from 'js-file-download';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_ENERGY_UNIT } from '../../../../domain_model/math/EnergyUnit';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import LoadConsumptionFromFileDialog from '../../LoadConsumptionFromFileDialog';
import UserDataCurveDiagram from '../UserDataCurveDiagram';
import { INumberConverterTwoWay } from '../../../../domain_model/math/Unit';
import LoadProductionFromFileDialog from '../../LoadProductionFromFileDialog';
import { changeParams, removeCustomData } from '../../../../redux/actions';
import { CustomDataSeriesSections } from '../../../../domain_model/SourceDataCollection';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import NumberInput from '../input-single/inputs/NumberInput';
import { IReduxState } from '../../../../redux/types';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
  customDataTitle: {
    gridArea: 'title',
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  graph: {
    gridArea: 'graph',
    height: '8em',
  },
  customDataContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '2fr 1px 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title title  info  "
                        "graph slider button"
                        "graph slider input "`,
  },
  customExampleContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '2fr 1px 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title  title ."
                        "button .     ."`,
  },
  infoButton: {
    gridArea: 'info',
    placeSelf: 'center end',
  },
  slider: {
    gridArea: 'slider',
  },
  input: {
    gridArea: 'input',
    alignSelf: 'end',
  },
  customDataButton: {
    gridArea: 'button',
    alignSelf: 'start',
  },
}));


type Props = PropsFromRedux;

const { CustomConsumption: Consumption, CustomProduction: Production } = CustomDataSeriesSections;

const GRAPH_LIMIT = new Quantity(80000, DB_ENERGY_UNIT, false);

const { unit: displayUnit, val: displayMaxValue } = GRAPH_LIMIT;
const {
  toThis: convertToDisplay, toOther: convertToDb,
} = displayUnit.getConvertersTwoWay(DB_ENERGY_UNIT) as INumberConverterTwoWay;

const { name: displayUnitName } = displayUnit;

const step = 0.1;
const stepSlider = 1;

const Custom = React.memo((props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [
    loadFromFileDialogOpen,
    setLoadFromFileDialogOpen,
  ] = useState<CustomDataSeriesSections | false>(false);
  const {
    changeParams: _changeParams,
    removeCustomData: _removeCustomData,
    consumption: { scaling: consumptionScaling, hasData: consumptionHasData },
    production: { scaling: productionScaling, hasData: productionHasData },
  } = props;

  const scales = {
    customConsumption: consumptionScaling,
    customProduction: productionScaling,
  };

  const hasData = {
    customConsumption: consumptionHasData,
    customProduction: productionHasData,
  };

  const consumptionDisplayValue = useMemo(() => (
    convertToDisplay(consumptionScaling)
  ), [consumptionScaling]);
  const productionDisplayValue = useMemo(() => (
    convertToDisplay(productionScaling)
  ), [productionScaling]);

  const displayValues = {
    customConsumption: consumptionDisplayValue,
    customProduction: productionDisplayValue,
  };


  const setScaling = (
    section: CustomDataSeriesSections,
    newValue: number,
  ) => {
    _changeParams({
      [section]: {
        scaling: convertToDb(newValue),
      },
    });
  };

  const handleLoadFromFileRequest = (section: CustomDataSeriesSections) => {
    setLoadFromFileDialogOpen(section);
  };

  const handleDeleteRequest = (section: CustomDataSeriesSections) => {
    _removeCustomData(section);
  };

  const infoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'benutzerdefiniert',
      // TODO: add en doku paragraph
      en: 'benutzerdefiniert',
    },
  };

  const buildSection = (section: CustomDataSeriesSections, title: string, inputLabel: string) => (
    <div className={classes.customDataContainer}>
      <Typography className={classes.customDataTitle} variant="overline">
        {title}
      </Typography>

      {section === CustomDataSeriesSections.CustomConsumption && (
        <ClickableInfoButton
          {...infoButtonProps}
          className={classes.infoButton}
        />
      )}

      <UserDataCurveDiagram
        section={section}
        scaling={scales[section]}
        max={GRAPH_LIMIT}
        className={classes.graph}
      />

      {hasData[section]
      && (
        <SimpleSlider
          className={classes.slider}
          value={displayValues[section]}
          onNewValue={(x) => setScaling(section, x)}
          max={displayMaxValue}
          step={stepSlider}
          vertical
        />
      )}

      {hasData[section]
      && (
        <NumberInput
          className={classes.input}
          value={displayValues[section]}
          onNewValue={(x) => setScaling(section, x)}
          unit={displayUnitName}
          label={inputLabel}
          step={step}
        />
      )}

      {hasData[section]
        ? (
          <Button
            variant="outlined"
            className={classes.customDataButton}
            onClick={() => handleDeleteRequest(section)}
            href=""
          >
            {t('remove')}
          </Button>
        )
        : (
          <Button
            variant="outlined"
            className={classes.customDataButton}
            onClick={() => handleLoadFromFileRequest(section)}
            href=""
          >
            {t('add')}
          </Button>
        )}
    </div>
  );

  const handleDownload = () => {
    downloadFile(
      Array.from(Array(35040)).map(() => Math.round(Math.random() * 51 * 2 * 100) / 100).join('\n'),
      `${t('paramsCustomExampleFileName')}.csv`,
    );
  };

  return (
    <>
      <LoadConsumptionFromFileDialog
        open={loadFromFileDialogOpen === Consumption}
        onClose={() => setLoadFromFileDialogOpen(false)}
      />
      <LoadProductionFromFileDialog
        open={loadFromFileDialogOpen === Production}
        onClose={() => setLoadFromFileDialogOpen(false)}
      />
      {buildSection(
        Consumption,
        t('paramsCustomConsumption'),
        t('paramsCustomConsumptionInputLabel'),
      )}
      {buildSection(
        Production,
        t('paramsCustomProduction'),
        t('paramsCustomProductionInputLabel'),
      )}
      <div className={classes.customExampleContainer}>
        <Typography className={classes.customDataTitle} variant="overline">
          {t('paramsCustomExampleFile')}
        </Typography>
        <Button
          className={classes.customDataButton}
          variant="outlined"
          onClick={handleDownload}
        >
          {t('paramsCustomExampleFileButton')}
        </Button>
      </div>
    </>
  );
});


const customSelect = (state: IReduxState) => ({
  consumption: {
    scaling: state.parameter.activeParams.customConsumption.scaling,
    hasData: state.parameter.activeParams.customConsumption.dataSeries.length > 0,
  },
  production: {
    scaling: state.parameter.activeParams.customProduction.scaling,
    hasData: state.parameter.activeParams.customProduction.dataSeries.length > 0,
  },
});

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(customSelect, { removeCustomData, changeParams });

export default connector(Custom);
