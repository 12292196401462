import { DeepPartial } from 'redux';
import { CustomDataSeriesSections } from '../../domain_model/SourceDataCollection';
import Scenario from '../../domain_model/scenario/Scenario';
import { IParameterCollection } from '../../domain_model/Parameters';


export const ADD_SCENARIO = 'ADD_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const SET_ACTIVE_SCENARIO = 'SET_ACTIVE_SCENARIO';
export const CHANGE_PARAMS = 'CHANGE_PARAMS';
export const REMOVE_CUSTOM_DATA = 'REMOVE_CUSTOM_DATA';


// IState Interfaces

export interface IParameterState {
  scenarios: Scenario[];
  activeScenario: number | null;
  activeParams: IParameterCollection;
}


// Action Interfaces

interface IAddScenarioAction {
  type: typeof ADD_SCENARIO;
  payload: {
    scenario: Scenario;
    setActive?: boolean;
  };
}

interface IUpdateScenarioAction {
  type: typeof UPDATE_SCENARIO;
  payload: {
    scenarioIndex: number;
    scenario: Scenario;
    setActive?: boolean;
  };
}

interface IDeleteScenarioAction {
  type: typeof DELETE_SCENARIO;
  payload: {
    scenarioIndex: number;
  };
}

interface ISetActiveScenarioAction {
  type: typeof SET_ACTIVE_SCENARIO;
  payload: { scenarioIndex: number };
}

interface IChangeParamsAction {
  type: typeof CHANGE_PARAMS;
  payload: { params: DeepPartial<IParameterCollection> };
}

interface IRemoveCustomDataAction {
  type: typeof REMOVE_CUSTOM_DATA;
  payload: { section: CustomDataSeriesSections };
}

export type ParameterActionTypes = (
  IAddScenarioAction
  | IUpdateScenarioAction
  | IDeleteScenarioAction
  | ISetActiveScenarioAction
  | IChangeParamsAction
  | IRemoveCustomDataAction
);
