/* eslint-disable import/prefer-default-export */
import { DeepPartial } from 'redux';
import { BaseUnits, UnitScopes } from '../domain_model/math/constants';
import ScreenSize from '../screen_size_handling/ScreenSize';
import { ActionTypes } from './types';
import {
  CustomDataSeriesSections,
  DataSeries,
  DataSeriesSection,
  IPreloadDataCollection,
  IPreloadSecondaryDataCollection,
} from '../domain_model/SourceDataCollection';
import Scenario, { ScenarioId } from '../domain_model/scenario/Scenario';
import { IParameterCollection } from '../domain_model/Parameters';
import CalculationState from '../domain_model/CalculationState';
import { IComputedData, SolarLoadPreScalingData } from '../domain_model/ComputedDataCollection';
import {
  ADD_COMPUTED_DATA,
  ADD_PRE_COMPUTED_CACHE_ENTRY,
  CHANGE_ACTIVE_PRE_COMPUTED_DATA,
  CHANGE_COMPARE_PRE_COMPUTED_DATA,
  DELETE_COMPUTED_DATA,
  REQUEST_COMPARE_PRE_COMPUTED_DATA,
  SET_ACTIVE_COMPUTED_DATA,
} from './types/computedTypes';
import {
  ADD_DATA_SERIES,
  ADD_PRELOAD_DATA,
  ADD_PRELOAD_SECONDARY_DATA,
  SET_DATA_SERIES_FETCHING_FLAG,
} from './types/dataTypes';
import { CHANGE_BASE_UNIT, CHANGE_SIZE, CHANGE_UNIT_SCOPE } from './types/miscTypes';
import {
  ADD_SCENARIO,
  CHANGE_PARAMS,
  DELETE_SCENARIO,
  REMOVE_CUSTOM_DATA,
  SET_ACTIVE_SCENARIO,
  UPDATE_SCENARIO,
} from './types/parameterTypes';
import {
  CalculationStateTargets,
  CalculationWarningState,
  CHANGE_CALCULATION_STATE,
  CHANGE_CALCULATION_WARNING_STATE,
  CHANGE_COMPARE_OPEN,
  CHANGE_EXPANDED_SECTION,
  CHANGE_GRAPH_RESOLUTION,
  CHANGE_GRAPH_STYLE,
  CHANGE_HIGHLIGHT_STATE,
  CHANGE_LANDING_PAGE_OPEN,
  CHANGE_OVERVIEW_OPEN,
  CHANGE_PARAM_SCENARIO_SELECT_OPEN,
  CHANGE_PARAM_SETTINGS_OPEN,
  DrawerSection,
  GraphResolution,
  GraphStyle,
  IHighlightState,
  REGISTER_CONNECTION_ERROR_LOOP,
  RESOLVE_CONNECTION_ERROR_LOOP,
  SET_COMPARE_SCENARIOS,
} from './types/uiTypes';


// Size

export const changeSize = (size: ScreenSize): ActionTypes => ({
  type: CHANGE_SIZE,
  payload: size,
});


// Unit

export const changeBaseUnit = (baseUnit: BaseUnits): ActionTypes => ({
  type: CHANGE_BASE_UNIT,
  payload: baseUnit,
});

export const changeUnitScope = (unitScope: UnitScopes): ActionTypes => ({
  type: CHANGE_UNIT_SCOPE,
  payload: unitScope,
});


// UiState

export const changeParamSettingsOpen = (open: boolean): ActionTypes => ({
  type: CHANGE_PARAM_SETTINGS_OPEN,
  payload: open,
});

export const changeParamScenarioSelectOpen = (open: boolean): ActionTypes => ({
  type: CHANGE_PARAM_SCENARIO_SELECT_OPEN,
  payload: open,
});

export const changeExpandedSection = (section: DrawerSection | false): ActionTypes => ({
  type: CHANGE_EXPANDED_SECTION,
  payload: section,
});

export const changeOverviewOpen = (open: boolean): ActionTypes => ({
  type: CHANGE_OVERVIEW_OPEN,
  payload: open,
});

export const changeCalculationState = (
  targetState: CalculationStateTargets,
  stateValue: CalculationState,
): ActionTypes => ({
  type: CHANGE_CALCULATION_STATE,
  payload: { targetState, stateValue },
});

export const changeCompareOpen = (isOpen: boolean): ActionTypes => ({
  type: CHANGE_COMPARE_OPEN,
  payload: isOpen,
});

export const setCompareScenarios = (
  scenarioAIndex: number | null,
  scenarioBIndex: number | null,
): ActionTypes => ({
  type: SET_COMPARE_SCENARIOS,
  payload: { scenarioAIndex, scenarioBIndex },
});

export const changeLandingPageOpen = (isOpen: boolean): ActionTypes => ({
  type: CHANGE_LANDING_PAGE_OPEN,
  payload: isOpen,
});

export const changeHighlightState = (
  newState: Partial<IHighlightState>,
): ActionTypes => ({
  type: CHANGE_HIGHLIGHT_STATE,
  payload: newState,
});

export const changeGraphStyle = (newStyle: GraphStyle): ActionTypes => ({
  type: CHANGE_GRAPH_STYLE,
  payload: newStyle,
});

export const changeGraphResolution = (
  newResolution: GraphResolution,
): ActionTypes => ({
  type: CHANGE_GRAPH_RESOLUTION,
  payload: newResolution,
});

export const changeCalculationWarningState = (
  newState: CalculationWarningState,
): ActionTypes => ({
  type: CHANGE_CALCULATION_WARNING_STATE,
  payload: newState,
});

export const registerConnectionErrorLoop = (): ActionTypes => ({
  type: REGISTER_CONNECTION_ERROR_LOOP,
});

export const resolveConnectionErrorLoop = (): ActionTypes => ({
  type: RESOLVE_CONNECTION_ERROR_LOOP,
});


// Data

export const addDataSeries = (
  dataSection: DataSeriesSection,
  yearOrSlug: string,
  dataSeries: DataSeries,
): ActionTypes => ({
  type: ADD_DATA_SERIES,
  payload: { dataSection, yearOrSlug, dataSeries },
});

export const setFetchingFlag = (
  dataSection: DataSeriesSection,
  yearOrSlug: string,
): ActionTypes => ({
  type: SET_DATA_SERIES_FETCHING_FLAG,
  payload: { dataSection, yearOrSlug },
});

export const addPreloadData = (preloadData: IPreloadDataCollection): ActionTypes => ({
  type: ADD_PRELOAD_DATA,
  payload: preloadData,
});

export const addPreloadSecondaryData = (
  preloadSecondaryData: IPreloadSecondaryDataCollection,
): ActionTypes => ({
  type: ADD_PRELOAD_SECONDARY_DATA,
  payload: preloadSecondaryData,
});


// Parameter

export const addScenario = (scenario: Scenario, setActive?: boolean): ActionTypes => ({
  type: ADD_SCENARIO,
  payload: { scenario, setActive },
});

export const updateScenario = (
  scenarioIndex: number,
  scenario: Scenario,
  setActive?: boolean,
): ActionTypes => ({
  type: UPDATE_SCENARIO,
  payload: { scenarioIndex, scenario, setActive },
});

export const deleteScenario = (scenarioIndex: number): ActionTypes => ({
  type: DELETE_SCENARIO,
  payload: { scenarioIndex },
});

export const setActiveScenario = (scenarioIndex: number): ActionTypes => ({
  type: SET_ACTIVE_SCENARIO,
  payload: { scenarioIndex },
});

export const changeParams = (params: DeepPartial<IParameterCollection>): ActionTypes => ({
  type: CHANGE_PARAMS,
  payload: { params },
});

export const removeCustomData = (section: CustomDataSeriesSections): ActionTypes => ({
  type: REMOVE_CUSTOM_DATA,
  payload: { section },
});


// Computed

export const setActiveComputedData = (data: IComputedData): ActionTypes => ({
  type: SET_ACTIVE_COMPUTED_DATA,
  payload: { data },
});

export const addComputedData = (scenarioId: ScenarioId, data: IComputedData): ActionTypes => ({
  type: ADD_COMPUTED_DATA,
  payload: { scenarioId, data },
});

export const deleteComputedData = (scenarioId: string): ActionTypes => ({
  type: DELETE_COMPUTED_DATA,
  payload: { scenarioId },
});

export const changeActivePreComputedData = (
  data: SolarLoadPreScalingData, hash: string,
): ActionTypes => ({
  type: CHANGE_ACTIVE_PRE_COMPUTED_DATA,
  payload: { data, hash },
});

export const changeComparePreComputedData = (
  ab: 'a' | 'b', data: SolarLoadPreScalingData, hash: string,
): ActionTypes => ({
  type: CHANGE_COMPARE_PRE_COMPUTED_DATA,
  payload: { ab, data, hash },
});

export const requestComparePreComputedData = (ab: 'a' | 'b', scenarioId: number): ActionTypes => ({
  type: REQUEST_COMPARE_PRE_COMPUTED_DATA,
  payload: { ab, scenarioId },
});

export const addPreComputedCacheEntry = (
  data: SolarLoadPreScalingData, hash: string,
): ActionTypes => ({
  type: ADD_PRE_COMPUTED_CACHE_ENTRY,
  payload: { data, hash },
});
