import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import CapacityInitialAndMax from '../input-combined/CapacityInitialAndMax';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import PumpStoragePower from '../input-combined/PumpStoragePower';
import BatteryStoragePower from '../input-combined/BatteryStoragePower';
import { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

type Props = PropsFromRedux & {
  section: 'pumpStorage' | 'batteryStorage';
};

const ActiveStorage = (props: Props) => {
  const { section } = props;

  const pumpInfoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'pumpspeicherkraftwerke',
      // TODO: add en doku paragraph
      en: 'pumpspeicherkraftwerke',
    },
  };

  const batteryInfoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'batteriespeicher',
      // TODO: add en doku paragraph
      en: 'batteriespeicher',
    },
  };

  return (
    <>
      {section === 'pumpStorage' ? (
        <PumpStoragePower
          maxPower={6}
          infoButtonProps={pumpInfoButtonProps}
        />
      ) : (
        <BatteryStoragePower
          infoButtonProps={batteryInfoButtonProps}
        />
      )}
      <CapacityInitialAndMax section={section} maxEnergy={800} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(ActiveStorage);
