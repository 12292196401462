import { ActionTypes } from '../types';
import { CHANGE_SIZE, ISizeState } from '../types/miscTypes';

const initialState: ISizeState = {
  currentSize: undefined,
};

export default size;

function size(state = initialState, action: ActionTypes): ISizeState {
  switch (action.type) {
    case CHANGE_SIZE: {
      return {
        ...state,
        currentSize: action.payload,
      };
    }
    default:
      return state;
  }
}
