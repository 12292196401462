// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import Worker from 'worker-loader!../PreCalculationWorker';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { changeCalculationState, changeComparePreComputedData } from '../redux/actions';
import { PreCalculationInputDTO, PreCalculationOutputDTO } from '../PreCalculationWorker';
import { DataStatus } from '../domain_model/SourceDataCollection';
import { generateHash } from './helper';
import { mapControllerStateToProps } from '../redux/mappers';
import { IPvOrientation, IYearOrSlug } from '../domain_model/Parameters';

type Props = PropsFromRedux
type State = {
  currentWorkerHash: string;
  worker?: Worker;
  startTime?: number;
}

const PreCalculationController = (props: Props) => {
  const {
    data,
    scenarios,
    preComputedData: {
      solar: {
        compare: preCompDataAB,
        cache,
      },
    },
    preloadSecondaryData: {
      thetaA,
      thetaE,
      thetaZ,
    },
    changeComparePreComputedData: changeData,
  } = props;

  const [state, setState] = useState<State>({ currentWorkerHash: '' });

  function calculateWithWorker(ab: 'a' | 'b', hash: string, params: IYearOrSlug & IPvOrientation) {
    const newWorker = new Worker();
    newWorker.addEventListener(
      'message',
      (event: { data: PreCalculationOutputDTO }) => handleWorkerResult(ab, event),
    );
    const inputHash = hash;
    const dto: PreCalculationInputDTO = {
      solarDirect: data.getOneYearDataSeries('solarDirectA', params.yearOrSlug),
      solarDiffuse: data.getOneYearDataSeries('solarDiffuseA', params.yearOrSlug),
      thetaA,
      thetaE,
      thetaZ,
      tilt: params.tilt,
      azimuth: params.azimuth,
      inputHash,
    };
    newWorker.postMessage(dto);
    setState({ currentWorkerHash: inputHash, worker: newWorker });
  }

  function handleWorkerResult(ab: 'a' | 'b', event: { data: PreCalculationOutputDTO }) {
    setState({ ...state, worker: undefined, currentWorkerHash: '' });
    changeData(ab, {
      highRes: event.data.output.highRes,
      lowRes: event.data.output.lowRes,
      sum: event.data.output.sum,
      max: event.data.output.max,
    }, event.data.inputHash);
  }

  function stopCurrentWorker() {
    const { worker } = state;
    if (worker !== undefined) {
      setState({ ...state, worker: undefined, currentWorkerHash: '' });
      worker.terminate();
    }
  }

  function IsDataAvailable(param: IYearOrSlug & IPvOrientation) {
    const { Available } = DataStatus;
    return (
      data.getOneYearDataSeriesStatus('solarDiffuseA', param.yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectA', param.yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDiffuseB', param.yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectB', param.yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDiffuseC', param.yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectC', param.yearOrSlug) === Available
      && thetaA.length > 0
      && thetaE.length > 0
      && thetaZ.length > 0
      && param.tilt.length > 0
      && param.azimuth.length > 0
    );
  }

  const isWorkerAlreadyRunning = (hash: string) => state.currentWorkerHash === hash;

  function controllerFor(ab: 'a' | 'b') {
    const preCompData = preCompDataAB[ab];
    switch (preCompData.state) {
      case 'NOT_INITIALIZED':
      case 'CALCULATED':
        return;
      case 'REQUESTED':
        (
          (() => {
            if (props.scenarios.length <= preCompData.scenarioId
              || preCompData.scenarioId < 0) return;
            const params = scenarios[preCompData.scenarioId].parameters.solar;
            if (!IsDataAvailable(params)) return;
            const hash = generateHash(params);
            if (isWorkerAlreadyRunning(hash)) {
              return;
            }
            stopCurrentWorker();
            const cacheEntry = cache.find((cashedEntry) => cashedEntry.hash === hash);
            if (cacheEntry !== undefined) {
              changeData(ab, cacheEntry.data, cacheEntry.hash);
            } else {
              calculateWithWorker(ab, hash, params);
            }
          })()
        );
        break;
      default:
        throw new Error('unhandled case');
    }
  }

  // Watch compare state and update comparePreCalcData
  useEffect(() => {
    controllerFor('a');
    controllerFor('b');
  });

  return null;
};


type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapControllerStateToProps, {
  changeCalculationState,
  changeComparePreComputedData,
});

export default connector(PreCalculationController);
