import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createMuiTheme, createStyles, makeStyles, Theme,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import { connect, ConnectedProps } from 'react-redux';
import { SECONDARY } from '../../domain_model/Colors';
import {
  mapHighlightScenarioSelectToProps,
  mapParamsScenarioSelectOpenToProps,
  mapParamsToProps,
} from '../../redux/mappers';
import { changeParamScenarioSelectOpen, setActiveScenario } from '../../redux/actions';
import LoadFromFileDialog from './LoadScenarioFromFileDialog';
import ScenarioSelect, { SelectEntry } from '../shared/ScenarioSelect';
import { IReduxState } from '../../redux/types';

type Props = PropsFromRedux & {
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  highlighted: {
    animation: '$pulseBackground 1.5s infinite !important',
    borderRadius: '3px',
  },
  formControl: {
    color: 'white !important',
    margin: theme.spacing(1),
    minWidth: '15em',
  },
  select: {
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 1) !important',
    },
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.7) !important',
    },
  },
  selectMenu: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  label: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  '@keyframes pulseBackground': {
    '50%': {
      backgroundColor: 'rgb(255,170,0)',
    },
  },
}));

const innerTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: SECONDARY,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    action: {
      active: 'rgba(255, 255, 255, 0.7)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

type KeyType = number | 'loadFromFile';

const ScenarioPreset = (props: Props) => {
  const {
    className,
    scenarios,
    activeScenario,
    paramsScenarioSelectOpen,
    setActiveScenario: _setActiveScenario,
    highlightScenarioSelect: highlighted,
    changeParamScenarioSelectOpen: _changeParamScenarioSelectOpen,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [loadFromFileDialogOpen, setLoadFromFileDialogOpen] = useState(false);

  function handleOpen() {
    _changeParamScenarioSelectOpen(true);
  }

  function handleClose() {
    _changeParamScenarioSelectOpen(false);
  }

  const handleLoadFromFileRequest = () => {
    setLoadFromFileDialogOpen(true);
  };

  function handleChange(newValue: 'loadFromFile' | number) {
    if (newValue === 'loadFromFile') {
      handleLoadFromFileRequest();
      return;
    }
    _setActiveScenario(newValue);
  }

  const scenarioEntries: SelectEntry<KeyType>[] = scenarios.map((scenario, i) => ({
    label: scenario.name.getString(t),
    key: i,
    subLabel: scenario.description.getString(t),
  }));
  scenarioEntries.push({
    label: t('loadScenarioFromFile'),
    key: 'loadFromFile',
  });

  return (
    <>
      <LoadFromFileDialog
        open={loadFromFileDialogOpen}
        onClose={() => setLoadFromFileDialogOpen(false)}
      />
      <ThemeProvider theme={innerTheme}>
        <ScenarioSelect
          managed={{
            open: paramsScenarioSelectOpen,
            onClose: handleClose,
            onOpen: handleOpen,
          }}
          name={t('scenario')}
          uid="parameter-scenario-select"
          onChange={handleChange}
          entries={scenarioEntries}
          activeEntry={activeScenario}
          className={clsx(classes.formControl, className, highlighted ? classes.highlighted : '')}
          labelClassName={classes.label}
          selectClassName={classes.select}
          selectMenuClassName={classes.selectMenu}
        />
      </ThemeProvider>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapHighlightScenarioSelectToProps(state),
  ...mapParamsToProps(state),
  ...mapParamsScenarioSelectOpenToProps(state),
});
const connector = connect(mapToProps, {
  setActiveScenario,
  changeParamScenarioSelectOpen,
});

export default connector(ScenarioPreset);
