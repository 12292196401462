import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  createStyles, makeStyles, Tab, Tabs, withStyles,
} from '@material-ui/core';
import { mapUnitToProps } from '../../redux/mappers';
import { changeBaseUnit } from '../../redux/actions';
import { BaseUnits } from '../../domain_model/math/constants';

type Props = PropsFromRedux;

const useStyles = makeStyles(() => createStyles({
  tabs: {},
}));

interface IStyledTabProps {
  label: string;
}

const ShortTab = withStyles(() => createStyles({
  root: {
    minWidth: 72,
  },
}))((props: IStyledTabProps) => <Tab {...props} />);

const UnitTabs = ({ baseUnit, changeBaseUnit: _changeBaseUnit }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  function handleChange(_: React.ChangeEvent<unknown>, newValue: number) {
    _changeBaseUnit(newValue === 0 ? BaseUnits.WATT_HOUR : BaseUnits.JOULE);
  }

  return (
    <Tabs
      value={baseUnit === BaseUnits.WATT_HOUR ? 0 : 1}
      variant="fullWidth"
      className={classes.tabs}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
    >
      <ShortTab label={t('wattHour')} />
      <ShortTab label={t('joule')} />
    </Tabs>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitToProps, { changeBaseUnit });

export default connector(UnitTabs);
