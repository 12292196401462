import { Decimal } from 'decimal.js';
import {
  UnitScopes, POTENCIES,
} from './constants';
import { Potency } from './types';
import { Unit } from './Unit';

export default class PowerUnit extends Unit {
  private static potencyFactor = 1000;

  public constructor(name: string, scope: UnitScopes, potency: Potency) {
    super(name, scope, potency, PowerUnit.potencyFactor);
  }

  public static createPowerUnit(scope: UnitScopes, customPotency?: Potency): PowerUnit {
    let baseName;
    let defaultPotency;
    switch (scope) {
      case UnitScopes.SWITZERLAND:
        baseName = 'W';
        defaultPotency = POTENCIES.GIGA;
        break;
      case UnitScopes.PERSON:
        baseName = 'W/P';
        defaultPotency = POTENCIES.KILO;
        break;
      default:
        throw new Error(`Unknown UnitScope: ${scope}`);
    }
    const potency = customPotency || defaultPotency;
    return new PowerUnit(baseName, scope, potency);
  }

  public getConverterTo(otherUnit: Unit): false | ((x: number) => number) {
    if (!(otherUnit instanceof PowerUnit)) {
      return false;
    }
    const potencyConversionFactor = PowerUnit.potencyFactor
      ** (this.potency.hierarchy - otherUnit.potency.hierarchy);
    const scopeConversionFactor = (new Decimal(otherUnit.scope)).dividedBy(new Decimal(this.scope));

    const factor = scopeConversionFactor.times(potencyConversionFactor);
    return (x: number) => factor.times(x).toNumber();
  }

  protected clone(): PowerUnit {
    return new PowerUnit(this.baseName, this.scope, this.potency);
  }
}

export const DB_POWER_UNIT = PowerUnit.createPowerUnit(UnitScopes.SWITZERLAND, POTENCIES.GIGA);
