import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Paper, Theme, Typography,
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type Props = {
  title: string;
  button?: JSX.Element;
};


const MainCard: FunctionComponent<Props> = (props) => {
  const { children, title, button } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">
          {t(title)}
        </Typography>
        {button}
      </div>
      {children}
    </Paper>
  );
};

export default MainCard;
