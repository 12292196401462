import { Unit } from './Unit';

export default class Quantity {
  public val: number;
  public readonly unit: Unit;

  public constructor(val: number, unit: Unit, autoPotentiate?: boolean) {
    if (autoPotentiate) {
      const newUnit = unit.withOptimalPotency(val);
      const converter = unit.getConverterTo(newUnit);
      if (!converter) {
        throw new Error('Invalid unit conversion');
      }
      this.val = converter(val);
      this.unit = newUnit;
    } else {
      this.val = val;
      this.unit = unit;
    }
  }
}
