import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Decimal from 'decimal.js';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import NumberInput from '../input-single/inputs/NumberInput';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import { IStorageEfficiencies } from '../../../../domain_model/Parameters';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    gridArea: 'title',
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  efficiencyContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title  title"
                        "output input"`,
  },
  input: {
    gridArea: 'input',
  },
  output: {
    gridArea: 'output',
  },
}));

type Props = PropsFromRedux & {
  section: 'waterStorage' | 'batteryStorage';
};

const StorageEfficiencies = (props: Props) => {
  const { section } = props;
  const {
    changeParams: _changeParams, activeParams: {
      [section]: { intakeEfficiency, outputEfficiency },
    },
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const setEfficiency = (newValue: number, type: Extract<keyof IStorageEfficiencies, 'intakeEfficiency' | 'outputEfficiency'>) => {
    _changeParams({
      [section]: {
        [type]: newValue,
      },
    });
  };

  return (
    <div className={classes.efficiencyContainer}>
      <Typography className={classes.title} variant="overline">
        {t('efficiencies')}
      </Typography>
      <NumberInput
        className={classes.input}
        label={t('charging')}
        value={new Decimal(intakeEfficiency).mul(100).toNumber()}
        onNewValue={(val) => setEfficiency(new Decimal(val).div(100).toNumber(), 'intakeEfficiency')}
        max={100}
        step={1}
        unit="%"
        alignRight
      />
      <NumberInput
        className={classes.output}
        label={t('discharging')}
        value={new Decimal(outputEfficiency).mul(100).toNumber()}
        onNewValue={(val) => setEfficiency(new Decimal(val).dividedBy(100).toNumber(), 'outputEfficiency')}
        max={100}
        step={1}
        unit="%"
        alignRight
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { changeParams });

export default connector(StorageEfficiencies);
