import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import { createSelector } from 'reselect';
import { mapActiveComputedToPropsFlat, mapUnitToProps } from '../../redux/mappers';
import EnergyUnit, { DB_ENERGY_UNIT } from '../../domain_model/math/EnergyUnit';
import { NumberConverter } from '../../domain_model/math/Unit';
import MultiFunctionalTable, { TableData } from '../shared/MultiFunctionalTable';
import { beautifyNumber } from '../helper';

type Props = PropsFromRedux;

const useStyles = makeStyles(() => createStyles({
  table: {
    '& thead': {
      '& tr:first-of-type': {
        '& th:nth-of-type(1)': {
          width: '70%',
        },
        '& th:nth-of-type(2)': {},
      },
    },
  },
}));

const OverviewTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    baseUnit, unitScope, activeComputedData: {
      windEnergyProduction,
      thermalEnergyProduction,
      solarEnergyProduction,
      riverEnergyProduction,
      pumpEnergyProduction,
      pumpEnergyConsumption,
      pumpEnergyNettProduction,
      nuclearEnergyProduction,
      nettImportEnergy,
      importEnergy,
      exportEnergy,
      nettShortageEnergy,
      shortageEnergy,
      excessEnergy,
      damEnergyProduction,
      batteryEnergyProduction,
      batteryEnergyConsumption,
      batteryEnergyNettProduction,
      customEnergyNettProduction,
      customEnergyProduction,
      customEnergyConsumption,
      endUserEnergyConsumption,
      networkLossEnergyConsumption,

      usableStorageDifference,
      damUsableStorageLevelEnergyDiff,
      damUsableStorageLevelEnergyIncrease,
      damUsableStorageLevelEnergyDecrease,
      pumpUsableStorageLevelEnergyDiff,
      pumpUsableStorageLevelEnergyIncrease,
      pumpUsableStorageLevelEnergyDecrease,
      batteryUsableStorageLevelEnergyDiff,
      batteryUsableStorageLevelEnergyIncrease,
      batteryUsableStorageLevelEnergyDecrease,
    },
  } = props;
  const displayEnergyUnit = EnergyUnit.createEnergyUnit(unitScope, baseUnit);
  const energyConverter = DB_ENERGY_UNIT.getConverterTo(displayEnergyUnit) as NumberConverter;

  const convertAndBeautify = (x: number, fractionDigits = 0) => beautifyNumber(
    energyConverter(x),
    fractionDigits,
  );

  const productionConsumption: TableData = {
    head: [
      {
        headingCells: [
          {
            label: ' ',
            colspan: 1,
          },
          {
            label: ' ',
            colspan: 1,
          },
        ],
        variant: 'heading1',
      },
      {
        headingCells: [
          {
            label: t('summaryAnnualBalance'),
            colspan: 1,
          },
          {
            label: t(displayEnergyUnit.name),
            colspan: 1,
            align: 'right',
          },
        ],
        variant: 'heading2',
      },
    ],
    body: [
      {
        label: t('summaryNuclear'),
        values: [convertAndBeautify(nuclearEnergyProduction)],
      },
      {
        label: t('summaryThermal'),
        values: [convertAndBeautify(thermalEnergyProduction)],
      },
      {
        label: t('summarySolar'),
        values: [convertAndBeautify(solarEnergyProduction)],
      },
      {
        label: t('summaryWind'),
        values: [convertAndBeautify(windEnergyProduction)],
      },
      {
        label: t('summaryRiver'),
        values: [convertAndBeautify(riverEnergyProduction)],
      },
      {
        label: t('summaryDam'),
        values: [convertAndBeautify(damEnergyProduction)],
      },
      {
        label: t('summaryPump'),
        values: [convertAndBeautify(pumpEnergyNettProduction)],
        childRows: [
          {
            label: t('summaryPumpProduction'),
            values: [convertAndBeautify(pumpEnergyProduction)],
          },
          {
            label: t('summaryPumpConsumption'),
            values: [convertAndBeautify(-pumpEnergyConsumption)],
          },
        ],
      },
      {
        label: t('summaryBattery'),
        values: [convertAndBeautify(batteryEnergyNettProduction, 2)],
        childRows: [
          {
            label: t('summaryBatteryProduction'),
            values: [convertAndBeautify(batteryEnergyProduction, 2)],
          },
          {
            label: t('summaryBatteryConsumption'),
            values: [convertAndBeautify(-batteryEnergyConsumption, 2)],
          },
        ],
      },
      {
        label: t('summaryNetImport'),
        values: [convertAndBeautify(nettImportEnergy)],
        childRows: [
          {
            label: t('summaryImport'),
            values: [convertAndBeautify(importEnergy)],
          },
          {
            label: t('summaryExport'),
            values: [convertAndBeautify(-exportEnergy)],
          },
        ],
      },
      {
        label: t('summaryCustomNettProduction'),
        values: [convertAndBeautify(customEnergyNettProduction)],
        childRows: [
          {
            label: t('summaryCustomProduction'),
            values: [convertAndBeautify(customEnergyProduction)],
          },
          {
            label: t('summaryCustomConsumption'),
            values: [convertAndBeautify(-customEnergyConsumption)],
          },
        ],
      },
      {
        label: t('summaryEndUser'),
        values: [convertAndBeautify(-endUserEnergyConsumption)],
      },
      {
        label: t('summaryNetworkLosses'),
        values: [convertAndBeautify(-networkLossEnergyConsumption)],
      },
    ],
    foot: [
      {
        label: t('summaryCalculatedNetExcess'),
        values: [convertAndBeautify(-nettShortageEnergy)],
        childRows: [
          {
            label: t('summaryShortage'),
            values: [convertAndBeautify(-shortageEnergy)],
          },
          {
            label: t('summaryExcess'),
            values: [convertAndBeautify(excessEnergy)],
          },
        ],
      },
    ],
  };

  const storageLevels: TableData = {
    head: [
      {
        headingCells: [
          {
            label: ' ',
            colspan: 1,
          },
          {
            label: ' ',
            colspan: 1,
          },
        ],
        variant: 'heading1',
      },
      {
        headingCells: [
          {
            label: t('summaryStorageLevelsTitle'),
            colspan: 1,
          },
          {
            label: t(displayEnergyUnit.name),
            colspan: 1,
            align: 'right',
          },
        ],
        variant: 'heading2',
      },
    ],
    body: [
      {
        label: t('summaryDam'),
        values: [convertAndBeautify(damUsableStorageLevelEnergyDiff)],
        childRows: [
          {
            label: t('summaryDamInflux'),
            values: [convertAndBeautify(damUsableStorageLevelEnergyIncrease)],
          },
          {
            label: t('summaryDamOutflow'),
            values: [convertAndBeautify(-damUsableStorageLevelEnergyDecrease)],
          },
        ],
      },
      {
        label: t('summaryPump'),
        values: [convertAndBeautify(pumpUsableStorageLevelEnergyDiff)],
        childRows: [
          {
            label: t('summaryPumpInflux'),
            values: [convertAndBeautify(pumpUsableStorageLevelEnergyIncrease)],
          },
          {
            label: t('summaryPumpOutflow'),
            values: [convertAndBeautify(-pumpUsableStorageLevelEnergyDecrease)],
          },
        ],
      },
      {
        label: t('summaryBattery'),
        values: [convertAndBeautify(batteryUsableStorageLevelEnergyDiff, 2)],
        childRows: [
          {
            label: t('summaryBatteryInflux'),
            values: [convertAndBeautify(batteryUsableStorageLevelEnergyIncrease, 2)],
          },
          {
            label: t('summaryBatteryOutflow'),
            values: [convertAndBeautify(-batteryUsableStorageLevelEnergyDecrease, 2)],
          },
        ],
      },
    ],
    foot: [
      {
        label: t('summaryTotal'),
        values: [convertAndBeautify(usableStorageDifference)],
      },
    ],
  };


  const tableDataArray = [
    productionConsumption,
    storageLevels,
  ];

  return (
    <MultiFunctionalTable
      tableClassName={classes.table}
      tableDataArray={tableDataArray}
      extraLeftSpacing={1}
    />
  );
};

const mapToProps = createSelector(
  [mapUnitToProps, mapActiveComputedToPropsFlat],
  (unitState, activeComputedState) => ({
    ...unitState,
    ...activeComputedState,
  }),
);
type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {});

export default connector(OverviewTable);
