import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { MenuItem, TextField, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { mapPreloadDataToProps } from '../../../../../redux/mappers';
import { DataSeriesSection } from '../../../../../domain_model/SourceDataCollection';
import { assertUnreachable } from '../../../../../helper';
import { ArbitrarySection } from '../../../../../data/sharedTypes';

type YearOrSlugSelectSection = 'solar' | Exclude<DataSeriesSection, 'solarDirectA' | 'solarDiffuseA'
| 'solarDirectB' | 'solarDiffuseB' | 'solarDirectC' | 'solarDiffuseC'>;


type Props = PropsFromRedux & {
  section: YearOrSlugSelectSection;
  selected: string;
  onNewSelected: (value: string) => void;
  label?: string;
  tooltip?: string;
}

const useStyles = makeStyles(() => createStyles({
  year: {
    gridArea: 'year',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YearOrSlugSelect = React.forwardRef((props: Props, ref: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    section,
    onNewSelected,
    selected,
    label,
    availableYearsInApi: years,
    arbitraryWithoutData,
    tooltip,
  } = props;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNewSelected(event.target.value);
  };


  const arbitraryTypeToSection = (arbitraryType: ArbitrarySection): YearOrSlugSelectSection => {
    switch (arbitraryType) {
      case ArbitrarySection.endUser:
      case ArbitrarySection.import:
      case ArbitrarySection.export:
      case ArbitrarySection.nuclear:
      case ArbitrarySection.thermal:
      case ArbitrarySection.wind:
      case ArbitrarySection.river:
        return arbitraryType;
      case ArbitrarySection.damInflux:
        return 'dam';
      default:
        assertUnreachable(arbitraryType);
        throw new Error('unknown arbitrary data section type');
    }
  };

  const arbitrarySlugs = arbitraryWithoutData
    .filter((entry) => arbitraryTypeToSection(entry.type) === section)
    .sort((a, b) => a.order - b.order)
    .map((entry) => entry.slug) || [];
  const yearsAndSlugs = years.sort((a, b) => a.localeCompare(b)).concat(arbitrarySlugs);

  return (
    <Tooltip
      placement="right"
      arrow
      enterDelay={1000}
      open={tooltip === undefined ? false : undefined}
      title={tooltip}
    >
      <TextField
        ref={ref}
        select
        label={label || t('paramsSelectDataYear')}
        className={classes.year}
        value={yearsAndSlugs.includes(selected) ? selected : ''}
        onChange={handleInputChange}
        margin="none"
        variant="outlined"
      >
        {yearsAndSlugs.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </TextField>
    </Tooltip>
  );
});

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapPreloadDataToProps, {});

export default connector(YearOrSlugSelect);
