import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { BarDataEntry } from './Bar';

type Props = BarDataEntry;

const useStyles = makeStyles(() => createStyles({
  export: {
    backgroundColor: (props: Props) => props.color,
    flexGrow: (props: Props) => props.value,
  },
}));


function ImportBar(props: Props): JSX.Element {
  const classes = useStyles(props);
  return <div className={classes.export} />;
}

export default ImportBar;
