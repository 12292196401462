import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';

type Label = { label: string };
type LabelAndClassName = { className: string } & Label;
type LabelAndColor = { color: string } & Label;
export type LabelDataEntry = LabelAndClassName | LabelAndColor;

type Props = {
  entry: LabelDataEntry;
}

const useStyles = makeStyles(() => createStyles({
  label: {
    marginRight: '1.6em',
  },
  dot: {
    height: '1em',
    width: '1em',
    backgroundColor: (props: Props) => (props.entry as LabelAndColor).color || `${grey[400]} !important`,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '0.6em',
    verticalAlign: 'text-top',
  },
}));

function ColorLabel(props: Props): JSX.Element {
  const { entry, entry: { label } } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.label}>
      <Typography variant="caption">
        <span className={clsx((entry as LabelAndClassName).className || '', classes.dot)} />
        {label}
      </Typography>
    </div>
  );
}

export default ColorLabel;
