/* eslint-disable camelcase */
import { Trans } from 'react-i18next';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { beautifyNumber } from '../helper';

const useStyles = makeStyles(() => createStyles({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));


/**
 * Zeitlich aufgelöstes Defizit?
 * @param XXX Anzahl Stunden Stromdefizit (Defizit = Import + Mangel)
 * @param YYY max. Defizitleistung
 * @returns XXX = 0: Die Stromnachfrage wird zu jedem Zeitpunkt durch inländische Produktion
 *                   gedeckt;
 *          XXX > 0: Während XXX Stunden herrscht ein Stromdefizit mit einer max. Defizitleistung
 *                   von YYY GW;
 */
export function TimeDeficitText({ XXX, YYY }: { XXX: number, YYY: number }): JSX.Element {
  const classes = useStyles();
  if (XXX === 0) {
    return (
      <Trans i18nKey="trafficLightText_TimeDeficit_NoImport">
        Die Stromnachfrage wird zu jedem Zeitpunkt durch inländische Produktion gedeckt.
      </Trans>
    );
  }
  if (XXX > 0) {
    const importHours = beautifyNumber(XXX, 0, true);
    const deficit = beautifyNumber(YYY, 1, true);
    return (
      <Trans i18nKey="trafficLightText_TimeDeficit_HasImport">
        Während
        <strong className={classes.noWrap}>
          {{ importHours }}
          Stunden
        </strong>
        herrscht ein Stromdefizit mit einer max. Defizitleistung von
        <strong className={classes.noWrap}>
          {{ deficit }}
          GW
        </strong>
      </Trans>
    );
  }
  throw new Error('importDurationH cant be negative');
}


/**
 * Überschuss inländische Produktion (vs. GESAMTE Nachfrage (inkl. Export))
 * @param E_prod_inland Über das Jahr im Inland produzierte Energie
 * @param E_cons_tot Über das Jahr gesamthaft verbraucht Energie (inkl. Export)
 * @param F_Ueberschuss Threshold-Wert für die verschiedenen Anzeigen (ab wann ist Produktion und
 Verbrauch nicht mehr "gleich gross"?) Standardwert = 0.05
 * @returns
  E_prod_inland / E_cons_tot > 1 + F_Ueberschuss:
 Die über das Jahr im Inland produzierte elektrische Energie übersteigt die Nachfrage.:
 E_prod_inland / E_cons_tot < 1 - F_Ueberschuss:
 Die über das Jahr im Inland produzierte elektrische Energie ist kleiner als die Nachfrage.:
 Else:
 Die über das Jahr im Inland produzierte elektrische Energie ist ähnlich gross wie die Nachfrage.:
 */
export function ExcessText(
  {
    E_prod_inland,
    E_cons_tot,
    F_Ueberschuss = 0.05,
  }: { E_prod_inland: number, E_cons_tot: number, F_Ueberschuss?: number },
): JSX.Element {
  if (E_prod_inland / E_cons_tot > 1 + F_Ueberschuss) {
    return (
      <Trans i18nKey="trafficLightText_Excess_HasExcess">
        Die über das Jahr im Inland produzierte elektrische Energie übersteigt die Nachfrage.
      </Trans>
    );
  }
  if (E_prod_inland / E_cons_tot < 1 - F_Ueberschuss) {
    return (
      <Trans i18nKey="trafficLightText_Excess_HasShortage">
        Die über das Jahr im Inland produzierte elektrische Energie ist kleiner als die Nachfrage.
      </Trans>
    );
  }
  return (
    <Trans i18nKey="trafficLightText_Excess_HasNothing">
      Die über das Jahr im Inland produzierte elektrische Energie ist ähnlich gross wie die
      Nachfrage.
    </Trans>
  );
}

/*
Speicherveränderung

Detla_Speicher = (nutzbare!) Speicherveränderung aller Speicher gesamthaft (Stauseen,
                 Pumpspeicherseen, Batterien) über das Jahr (negativ = Speicherstand sinkt).
Cap_Speicher = (nutzbare!) Speicherkapazität aller Speicher zusammen (Stauseen,
               Pumpspeicherseen, Batterien)
F_Speicher = Threshold-Wert für die verschiedenen Anzeigen (ab wann ist der Speicherstand Ende
             Jahr nicht mehr "gleich gross" wie Anfang Jahr?) Standardwert = 0.05

Delta_Speicher > 0 && Delta_Speicher / Cap_Speicher > F_Speicher:
  Gleichzeitig steigt die Menge der in den Speichern vorhandenen Energie.

Delta_Speicher < 0 && Delta_Speicher / Cap_Speicher < - F_Speicher:
  Gleichzeitig sinkt die Menge der in den Speichern vorhandenen Energie. Das Szenario ist nicht
  nachhaltig, weil im Folgejahr mit tieferen Speicherständen gestartet werden würde.

Else:
Gleichzeitig bleibt die Menge der in den Speichern vorhandenen Energie ungefähr konstant.
 */
export function LevelChangesText({
  Delta_Speicher,
  Cap_Speicher,
  F_Speicher = 0.05,
}: { Delta_Speicher: number, Cap_Speicher: number, F_Speicher?: number }): JSX.Element {
  if (Delta_Speicher > 0 && Delta_Speicher / Cap_Speicher > F_Speicher) {
    return (
      <Trans i18nKey="trafficLightText_LevelChanges_HasIncrease">
        Gleichzeitig steigt die Menge der in den Speichern vorhandenen Energie.
      </Trans>
    );
  }
  if (Delta_Speicher < 0 && Delta_Speicher / Cap_Speicher < -F_Speicher) {
    return (
      <Trans i18nKey="trafficLightText_LevelChanges_HasDecrease">
        Gleichzeitig sinkt die Menge der in den Speichern vorhandenen Energie. Das Szenario ist
        nicht nachhaltig, weil im Folgejahr mit tieferen Speicherständen gestartet werden würde.
      </Trans>
    );
  }
  return (
    <Trans i18nKey="trafficLightText_LevelChanges_HasNothing">
      Gleichzeitig bleibt die Menge der in den Speichern vorhandenen Energie ungefähr konstant.
    </Trans>
  );
}


/*
Tipp
E_prod_inland / E_cons_tot < 1 - F_Ueberschuss
&& Delta_Speicher / Cap_Speicher > F_Speicher:
  Um den SVG zu erhöhen, kann versucht werden, mehr Energie aus den Speichern zu entnehmen.

E_prod_inland / E_cons_tot > 1+F_Ueberschuss
&& Delta_Speicher < 0
&& Delta_Speicher / Cap_Speicher < - F_Speicher:
  Um das Szenario aus Sicht der Speicher nachhaltiger zu gestalten, kann versucht werden, weniger
  Energie aus den Speichern zu entnehmen.

Else:
  Nichts anzeigen.
 */
export function TippText(
  {
    E_prod_inland,
    E_cons_tot,
    F_Ueberschuss = 0.05,
    Delta_Speicher,
    Cap_Speicher,
    F_Speicher = 0.05,
  }: {
    E_prod_inland: number,
    E_cons_tot: number,
    F_Ueberschuss?: number,
    Delta_Speicher: number,
    Cap_Speicher: number,
    F_Speicher?: number,
  },
): JSX.Element {
  if (
    E_prod_inland / E_cons_tot < 1 - F_Ueberschuss
    && Delta_Speicher / Cap_Speicher > F_Speicher
  ) {
    return (
      <Trans i18nKey="trafficLightText_Tipp_A">
        Um den SVG zu erhöhen, kann versucht werden, mehr Energie aus den Speichern zu entnehmen.
      </Trans>
    );
  }
  if (
    E_prod_inland / E_cons_tot > 1 + F_Ueberschuss
    && Delta_Speicher < 0
    && Delta_Speicher / Cap_Speicher < -F_Speicher
  ) {
    return (
      <Trans i18nKey="trafficLightText_Tipp_B">
        Um das Szenario aus Sicht der Speicher nachhaltiger zu gestalten, kann versucht werden,
        weniger Energie aus den Speichern zu entnehmen.
      </Trans>
    );
  }
  return <></>;
}
