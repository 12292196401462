import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import YearAndScaling from '../input-combined/YearAndScaling';
import Quantity from '../../../../domain_model/math/Quantity';
import { DB_ENERGY_UNIT } from '../../../../domain_model/math/EnergyUnit';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import EMobility from '../input-combined/EMobility';
import { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const GRAPH_LIMIT = new Quantity(115000, DB_ENERGY_UNIT, false);

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr',
  },
  mobilitySection: {
    display: 'grid',
    gridColumnGap: theme.spacing(2 * PARAMETER_SPACING),
    gridTemplateColumns: '2fr 1.3fr',
    gridTemplateRows: 'auto auto 6px',
    gridTemplateAreas: `"title  title"
                        "text   input"
                        "slider input"`,
  },
  mobilityTitle: {
    gridArea: 'title',
  },
  slider: {
    gridArea: 'slider',
  },
  input: {
    gridArea: 'input',
    alignSelf: 'end',
  },
  text: {
    gridArea: 'text',
    alignSelf: 'center',
    color: grey[700],
  },
}));

const EndUserParams = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const infoButtonProps: ClickableInfoButtonProps = {
    dokuPage: 'user-manual.html',
    paragraph: {
      de: 'stromverbrauch-endverbrauch',
      // TODO: add en doku paragraph
      en: 'stromverbrauch-endverbrauch',
    },
  };

  return (
    <div className={classes.root}>
      <YearAndScaling
        section="endUser"
        scalingLabel={t('paramsYearlyEnergyConsumption')}
        graphLimit={GRAPH_LIMIT}
        originalUnit={DB_ENERGY_UNIT}
        infoButtonProps={infoButtonProps}
        scalingTootltip={t('infoEndUserScaling')}
        yearTooltip={t('infoEndUserYear')}
      />
      <div className={classes.mobilitySection}>
        <Typography className={classes.mobilityTitle} variant="overline">
          {t('paramsEMobilityTitle')}
        </Typography>
        <EMobility
          inputClassName={classes.input}
          sliderClassName={classes.slider}
          textClassName={classes.text}
          tooltip={t('infoEndUserEMobilityScaling')}
        />
      </div>
    </div>
  );
};

export default EndUserParams;
