import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, createStyles, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux';
import clsx from 'clsx';
import ScenarioPreset from './ScenarioPreset';
import { WHITE_BUTTON_THEME } from '../../domain_model/Themes';
import {
  changeCalculationState,
  changeHighlightState,
  setCompareScenarios,
} from '../../redux/actions';
import CalculationState from '../../domain_model/CalculationState';
import {
  mapCalcStateAndParamsOpenToProps,
  mapHighlightCalculateButtonToProps,
  mapParamsToProps,
} from '../../redux/mappers';
import DownloadScenarioButton from './DownloadScenarioButton';
import SaveScenarioButton from './SaveScenarioButton';
import DeleteScenarioButton from './DeleteScenarioButton';
import { IParameterState } from '../../redux/types/parameterTypes';
import { IReduxState } from '../../redux/types';

type Props = PropsFromRedux & {
  onClose: (withCalculation?: boolean) => void;
  className?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => createStyles({
  header: {
    transition: 'height 0.2s linear 0s',
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    height: (props) => (props.paramsOpen ? '11em' : '3em'),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  verticalDivider: {
    width: '1px',
    backgroundColor: 'white',
    height: '3.5em',
    marginLeft: '1em',
    marginRight: '0.5em',
  },
  calculateButton: {
    borderColor: 'rgba(255, 255, 255, 0.7) !important',
    marginRight: theme.spacing(0.5),
  },
  highlightedButton: {
    animation: '$pulse 1.5s infinite',
  },
  '@keyframes pulse': {
    '50%': {
      backgroundColor: 'rgb(255,170,0)',
    },
  },
}));

const RenderDeleteButton = connect(mapParamsToProps, {})(
  (
    { scenarios, activeScenario, className }: IParameterState & { className: string },
  ) => {
    if (activeScenario === null || !scenarios[activeScenario].mutable) {
      return null;
    }
    return (
      <>
        <div className={className} />
        <DeleteScenarioButton />
      </>
    );
  },
);

const DrawerContentHeader = (props: Props) => {
  const {
    changeCalculationState: _changeCalculationState,
    calculationStates: {
      activeScenario: calculationState,
    },
    onClose,
    className,
    highlightCalculateButton,
    changeHighlightState: _changeHighlightState,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  const handleStartCalcClick = () => {
    _changeCalculationState('forActiveScenario', CalculationState.Requested);
    _changeHighlightState({
      highlightCalculateButton: false,
      highlightScenarioSelect: false,
      highlightParameterSections: false,
    });
    onClose(true);
  };

  return (
    <Grid
      container
      className={className ? clsx(className, classes.header) : classes.header}
      direction="column"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <DownloadScenarioButton />
        <SaveScenarioButton />
        <RenderDeleteButton className={classes.verticalDivider} />
        <ScenarioPreset
          className={classes.grow}
        />
      </Grid>
      <Grid container justify="flex-end" className={classes.buttons}>
        <ThemeProvider theme={WHITE_BUTTON_THEME}>
          <Button
            className={clsx(
              classes.calculateButton,
              highlightCalculateButton ? classes.highlightedButton : '',
            )}
            variant="outlined"
            disabled={calculationState !== CalculationState.Idle}
            onClick={handleStartCalcClick}
            href=""
          >
            {t('startCalculation')}
          </Button>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapCalcStateAndParamsOpenToProps(state),
  ...mapHighlightCalculateButtonToProps(state),
});
const connector = connect(mapToProps, {
  changeCalculationState,
  changeHighlightState,
  setCompareScenarios,
});

export default connector(DrawerContentHeader);
