import {
  createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles<Theme, Props>(() => createStyles({
  root: {
    width: (props) => `${props.sliderWidth}px`,
    display: 'grid',
    gridTemplateRows: '1fr auto',
    textAlign: 'center',
  },
}));


type Props = {
  label: string;
  // Bug
  // eslint-disable-next-line react/no-unused-prop-types
  sliderWidth: number;
}

const EqLabel = (props: Props): JSX.Element => {
  const classes = useStyles(props);
  const { label } = props;

  return (
    <div className={classes.root}>
      <Typography variant="caption">
        {label}
      </Typography>
    </div>
  );
};

export default EqLabel;
