import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { PARAMETER_SPACING } from '../../../../domain_model/GlobalStyleConstants';
import NumberInput from '../input-single/inputs/NumberInput';
import SimpleSlider from '../input-single/sliders/SimpleSlider';
import { mapParamsToProps } from '../../../../redux/mappers';
import { changeParams } from '../../../../redux/actions';
import ClickableInfoButton, { ClickableInfoButtonProps } from '../../../shared/ClickableInfoButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    gridArea: 'title',
    marginBottom: theme.spacing(-PARAMETER_SPACING / 3),
  },
  infoButton: {
    gridArea: 'info',
    placeSelf: 'center end',
  },
  powerContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(PARAMETER_SPACING),
    gridColumnGap: theme.spacing(PARAMETER_SPACING),
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"title            info             "
                        "inputPowerInput  outputPowerInput "
                        "inputPowerSlider outputPowerSlider"`,
  },
  inputPowerInput: {
    gridArea: 'inputPowerInput',
  },
  outputPowerInput: {
    gridArea: 'outputPowerInput',
  },
  inputPowerSlider: {
    gridArea: 'inputPowerSlider',
  },
  outputPowerSlider: {
    gridArea: 'outputPowerSlider',
  },
}));

type Props = PropsFromRedux & {
  infoButtonProps?: ClickableInfoButtonProps;
};

const BatteryStoragePower = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { infoButtonProps } = props;
  const {
    changeParams: _changeParams,
    intakePowerInHours,
    outputPowerInHours,
  } = props;

  const max = 10;
  const step = 0.1;
  const stepSlider = 0.1;

  const displayUnitName = t('h');

  const setValue = (name: 'intakePowerInHours' | 'outputPowerInHours', newValue: number) => {
    _changeParams({
      batteryStorage: {
        [name]: newValue,
      },
    });
  };

  return (
    <div className={classes.powerContainer}>
      <Typography className={classes.title} variant="overline">
        {t('inputOutputPowerTitle')}
      </Typography>
      {infoButtonProps && (
        <ClickableInfoButton
          {...infoButtonProps}
          className={classes.infoButton}
        />
      )}
      <NumberInput
        className={classes.inputPowerInput}
        label={t('inputPowerInHours')}
        value={intakePowerInHours}
        onNewValue={(val) => setValue('intakePowerInHours', val)}
        step={step}
        unit={displayUnitName}
      />
      <SimpleSlider
        className={classes.inputPowerSlider}
        value={intakePowerInHours}
        onNewValue={(val) => setValue('intakePowerInHours', val)}
        max={max}
        step={stepSlider}
      />
      <NumberInput
        className={classes.outputPowerInput}
        label={t('outputPowerInHours')}
        value={outputPowerInHours}
        onNewValue={(val) => setValue('outputPowerInHours', val)}
        step={step}
        unit={displayUnitName}
      />
      <SimpleSlider
        className={classes.outputPowerSlider}
        value={outputPowerInHours}
        onNewValue={(val) => setValue('outputPowerInHours', val)}
        max={max}
        step={stepSlider}
      />
    </div>
  );
};

const mapToProps = createSelector(
  [mapParamsToProps],
  (params) => ({
    intakePowerInHours: params.activeParams.batteryStorage.intakePowerInHours,
    outputPowerInHours: params.activeParams.batteryStorage.outputPowerInHours,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, { changeParams });

export default connector(BatteryStoragePower);
