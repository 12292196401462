import React from 'react';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import MultiFunctionalTable, { TableData } from '../shared/MultiFunctionalTable';
import { IComputedData, IComputedSummary } from '../../domain_model/ComputedDataCollection';
import { mapUnitToProps } from '../../redux/mappers';
import EnergyUnit, { DB_ENERGY_UNIT } from '../../domain_model/math/EnergyUnit';
import { NumberConverter } from '../../domain_model/math/Unit';
import { beautifyNumber, calculateYearlyBalanceTotal } from '../helper';

type Props = PropsFromRedux & {
  scenarioA?: IComputedData;
  scenarioB?: IComputedData;
};

const useStyles = makeStyles(() => createStyles({
  table: {
    '& thead': {
      '& tr:first-of-type': {
        '& th:nth-of-type(1)': {
          width: '45%',
        },
        '& th:nth-of-type(2)': {
          width: '20%',
        },
        '& th:nth-of-type(3)': {
          width: '20%',
        },
        '& th:nth-of-type(4)': {
          width: '20%',
        },
      },
      '& tr': {
        '& th:nth-of-type(1)': {
          borderRightStyle: 'solid',
        },
      },
      '& tr:last-of-type': {
        '& th:nth-of-type(1)': {
          borderRightStyle: 'none',
        },
        '& th:nth-of-type(2)': {
          borderRightStyle: 'solid',
        },
      },
    },
    '& tr': {
      '& td:nth-of-type(2)': {
        borderRightStyle: 'solid',
      },
    },
  },
}));

function calcPercentage(a: number, b: number) {
  if (a <= 0 && b > 0) {
    return Number.POSITIVE_INFINITY;
  }
  if (a === 0 && b < 0) {
    return Number.NEGATIVE_INFINITY;
  }
  if (a === 0 && b === 0) {
    return 0;
  }
  if (((b - a) / a) * 100 > 99999) {
    return Number.POSITIVE_INFINITY;
  }
  if (((b - a) / a) * 100 < -99999) {
    return Number.NEGATIVE_INFINITY;
  }
  return ((b - a) / a) * 100;
}

function ComparisonTable(props: Props) {
  const { t } = useTranslation();
  const {
    scenarioA, scenarioB, baseUnit, unitScope,
  } = props;
  const classes = useStyles();

  const displayEnergyUnit = EnergyUnit.createEnergyUnit(unitScope, baseUnit);
  const energyConverter = DB_ENERGY_UNIT.getConverterTo(displayEnergyUnit) as NumberConverter;
  const unitLabel = t(displayEnergyUnit.name);

  const convertAndBeautify = (x: number, fractionDigits = 0) => beautifyNumber(
    energyConverter(x),
    fractionDigits,
  );

  function getValues(
    key: Exclude<keyof IComputedSummary, 'importLevel' | 'storageDifferenceLevel'>,
    negative?: boolean,
    fractionDigits = 0,
  ) {
    const valueA = scenarioA ? scenarioA.computedSummary[key] : 0;
    const valueB = scenarioB ? scenarioB.computedSummary[key] : 0;
    const percentage = calcPercentage(valueA, valueB);
    return scenarioA && scenarioB
      ? [
        convertAndBeautify(negative ? -valueA : valueA, fractionDigits),
        convertAndBeautify(negative ? -valueB : valueB, fractionDigits),
        Number.isNaN(percentage) || percentage === 0
          ? '-'
          : `${beautifyNumber(percentage)}%`,
      ]
      : ['-', '-', '-'];
  }


  const productionConsumption: TableData = {
    head: [
      {
        headingCells: [
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
        ],
        variant: 'invisible',
      },
      {
        headingCells: [
          {
            label: scenarioA ? scenarioA.scenarioInfo.scenarioName.getString(t) : '',
            colspan: 2,
            align: 'right',
          },
          {
            label: scenarioB ? scenarioB.scenarioInfo.scenarioName.getString(t) : '',
            colspan: 2,
            align: 'right',
          },
        ],
        variant: 'heading1',
      },
      {
        headingCells: [
          {
            label: t('summaryAnnualBalance'),
            colspan: 1,
          },
          {
            label: unitLabel,
            colspan: 1,
            align: 'right',
          },
          {
            label: unitLabel,
            colspan: 1,
            align: 'right',
          },
          {
            label: t('summaryDifference'),
            colspan: 1,
            align: 'right',
          },
        ],
        variant: 'heading2',
      },
    ],
    body: [
      {
        label: t('summaryNuclear'),
        values: getValues('nuclearEnergyProduction'),
      },
      {
        label: t('summaryThermal'),
        values: getValues('thermalEnergyProduction'),
      },
      {
        label: t('summarySolar'),
        values: getValues('solarEnergyProduction'),
      },
      {
        label: t('summaryWind'),
        values: getValues('windEnergyProduction'),
      },
      {
        label: t('summaryRiver'),
        values: getValues('riverEnergyProduction'),
      },
      {
        label: t('summaryDam'),
        values: getValues('damEnergyProduction'),
      },
      {
        label: t('summaryPump'),
        values: getValues('pumpEnergyNettProduction'),
        childRows: [
          {
            label: t('summaryPumpProduction'),
            values: getValues('pumpEnergyProduction'),
          },
          {
            label: t('summaryPumpConsumption'),
            values: getValues('pumpEnergyConsumption', true),
          },
        ],
      },
      {
        label: t('summaryBattery'),
        values: getValues('batteryEnergyNettProduction', false, 2),
        childRows: [
          {
            label: t('summaryBatteryProduction'),
            values: getValues('batteryEnergyProduction', false, 2),
          },
          {
            label: t('summaryBatteryConsumption'),
            values: getValues('batteryEnergyConsumption', true, 2),
          },
        ],
      },
      {
        label: t('summaryNetImport'),
        values: getValues('nettImportEnergy'),
        childRows: [
          {
            label: t('summaryImport'),
            values: getValues('importEnergy'),
          },
          {
            label: t('summaryExport'),
            values: getValues('exportEnergy', true),
          },
        ],
      },
      {
        label: t('summaryCalculatedNetExcess'),
        values: getValues('nettShortageEnergy', true),
        childRows: [
          {
            label: t('summaryShortage'),
            values: getValues('shortageEnergy', true),
          },
          {
            label: t('summaryExcess'),
            values: getValues('excessEnergy'),
          },
        ],
      },
      {
        label: t('summaryCustomNettProduction'),
        values: getValues('customEnergyNettProduction'),
        childRows: [
          {
            label: t('summaryCustomProduction'),
            values: getValues('customEnergyProduction'),
          },
          {
            label: t('summaryCustomConsumption'),
            values: getValues('customEnergyConsumption', true),
          },
        ],
      },
      {
        label: t('summaryEndUser'),
        values: getValues('endUserEnergyConsumption', true),
      },
      {
        label: t('summaryNetworkLosses'),
        values: getValues('networkLossEnergyConsumption', true),
      },
    ],
    foot: [
      {
        label: t('summaryTotal'),
        values: [
          scenarioA ? convertAndBeautify(calculateYearlyBalanceTotal(scenarioA.computedSummary)) : '-',
          scenarioB ? convertAndBeautify(calculateYearlyBalanceTotal(scenarioB.computedSummary)) : '-',
          '',
        ],
      },
    ],
  };

  const storage: TableData = {
    head: [
      {
        headingCells: [
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
          {
            label: '',
            colspan: 1,
          },
        ],
        variant: 'invisible',
      },
      {
        headingCells: [
          {
            label: ' ',
            colspan: 2,
          },
          {
            label: ' ',
            colspan: 2,
          },
        ],
        variant: 'heading1',
      },
      {
        headingCells: [
          {
            label: t('summaryStorageLevelsTitle'),
            colspan: 1,
          },
          {
            label: unitLabel,
            colspan: 1,
            align: 'right',
          },
          {
            label: unitLabel,
            colspan: 1,
            align: 'right',
          },
          {
            label: t('summaryDifference'),
            colspan: 1,
            align: 'right',
          },
        ],
        variant: 'heading2',
      },
    ],
    body: [
      {
        label: t('summaryDam'),
        values: getValues('damUsableStorageLevelEnergyDiff', false),
        childRows: [
          {
            label: t('summaryDamInflux'),
            values: getValues('damUsableStorageLevelEnergyIncrease', false),
          },
          {
            label: t('summaryDamOutflow'),
            values: getValues('damUsableStorageLevelEnergyDecrease', true),
          },
        ],
      },
      {
        label: t('summaryPump'),
        values: getValues('pumpUsableStorageLevelEnergyDiff', false),
        childRows: [
          {
            label: t('summaryPumpInflux'),
            values: getValues('pumpUsableStorageLevelEnergyIncrease', false),
          },
          {
            label: t('summaryPumpOutflow'),
            values: getValues('pumpUsableStorageLevelEnergyDecrease', true),
          },
        ],
      },
      {
        label: t('summaryBattery'),
        values: getValues('batteryUsableStorageLevelEnergyDiff', false, 2),
        childRows: [
          {
            label: t('summaryBatteryInflux'),
            values: getValues('batteryUsableStorageLevelEnergyIncrease', false, 2),
          },
          {
            label: t('summaryBatteryOutflow'),
            values: getValues('batteryUsableStorageLevelEnergyDecrease', true, 2),
          },
        ],
      },
    ],
    foot: [
      {
        label: t('summaryTotal'),
        values: getValues('usableStorageDifference', false),
      },
    ],
  };

  const tableDataArray = [
    productionConsumption,
    storage,
  ];

  return <MultiFunctionalTable tableClassName={classes.table} tableDataArray={tableDataArray} />;
}

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitToProps);

export default connector(ComparisonTable);
