import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { makeStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { PRIMARY } from '../domain_model/Colors';
import { mapUiStateToProps } from '../redux/mappers';

type Props = PropsFromRedux;

const useStyles = makeStyles(() => createStyles({
  root: {
    backgroundColor: PRIMARY,
    borderRadius: '4xp',
    fontSize: '1.1rem',
    opacity: 0.8,
    pointerEvents: 'none',
  },
}));

const ConnectionErrorLoopNotification = (props: Props) => {
  const {
    hasErrorLoops,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Snackbar
      className={classes.root}
      open={hasErrorLoops}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent className={classes.root} message={t('connectionLostReconnecting')} />
    </Snackbar>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = createSelector(
  [mapUiStateToProps],
  (uiState) => ({
    hasErrorLoops: uiState.connectionsInErrorLoop !== 0,
  }),
);
const connector = connect(mapToProps, {});

export default connector(ConnectionErrorLoopNotification);
