import React from 'react';
import {
  Button, createStyles, makeStyles, Paper, SvgIcon, Theme,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import NuclearIcon from '@material-ui/icons/Adjust';
import EndUserIcon from '@material-ui/icons/LocationCity';
import ThermalIcon from '@material-ui/icons/Whatshot';
import SolarIcon from '@material-ui/icons/BrightnessLow';
import WindIcon from '@material-ui/icons/Toys';
import BatteryIcon from '@material-ui/icons/BatteryChargingFull';
import CustomIcon from '@material-ui/icons/PostAdd';
import AdvancedIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import { changeExpandedSection, changeParamSettingsOpen } from '../redux/actions';
import CustomImportExportIconSvg from '../icons/ImportExport';
import CustomRiverIconSvg from '../icons/River';
import CustomDamIconSvg from '../icons/Dam';
import CustomPumpIconSvg from '../icons/Pump';
import { DrawerSection } from '../redux/types/uiTypes';

type Props = PropsFromRedux;

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    position: 'fixed',
    padding: '0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '4em',
    overflow: 'hidden',
  },
  button: {
    minWidth: '4em',
    padding: 0,
    borderRadius: 0,
  },
  menuButton: {
    minWidth: '4em',
    minHeight: '4em',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  sectionButton: {
    minHeight: '3.6em',
    color: theme.palette.grey[700],
  },
  icon: {
    width: '1em',
  },
}));

const ClosedScenarioDrawer = (props: Props) => {
  const { changeExpandedSection: setExtended, changeParamSettingsOpen: setDrawerOpen } = props;
  const classes = useStyles();

  const generateSectionButton = (section: DrawerSection, icon: React.ReactNode) => (
    <Button
      onClick={() => {
        setExtended(section);
        setDrawerOpen(true);
      }}
      className={clsx(classes.button, classes.sectionButton)}
      focusRipple
    >
      {icon}
    </Button>
  );

  return (
    <Paper
      className={classes.paper}
      square
    >
      <Button
        onClick={() => {
          setExtended(false);
          setDrawerOpen(true);
        }}
        className={clsx(classes.button, classes.menuButton)}
        color="primary"
      >
        <MenuIcon className={classes.icon} />
      </Button>

      {generateSectionButton('endUser', <EndUserIcon className={classes.icon} />)}
      {generateSectionButton('nuclear', <NuclearIcon className={classes.icon} />)}
      {generateSectionButton('thermal', <ThermalIcon className={classes.icon} />)}
      {generateSectionButton('solar', <SolarIcon className={classes.icon} />)}
      {generateSectionButton('wind', <WindIcon className={classes.icon} />)}
      {generateSectionButton('river', (
        <SvgIcon className={classes.icon}>
          <CustomRiverIconSvg />
        </SvgIcon>))}
      {generateSectionButton('dam', (
        <SvgIcon className={classes.icon}>
          <CustomDamIconSvg />
        </SvgIcon>))}
      {generateSectionButton('pump', (
        <SvgIcon className={classes.icon}>
          <CustomPumpIconSvg />
        </SvgIcon>))}
      {generateSectionButton('battery', <BatteryIcon className={classes.icon} />)}
      {generateSectionButton('importExport', (
        <SvgIcon className={classes.icon}>
          <CustomImportExportIconSvg />
        </SvgIcon>))}
      {generateSectionButton('custom', <CustomIcon className={classes.icon} />)}
      {generateSectionButton('advanced', <AdvancedIcon className={classes.icon} />)}
    </Paper>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(null, { changeParamSettingsOpen, changeExpandedSection });

export default connector(ClosedScenarioDrawer);
