import React from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import NuclearIcon from '@material-ui/icons/Adjust';
import EndUserIcon from '@material-ui/icons/LocationCity';
import ThermalIcon from '@material-ui/icons/Whatshot';
import SolarIcon from '@material-ui/icons/BrightnessLow';
import WindIcon from '@material-ui/icons/Toys';
import BatteryIcon from '@material-ui/icons/BatteryChargingFull';
import CustomIcon from '@material-ui/icons/PostAdd';
import AdvancedIcon from '@material-ui/icons/Settings';
import { connect, ConnectedProps } from 'react-redux';
import { SvgIcon } from '@material-ui/core';
import CustomRiverIcon from '../../../icons/River';
import CustomDamIcon from '../../../icons/Dam';
import CustomPumpIcon from '../../../icons/Pump';
import NuclearParams from './sections/NuclearParams';
import Panel from './Panel';
import EndUserParams from './sections/EndUserParams';
import ThermalParams from './sections/ThermalParams';
import SolarParams from './sections/SolarParams';
import WindParams from './sections/WindParams';
import RiverParams from './sections/RiverParams';
import DamParams from './sections/DamParams';
import ActiveStorage from './sections/ActiveStorage';
import { customIconStyles } from '../../../domain_model/GlobalStyleConstants';
import Custom from './sections/Custom';
import {
  mapExtendedSectionToProps,
  mapHighlightParameterSectionsToProps,
} from '../../../redux/mappers';
import { changeExpandedSection } from '../../../redux/actions';
import AdvancedParams from './sections/AdvancedParams';
import { IReduxState } from '../../../redux/types';
import ImportExportParams from './sections/ImportExportParams';
import CustomImportExportIconSvg from '../../../icons/ImportExport';

type Props = PropsFromRedux;

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
  },
}));

const DamIcon = styled(CustomDamIcon)(customIconStyles);
const RiverIcon = styled(CustomRiverIcon)(customIconStyles);
const PumpIcon = styled(CustomPumpIcon)(customIconStyles);

const ExpansionPanels = (props: Props) => {
  const {
    expandedSection: expanded,
    changeExpandedSection: setExpanded,
    highlightParameterSections: highlighted,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Panel
        id="endUser"
        title={t('totalEnergyUsage')}
        icon={<EndUserIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <EndUserParams />
      </Panel>


      <Panel
        id="nuclear"
        title={t('nuclearEnergy')}
        icon={<NuclearIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <NuclearParams />
      </Panel>

      <Panel
        id="thermal"
        title={t('thermalEnergy')}
        icon={<ThermalIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <ThermalParams />
      </Panel>

      <Panel
        id="solar"
        title={t('solarEnergy')}
        icon={<SolarIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <SolarParams />
      </Panel>

      <Panel
        id="wind"
        title={t('windEnergy')}
        icon={<WindIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <WindParams />
      </Panel>

      <Panel
        id="river"
        title={t('riverEnergy')}
        icon={<RiverIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <RiverParams />
      </Panel>

      <Panel
        id="dam"
        title={t('damEnergy')}
        icon={<DamIcon width="24px" height="24px" />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <DamParams />
      </Panel>

      <Panel
        id="pump"
        title={t('pumpEnergy')}
        icon={<PumpIcon width="24px" height="24px" />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <ActiveStorage section="pumpStorage" />
      </Panel>

      <Panel
        id="battery"
        title={t('batteryEnergy')}
        icon={<BatteryIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <ActiveStorage section="batteryStorage" />
      </Panel>

      <Panel
        id="importExport"
        title={t('importExport')}
        icon={(
          <SvgIcon>
            <CustomImportExportIconSvg />
          </SvgIcon>
        )}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <ImportExportParams />
      </Panel>

      <Panel
        id="custom"
        title={t('customData')}
        icon={<CustomIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <Custom />
      </Panel>

      <Panel
        id="advanced"
        title={t('advancedSettings')}
        icon={<AdvancedIcon />}
        expanded={expanded}
        setExpanded={setExpanded}
        highlighted={highlighted}
      >
        <AdvancedParams />
      </Panel>

    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapHighlightParameterSectionsToProps(state),
  ...mapExtendedSectionToProps(state),
});
const connector = connect(mapToProps, {
  changeExpandedSection,
});

export default connector(ExpansionPanels);
