import { Decimal } from 'decimal.js';
import { BaseUnits, POTENCIES, UnitScopes } from './constants';
import { Potency } from './types';
import { NumberConverter, Unit } from './Unit';

export default class EnergyUnit extends Unit {
  public base: BaseUnits;
  private static potencyFactor = 1000;

  public constructor(baseName: string, scope: UnitScopes, base: BaseUnits, potency: Potency) {
    super(baseName, scope, potency, EnergyUnit.potencyFactor);
    this.base = base;
  }

  public static createEnergyUnit(
    scope: UnitScopes, base: BaseUnits, customPotency?: Potency,
  ): EnergyUnit {
    let baseName;
    let defaultPotency;
    switch (base) {
      case BaseUnits.WATT_HOUR:
        switch (scope) {
          case UnitScopes.SWITZERLAND:
            baseName = 'Wh';
            defaultPotency = POTENCIES.GIGA;
            break;
          case UnitScopes.PERSON:
            baseName = 'Wh/P';
            defaultPotency = POTENCIES.KILO;
            break;
          default:
            throw new Error(`Unknown UnitScope: ${scope}`);
        }
        break;

      case BaseUnits.JOULE:
        switch (scope) {
          case UnitScopes.SWITZERLAND:
            baseName = 'J';
            defaultPotency = POTENCIES.TERRA;
            break;
          case UnitScopes.PERSON:
            baseName = 'J/P';
            defaultPotency = POTENCIES.MEGA;
            break;
          default:
            throw new Error(`Unknown UnitScope: ${scope}`);
        }
        break;

      default:
        throw new Error(`Unknown BaseUnit: ${base}`);
    }
    const potency = customPotency || defaultPotency;
    return new EnergyUnit(baseName, scope, base, potency);
  }

  // Caution, floatErrorCorrection is expensive, set keepFloatErrors=true for better performance
  public getConverterTo(otherUnit: Unit, keepFloatErrors?: boolean): false | NumberConverter {
    if (!(otherUnit instanceof EnergyUnit)) {
      return false;
    }
    const potencyConversionFactor = EnergyUnit.potencyFactor
      ** (this.potency.hierarchy - otherUnit.potency.hierarchy);

    if (keepFloatErrors) {
      const scopeConversionFactor = otherUnit.scope / this.scope;
      const baseConversionFactor = otherUnit.base / this.base;
      const factor = scopeConversionFactor * baseConversionFactor * potencyConversionFactor;
      return (x: number) => x * factor;
    }

    const scopeConversionFactor = (new Decimal(otherUnit.scope)).dividedBy(new Decimal(this.scope));
    const baseConversionFactor = (new Decimal(otherUnit.base)).dividedBy(new Decimal(this.base));
    const factor = scopeConversionFactor.times(baseConversionFactor).times(potencyConversionFactor);

    return (x: number) => factor.times(x).toNumber();
  }

  protected clone(): EnergyUnit {
    return new EnergyUnit(this.baseName, this.scope, this.base, this.potency);
  }
}

export const DB_ENERGY_UNIT = EnergyUnit.createEnergyUnit(
  UnitScopes.SWITZERLAND, BaseUnits.WATT_HOUR, POTENCIES.GIGA,
);
