import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WHITE_BUTTON_THEME } from '../../domain_model/Themes';
import { mapParamsToProps } from '../../redux/mappers';
import DownloadScenarioDialog from './DownloadScenarioDialog';

type Props = PropsFromRedux;

const DownloadScenarioButton = (props: Props) => {
  const { scenarios, activeScenario } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [input, setInput] = useState('');
  const { t } = useTranslation();

  const getDefaultName = () => (
    (activeScenario !== null)
      ? scenarios[activeScenario].name.getString(t)
      : 'CustomSceanrio'
  );

  const handleClick = () => {
    setDialogOpen(true);
    setInput(getDefaultName());
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <DownloadScenarioDialog
        dialogOpen={dialogOpen}
        onClose={handleClose}
        input={input}
        setInput={setInput}
      />
      <ThemeProvider theme={WHITE_BUTTON_THEME}>
        <IconButton onClick={handleClick} aria-label="Download" href="">
          <DownloadIcon />
        </IconButton>
      </ThemeProvider>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, {});

export default connector(DownloadScenarioButton);
