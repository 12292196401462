import React from 'react';
import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import {
  SliderItem,
  GetTrackProps,
} from 'react-compound-slider';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    transform: 'translate(0%, -50%)',
    height: 2,
    zIndex: 1,
    borderRadius: 1,
    cursor: 'pointer',
  },
  faded: {
    backgroundColor: theme.palette.primary.light,
  },
  fullColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  faded?: boolean;
}

const Track = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    source, target, getTrackProps, faded,
  } = props;

  const colorClass = faded ? classes.faded : classes.fullColor;

  return (
    <div
      className={clsx(classes.root, colorClass)}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};

export default Track;
