import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleSelect from './simpleSelect';
import LoadingScreen from '../shared/LoadingScreen';
import { sleep } from '../../helper';

export default function LanguageSwitch(): JSX.Element {
  const { i18n, t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (newValue: unknown) => {
    if (typeof newValue === 'string') {
      setLoading(true);
      sleep(100).then(() => {
        i18n.changeLanguage(newValue).then(() => {
          setLoading(false);
        });
      });
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <SimpleSelect onChange={onChange} value={i18n.languages[0]} name={t('language')}>
        <MenuItem value="de">Deutsch</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </SimpleSelect>
    </>
  );
}
