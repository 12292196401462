// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import Worker from 'worker-loader!../PreCalculationWorker';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { changeActivePreComputedData, changeCalculationState } from '../redux/actions';
import CalculationState from '../domain_model/CalculationState';
import { PreCalculationInputDTO, PreCalculationOutputDTO } from '../PreCalculationWorker';
import { DataSeriesSection, DataStatus } from '../domain_model/SourceDataCollection';
import { SolarLoadPreScalingData } from '../domain_model/ComputedDataCollection';
import { generateHash } from './helper';
import { mapControllerStateToProps } from '../redux/mappers';

type Props = PropsFromRedux
type State = {
  currentWorkerHash: string;
  worker?: Worker;
  startTime?: number;
}

const PreCalculationActiveController = (props: Props) => {
  const {
    data,
    calculationStates: {
      solarLoadActivePreCalculation: calculationState,
    },
    preComputedData: {
      solar: {
        active: {
          isUpToDate,
        },
        cache: preCompSolarCache,
      },
    },
    preloadSecondaryData: {
      thetaA,
      thetaE,
      thetaZ,
    },
    activeParams: {
      solar: {
        yearOrSlug,
        azimuth,
        tilt,
        version,
      },
    },
    changeCalculationState: _changeCalculationState,
    changePreComputedData: _changePreComputedData,

  } = props;


  const [state, setState] = useState<State>({ currentWorkerHash: '' });

  function generateHashForPreviousData() {
    return generateHash({
      yearOrSlug,
      azimuth,
      tilt,
      version,
    });
  }

  function calculateWithWorker() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newWorker = new Worker();
    newWorker.addEventListener('message', handleWorkerResult);
    const inputHash = generateHashForPreviousData();
    let directSection: DataSeriesSection;
    let diffuseSection: DataSeriesSection;
    switch (version) {
      case 'EqualWeighted':
        directSection = 'solarDirectA';
        diffuseSection = 'solarDiffuseA';
        break;
      case 'PopulationWeighted':
        directSection = 'solarDirectB';
        diffuseSection = 'solarDiffuseB';
        break;
      case 'SouthWeighted':
        directSection = 'solarDirectC';
        diffuseSection = 'solarDiffuseC';
        break;
      default:
        throw new Error('unknown solar weight version');
    }
    const dto: PreCalculationInputDTO = {
      solarDirect: data.getOneYearDataSeries(directSection, yearOrSlug),
      solarDiffuse: data.getOneYearDataSeries(diffuseSection, yearOrSlug),
      thetaA,
      thetaE,
      thetaZ,
      tilt,
      azimuth,
      inputHash,
    };
    newWorker.postMessage(dto);
    setState({ currentWorkerHash: inputHash, worker: newWorker });
  }

  function handleWorkerResult(event: { data: PreCalculationOutputDTO }) {
    setState({ ...state, worker: undefined, currentWorkerHash: '' });
    updateData(
      event.data.inputHash,
      {
        highRes: event.data.output.highRes,
        lowRes: event.data.output.lowRes,
        sum: event.data.output.sum,
        max: event.data.output.max,
      },
    );
  }

  function updateData(hash: string, newData: SolarLoadPreScalingData) {
    if (hash !== generateHashForPreviousData()) {
      _changeCalculationState('forSolarLoadPreCalc', CalculationState.Requested);
      return;
    }
    _changePreComputedData(newData, hash);
    _changeCalculationState('forSolarLoadPreCalc', CalculationState.Idle);
  }

  function stopCurrentWorker() {
    const { worker } = state;
    if (worker !== undefined) {
      setState({ ...state, worker: undefined, currentWorkerHash: '' });
      worker.terminate();
    }
  }

  function IsDataAvailable() {
    const { Available } = DataStatus;
    return (
      data.getOneYearDataSeriesStatus('solarDiffuseA', yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectA', yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDiffuseB', yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectB', yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDiffuseC', yearOrSlug) === Available
      && data.getOneYearDataSeriesStatus('solarDirectC', yearOrSlug) === Available
      && thetaA.length > 0
      && thetaE.length > 0
      && thetaZ.length > 0
      && tilt.length > 0
      && azimuth.length > 0
    );
  }

  const isWorkerAlreadyRunning = () => state.currentWorkerHash === generateHashForPreviousData();

  const getFromCache = (hash: string) => preCompSolarCache.find(
    (cashedEntry) => cashedEntry.hash === hash,
  );


  useEffect(() => {
    if (!IsDataAvailable()) return;

    if (isUpToDate) {
      stopCurrentWorker();
      if (calculationState !== CalculationState.Idle) {
        _changeCalculationState('forSolarLoadPreCalc', CalculationState.Idle);
      }
      return;
    }
    if (isWorkerAlreadyRunning()) {
      return;
    }
    const hash = generateHashForPreviousData();
    const cacheEntry = getFromCache(hash);
    if (cacheEntry !== undefined) {
      stopCurrentWorker();
      updateData(cacheEntry.hash, cacheEntry.data);
    } else {
      _changeCalculationState('forSolarLoadPreCalc', CalculationState.InProgress);
      stopCurrentWorker();
      calculateWithWorker();
    }
  });

  return null;
};


type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapControllerStateToProps, {
  changeCalculationState,
  changePreComputedData: changeActivePreComputedData,
});

export default connector(PreCalculationActiveController);
