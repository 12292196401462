import React from 'react';
import {
  createStyles, InputAdornment, makeStyles, TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  alignRight: {
    textAlign: 'end',
  },
}));

type Props = {
  className?: string;
  value: number;
  onNewValue: (value: number) => void;
  max?: number;
  unit?: string;
  label?: string;
  step: number;
  alignRight?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberInput = React.forwardRef((props: Props, ref: any) => {
  const {
    className, value, max, onNewValue, unit, label, step, alignRight, ...other
  } = props;

  const classes = useStyles();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNewValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      onNewValue(0);
    } else if (max && value > max) {
      onNewValue(max);
    }
  };

  const unitAdornment = unit
    ? <InputAdornment position="end">{unit}</InputAdornment>
    : null;

  return (
    <TextField
      {...other}
      ref={ref}
      className={className}
      label={label}
      value={value}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: unitAdornment,
        classes: alignRight ? { input: classes.alignRight } : {},
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        min: 0,
        step,
        max,
        type: 'number',
      }}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      onBlur={handleBlur}
    />
  );
});


export default NumberInput;
