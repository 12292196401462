import _ from 'lodash';
import {
  ActionTypes,


} from '../types';
import getPredefinedScenarios from '../../predefinedScenarios';
import {
  ADD_SCENARIO,
  CHANGE_PARAMS, DELETE_SCENARIO,
  IParameterState,
  REMOVE_CUSTOM_DATA,
  SET_ACTIVE_SCENARIO, UPDATE_SCENARIO,
} from '../types/parameterTypes';

const initialState: IParameterState = {
  activeParams: getPredefinedScenarios()[0].parameters,
  activeScenario: null,
  scenarios: [],
};


export default parameter;

function parameter(state = initialState, action: ActionTypes): IParameterState {
  switch (action.type) {
    case CHANGE_PARAMS: {
      return ({
        ...state,
        activeParams: _.merge(_.clone(state.activeParams), action.payload.params),
        activeScenario: null,
      });
    }
    case REMOVE_CUSTOM_DATA: {
      const activeParams = _.clone(state.activeParams);
      activeParams[action.payload.section] = {
        scaling: 0,
        dataSeries: [],
      };
      return ({
        ...state,
        activeParams,
        activeScenario: null,
      });
    }
    case SET_ACTIVE_SCENARIO: {
      return {
        ...state,
        activeScenario: action.payload.scenarioIndex,
        activeParams: _.cloneDeep(state.scenarios[action.payload.scenarioIndex].parameters),
      };
    }
    case ADD_SCENARIO: {
      if (action.payload.setActive) {
        return {
          ...state,
          scenarios: [...state.scenarios, _.clone(action.payload.scenario)],
          activeScenario: state.scenarios.length,
          activeParams: _.cloneDeep(action.payload.scenario.parameters),
        };
      }
      return {
        ...state,
        scenarios: [...state.scenarios, _.clone(action.payload.scenario)],
      };
    }
    case UPDATE_SCENARIO: {
      const scenarios = _.clone(state.scenarios);
      if (action.payload.setActive) {
        scenarios[action.payload.scenarioIndex] = action.payload.scenario;
        return {
          ...state,
          scenarios,
          activeScenario: action.payload.scenarioIndex,
          activeParams: _.cloneDeep(scenarios[action.payload.scenarioIndex].parameters),
        };
      }
      return {
        ...state,
        scenarios: [...state.scenarios, _.clone(action.payload.scenario)],
      };
    }
    case DELETE_SCENARIO: {
      const scenarios = _.clone(state.scenarios);
      scenarios.splice(action.payload.scenarioIndex, 1);
      return {
        ...state,
        activeScenario: null,
        scenarios,
      };
    }
    default:
      return state;
  }
}
