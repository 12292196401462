export default class ScreenSize {
  public name: string;
  public minWidth: number;
  public nextSize?: ScreenSize;

  public constructor(name: string, minWidth: number, nextSize?: ScreenSize) {
    this.name = name;
    this.minWidth = minWidth;
    this.nextSize = nextSize;
  }

  public getMinWidth(): number {
    return this.minWidth;
  }

  public getMaxWidth(): number {
    return this.nextSize ? this.nextSize.getMinWidth() - 1 : Number.POSITIVE_INFINITY;
  }

  public isSize(width: number): boolean {
    return width >= this.getMinWidth() && width <= this.getMaxWidth();
  }

  public isLessOrEqualTo(size?: ScreenSize): boolean {
    return !!size && this.getMinWidth() <= size.getMinWidth();
  }

  public isGreaterOrEqualTo(size?: ScreenSize): boolean {
    return !!size && this.getMinWidth() >= size.getMinWidth();
  }

  public isEqualTo(size?: ScreenSize): boolean {
    return !!size && this.name === size.name;
  }
}
