import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Chart } from 'highcharts';
import * as _ from 'lodash';
import ChartComponent, { Extremes, PlotDataSeries } from './ChartComponent';
import { mapUnitScopeToProps } from '../../redux/mappers';
import { NumberConverter } from '../../domain_model/math/Unit';
import PowerUnit, { DB_POWER_UNIT } from '../../domain_model/math/PowerUnit';
import { BaseUnits, POTENCIES, UnitScopes } from '../../domain_model/math/constants';
import { Potency } from '../../domain_model/math/types';
import { toEnergy } from '../../domain_model/math/helpers';
import EnergyUnit, { DB_ENERGY_UNIT } from '../../domain_model/math/EnergyUnit';

type Props = PropsFromRedux & {
  plotData: PlotDataSeries[];
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
  stack?: boolean;
  centerZero?: boolean;
  noOneBarResolution?: boolean;
  type: 'power' | 'energy';
  oneBar?: boolean;
};

function getPowerPotency(unitScope: UnitScopes) {
  let potency: Potency;
  switch (unitScope) {
    case UnitScopes.PERSON:
      potency = POTENCIES.NONE;
      break;
    case UnitScopes.SWITZERLAND:
      potency = POTENCIES.GIGA;
      break;
    default:
      throw new Error('unknown unitScope');
  }
  return potency;
}

function getEnergyPotency(unitScope: UnitScopes) {
  let potency: Potency;
  switch (unitScope) {
    case UnitScopes.PERSON:
      potency = POTENCIES.KILO;
      break;
    case UnitScopes.SWITZERLAND:
      potency = POTENCIES.GIGA;
      break;
    default:
      throw new Error('unknown unitScope');
  }
  return potency;
}

const PowerOrEnergyChart = (props: Props) => {
  const {
    plotData,
    unitScope,
    chartCallback,
    onNewExtremes,
    onMouseMove,
    onMouseLeave,
    stack,
    centerZero,
    type,
    noOneBarResolution,
    oneBar,
  } = props;
  const { t } = useTranslation();

  const potency = type === 'power' ? getPowerPotency(unitScope) : getEnergyPotency(unitScope);

  const displayUnit = useMemo(() => (
    type === 'power'
      ? PowerUnit.createPowerUnit(unitScope, potency)
      : EnergyUnit.createEnergyUnit(unitScope, BaseUnits.WATT_HOUR, potency)
  ), [unitScope, potency, type]);

  const unitConverter = useMemo(() => (
    type === 'power'
      ? DB_POWER_UNIT.getConverterTo(displayUnit) as NumberConverter
      : DB_ENERGY_UNIT.getConverterTo(displayUnit) as NumberConverter
  ), [displayUnit, type]);

  const unitName = t(displayUnit.name);

  const unitSpecificPlotData = useMemo(() => (
    oneBar
      ? plotData.map((series): PlotDataSeries => (
        {
          color: series.color,
          name: series.name,
          type: series.type,
          // @ts-ignore
          data: series.type === 'line'
            ? [{ target: unitConverter(toEnergy(_.mean(series.data), 365 * 24)), y: 0 }]
            : [unitConverter(toEnergy(_.mean(series.data), 365 * 24))],
        }
      ))
      : plotData.map((series) => (
        {
          ...series,
          data: series.data.map(unitConverter),
        }
      ))
  ), [plotData, unitConverter, oneBar]);

  return (
    <ChartComponent
      chartCallback={chartCallback}
      plotData={unitSpecificPlotData}
      unit={unitName}
      onNewExtremes={onNewExtremes}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      stack={stack}
      centerZero={centerZero}
      noOneBarResolution={noOneBarResolution}
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapUnitScopeToProps, {});

export default connector(PowerOrEnergyChart);
