import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CompareScenarioChooseDialog from './CompareScenarioChooseDialog';

type Props = {
  className?: string;
}

const CompareButton = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    className,
  } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleOpen() {
    setDialogOpen(true);
  }

  function handleClose() {
    setDialogOpen(false);
  }

  return (
    <div className={className}>
      <Button
        aria-haspopup="true"
        onClick={handleOpen}
        variant="outlined"
        color="primary"
      >
        {t('compare')}
      </Button>

      <CompareScenarioChooseDialog
        open={dialogOpen}
        onClose={handleClose}
      />
    </div>
  );
};

export default CompareButton;
