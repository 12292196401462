import { POTENCIES_ARRAY, UnitScopes } from './constants';
import { Potency } from './types';

export type NumberConverter = (x: number) => number;

export interface INumberConverterTwoWay {
  toOther: NumberConverter;
  toThis: NumberConverter;
}


export abstract class Unit {
  protected scope: UnitScopes;
  protected readonly baseName: string;
  public potency: Potency;
  protected potencyFactor: number;

  public abstract getConverterTo(otherUnit: Unit): false | NumberConverter;

  public getConvertersTwoWay(otherUnit: Unit): false | INumberConverterTwoWay {
    const toOther = this.getConverterTo(otherUnit);
    const toThis = otherUnit.getConverterTo(this);
    if (toOther && toThis) {
      return {
        toThis,
        toOther,
      };
    }
    return false;
  }

  protected constructor(
    baseName: string, scope: UnitScopes, potency: Potency, potencyFactor: number,
  ) {
    this.baseName = baseName;
    this.potency = potency;
    this.scope = scope;
    this.potencyFactor = potencyFactor;
  }

  public get name(): string {
    return this.potency.name + this.baseName;
  }

  public withOptimalPotency(val: number): Unit {
    let valWithOptimalPotency = val;
    let optimalPotencyIndex = this.potency.arrayIndex;

    while (valWithOptimalPotency < 0 && optimalPotencyIndex > 0) {
      optimalPotencyIndex -= 1;
      valWithOptimalPotency *= this.potencyFactor;
    }
    while (valWithOptimalPotency >= this.potencyFactor
    && POTENCIES_ARRAY.length - 1 > optimalPotencyIndex) {
      optimalPotencyIndex += 1;
      valWithOptimalPotency /= this.potencyFactor;
    }
    return this.withNewPotency(POTENCIES_ARRAY[optimalPotencyIndex]);
  }

  private withNewPotency(potency: Potency): Unit {
    const result = this.clone();
    result.potency = potency;
    return result;
  }

  protected abstract clone(): Unit;
}
