import React from 'react';
import TableChart from '@material-ui/icons/TableChart';
import BarChart from '@material-ui/icons/Equalizer';
import { IconButton } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { changeGraphStyle } from '../../redux/actions';
import { mapGraphStyleToProps } from '../../redux/mappers';

type Props = PropsFromRedux;

const ChangeGraphStyleButton = (props: Props) => {
  const { changeGraphStyle: _changeGraphStyle, graphStyle } = props;

  const toggleGraphStyle = () => {
    _changeGraphStyle(graphStyle === 'bar' ? 'table' : 'bar');
  };

  return (
    <IconButton onClick={toggleGraphStyle} aria-label="switch graph style" href="">
      {graphStyle === 'table' ? <BarChart /> : <TableChart />}
    </IconButton>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapGraphStyleToProps, { changeGraphStyle });

export default connector(ChangeGraphStyleButton);
