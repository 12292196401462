import ScreenSize from '../../screen_size_handling/ScreenSize';
import { BaseUnits, UnitScopes } from '../../domain_model/math/constants';


// size
export const CHANGE_SIZE = 'CHANGE_SIZE';

// unit
export const CHANGE_BASE_UNIT = 'CHANGE_BASE_UNIT';
export const CHANGE_UNIT_SCOPE = 'CHANGE_UNIT_SCOPE';


// IState Interfaces

export interface ISizeState {
  currentSize?: ScreenSize;
}

export interface IUnitState {
  baseUnit: BaseUnits;
  unitScope: UnitScopes;
}


// Action Interfaces

interface IChangeBaseUnitAction {
  type: typeof CHANGE_BASE_UNIT;
  payload: BaseUnits;
}

interface IChangeUnitScopeAction {
  type: typeof CHANGE_UNIT_SCOPE;
  payload: UnitScopes;
}

interface IChangeSizeAction {
  type: typeof CHANGE_SIZE;
  payload: ScreenSize;
}

export type MiscActionTypes = (
  IChangeSizeAction
  | IChangeBaseUnitAction
  | IChangeUnitScopeAction
);
