// Plausible Events Setup

export function fireEvent(analyticsEvent: string): void {
  firePlausibleEvent(analyticsEvent);
}

type Window = {
  plausible?: (str: string) => void;
};

function firePlausibleEvent(event: string) {
  const wndw = (window as unknown) as Window;
  if (wndw.plausible !== undefined) {
    wndw.plausible(event);
  }
}
