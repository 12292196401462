import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Chart } from 'highcharts';
import {
  blue, brown, deepPurple, grey, pink, red, teal, yellow,
} from '@material-ui/core/colors';
import { createSelector } from 'reselect';
import { orange } from '@ant-design/colors';
import { Extremes, PlotDataSeries } from './ChartComponent';
import { mapActiveComputedToPropsFlat, mapGraphResolutionToProps } from '../../redux/mappers';
import { STATUS_GREEN } from '../../domain_model/Colors';
import { halfResolutionReducer } from '../helper';
import PowerOrEnergyChart from './PowerorEnergyChart';


type Props = PropsFromRedux & {
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
};


const ProductionChart = (props: Props) => {
  const {
    activeComputedData: computedData,
    graphResolution,
    chartCallback, onNewExtremes, onMouseMove, onMouseLeave,
  } = props;
  const { t } = useTranslation();

  const strings = {
    graphProductionTotalConsumption: t('graphProductionTotalConsumption'),
    graphProductionThermal: t('graphProductionThermal'),
    graphProductionNuclear: t('graphProductionNuclear'),
    graphProductionRiver: t('graphProductionRiver'),
    graphProductionWind: t('graphProductionWind'),
    graphProductionSolar: t('graphProductionSolar'),
    graphProductionCustom: t('graphProductionCustom'),
    graphProductionDam: t('graphProductionDam'),
    graphProductionPump: t('graphProductionPump'),
    graphProductionBattery: t('graphProductionBattery'),
    graphProductionShortage: t('graphProductionShortage'),
    graphProductionImport: t('graphProductionImport'),
  };

  const plotData: PlotDataSeries[] = useMemo(() => {
    const plotDataArray: PlotDataSeries[] = [];
    if (computedData.totalEnergyConsumption > 0) {
      plotDataArray.push({
        type: 'line',
        data: computedData.totalConsumption.reduce(halfResolutionReducer, []),
        name: strings.graphProductionTotalConsumption,
        color: grey[900],
      });
    }
    if (computedData.thermalEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.thermal.reduce(halfResolutionReducer, []),
        name: strings.graphProductionThermal,
        color: red[100],
      });
    }
    if (computedData.nuclearEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.nuclear.reduce(halfResolutionReducer, []),
        name: strings.graphProductionNuclear,
        color: orange[2],
      });
    }
    if (computedData.riverEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.river.reduce(halfResolutionReducer, []),
        name: strings.graphProductionRiver,
        color: blue[100],
      });
    }
    if (computedData.windEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.wind.reduce(halfResolutionReducer, []),
        name: strings.graphProductionWind,
        color: grey[300],
      });
    }
    if (computedData.solarEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.solar.reduce(halfResolutionReducer, []),
        name: strings.graphProductionSolar,
        color: yellow[200],
      });
    }
    if (computedData.customEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.customProduction.reduce(halfResolutionReducer, []),
        name: strings.graphProductionCustom,
        color: pink[200],
      });
    }
    if (computedData.damEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.damProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphProductionDam,
        color: STATUS_GREEN,
      });
    }
    if (computedData.pumpEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.pumpProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphProductionPump,
        color: teal[200],
      });
    }
    if (computedData.batteryEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.batteryProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphProductionBattery,
        color: deepPurple[100],
      });
    }
    if (computedData.importEnergy > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.import.reduce(halfResolutionReducer, []),
        name: strings.graphProductionImport,
        color: pink[300],
      });
    }
    if (computedData.shortageEnergy > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.shortageSeries.reduce(halfResolutionReducer, []),
        name: strings.graphProductionShortage,
        color: brown[200],
      });
    }
    return plotDataArray;
  },
  [computedData, strings]);

  return (
    <PowerOrEnergyChart
      chartCallback={chartCallback}
      plotData={plotData}
      onNewExtremes={onNewExtremes}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      stack
      type={graphResolution === 'year' ? 'energy' : 'power'}
      oneBar={graphResolution === 'year'}
    />
  );
};


const mapToProps = createSelector(
  [mapActiveComputedToPropsFlat, mapGraphResolutionToProps],
  (activeComputed, resolution) => ({
    ...activeComputed,
    graphResolution: resolution.graphResolution,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {});

export default connector(ProductionChart);
