import React from "react";

const SvgBarChart365 = props => (
  <svg viewBox="0 0 23 23" {...props}>
    <path d="M-.5-.5h24v24h-24v-24z" fill="none" />
    <path d="M5.7 12.3H2.5V14h3.4v1.7H4.2v1.7h1.6v1.7H2.5v1.7h3.2c.9 0 1.7-.8 1.7-1.7v-1.2c0-.6-.5-1.2-1.2-1.2.6 0 1.2-.5 1.2-1.2v-1.6c0-.8-.8-1.6-1.7-1.6zM12.3 15.7h-1.7V14h3.2v-1.6h-3.2c-.9 0-1.6.8-1.6 1.7V19c0 .9.8 1.7 1.7 1.7h1.7c.9 0 1.7-.8 1.7-1.7v-1.7c-.1-.9-.9-1.6-1.8-1.6zm0 3.1h-1.7v-1.6h1.7v1.6zM20.3 14h.1v-1.6h-4.9v4.9h3.2V19h-3.2v1.7h3.2c.9 0 1.7-.8 1.7-1.7v-1.7c0-.9-.8-1.7-1.7-1.7H17v-1.7l3.3.1zM12.8 3.6h2.6v6.5h-2.6zM2.5 6.5h2.6v3.6H2.5zM7.6 1h2.6v9.1H7.6zM17.8 1h2.6v9.1h-2.6z" />
  </svg>
);

export default SvgBarChart365;
