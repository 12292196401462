import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import Scenario, { ScenarioDTO } from '../../domain_model/scenario/Scenario';
import GenericLoadFromFileDialog from './GenericLoadFromFileDialog';
import { mapParamsToProps } from '../../redux/mappers';
import { addScenario } from '../../redux/actions';
import ScenarioConverter from '../../domain_model/scenario/ScenarioConverter';


type Props = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
}

const LoadScenarioFromFileDialog: FunctionComponent<Props> = (props) => {
  const {
    onClose, open, addScenario: _addScenario, scenarios,
  } = props;
  const { t } = useTranslation();

  const handleInput = (files: FileList | null) => {
    const reader = new FileReader();
    reader.onload = () => {
      const scenarioDTO = JSON.parse(reader.result as string) as ScenarioDTO;
      if (scenarioDTO.name === '') {
        scenarioDTO.name = 'Unnamed';
      }
      let suffix = 0;
      // eslint-disable-next-line no-loop-func
      while (_.some(scenarios, (scenario: Scenario) => (
        scenario.name.getString(t) === `${scenarioDTO.name}${suffix ? `_${suffix}` : ''}`
      ))) {
        suffix += 1;
      }
      scenarioDTO.name = `${scenarioDTO.name}${suffix ? `_${suffix}` : ''}`;
      _addScenario(
        ScenarioConverter.fromDTO(scenarioDTO),
        true,
      );
    };
    if (files) {
      reader.readAsText(files[0]);
    }
    onClose();
  };

  return (
    <GenericLoadFromFileDialog
      text={t('loadScenarioFromFileDialogText')}
      title={t('loadScenarioFromFileDialogTitle')}
      open={open}
      onClose={onClose}
      handleInput={handleInput}
      accept=".json"
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, { addScenario });

export default connector(LoadScenarioFromFileDialog);
