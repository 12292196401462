import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import downloadFile from 'js-file-download';
import { mapParamsToProps } from '../../redux/mappers';
import { ScenarioDTO } from '../../domain_model/scenario/Scenario';

type Props = PropsFromRedux & {
  dialogOpen: boolean;
  onClose: () => void;
  input: string;
  setInput: (s: string) => void;
};

const useStyles = makeStyles(() => (
  createStyles({
    dialogContent: {
      width: '30em',
    },
    inputContainer: {
      marginBottom: '1em',
    },
  })
));


const DownloadScenarioDialog = (props: Props) => {
  const {
    activeParams, dialogOpen, onClose, input, setInput,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDownload = () => {
    const scenarioDto: ScenarioDTO = {
      name: input,
      parameter: activeParams,
    };
    downloadFile(
      JSON.stringify(scenarioDto, null, 2),
      `${input.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.json`,
    );
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <DialogTitle>
        {t('downloadScenario')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          variant="outlined"
          value={input}
          onChange={handleInputChange}
          autoFocus
          label={t('scenarioName')}
          className={classes.inputContainer}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleDownload} color="primary">
          {t('download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapParamsToProps, {});

export default connector(DownloadScenarioDialog);
