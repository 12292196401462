import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Chart } from 'highcharts';
import { deepPurple, teal } from '@material-ui/core/colors';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { Extremes, PlotDataSeries } from './ChartComponent';
import { mapActiveComputedToPropsFlat, mapGraphResolutionToProps } from '../../redux/mappers';
import { customGreen } from '../../domain_model/Colors';
import { halfResolutionReducer } from '../helper';
import PowerOrEnergyChart from './PowerorEnergyChart';


type Props = PropsFromRedux & {
  chartCallback: (chart: Chart) => void;
  onNewExtremes: (extremes: Extremes) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
};


const StorageInOutChart = (props: Props) => {
  const {
    activeComputedData: computedData,
    graphResolution,
    chartCallback, onNewExtremes, onMouseMove, onMouseLeave,
  } = props;
  const { t } = useTranslation();

  const strings = {
    graphStorageInOutBatteryCharge: t('graphStorageInOutBatteryCharge'),
    graphStorageInOutBatteryDisCharge: t('graphStorageInOutBatteryDisCharge'),
    graphStorageInOutPumpCharge: t('graphStorageInOutPumpCharge'),
    graphStorageInOutPumpDisCharge: t('graphStorageInOutPumpDisCharge'),
    graphStorageInOutDamInflux: t('graphStorageInOutDamInflux'),
    graphStorageInOutDamDischarge: t('graphStorageInOutDamDischarge'),
  };

  const plotData: PlotDataSeries[] = useMemo(() => {
    const plotDataArray: PlotDataSeries[] = [];
    if (computedData.batteryEnergyConsumption > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.batteryConsumedPower.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutBatteryCharge,
        color: deepPurple[300],
      });
    }
    if (computedData.batteryEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.batteryNegativeProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutBatteryDisCharge,
        color: deepPurple[100],
      });
    }
    if (computedData.pumpEnergyConsumption > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.pumpConsumedPower.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutPumpCharge,
        color: teal[400],
      });
    }
    if (computedData.pumpEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.pumpNegativeProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutPumpDisCharge,
        color: teal[100],
      });
    }
    if (_.some(computedData.damInflux, (x) => x !== 0)) {
      plotDataArray.push({
        type: 'area',
        data: computedData.damInflux.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutDamInflux,
        color: customGreen[800],
      });
    }
    if (computedData.damEnergyProduction > 0) {
      plotDataArray.push({
        type: 'area',
        data: computedData.damNegativeProducedPower.reduce(halfResolutionReducer, []),
        name: strings.graphStorageInOutDamDischarge,
        color: customGreen[500],
      });
    }
    return plotDataArray;
  }, [computedData, strings]);

  return (
    <PowerOrEnergyChart
      chartCallback={chartCallback}
      plotData={plotData}
      onNewExtremes={onNewExtremes}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      stack={false}
      centerZero
      type={graphResolution === 'year' ? 'energy' : 'power'}
      oneBar={graphResolution === 'year'}
    />
  );
};

const mapToProps = createSelector(
  [mapActiveComputedToPropsFlat, mapGraphResolutionToProps],
  (computed, resolution) => ({
    ...computed,
    graphResolution: resolution.graphResolution,
  }),
);

type PropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapToProps, {});

export default connector(StorageInOutChart);
