import SourceDataCollection, {
  DataSeries,
  DataSeriesSection,
  IPreloadDataCollection,
  IPreloadSecondaryDataCollection,
} from '../../domain_model/SourceDataCollection';

export const ADD_DATA_SERIES = 'ADD_DATA_SERIES';
export const SET_DATA_SERIES_FETCHING_FLAG = 'SET_DATA_SERIES_FETCHING_FLAG';
export const ADD_PRELOAD_DATA = 'ADD_PRELOAD_DATA';
export const ADD_PRELOAD_SECONDARY_DATA = 'ADD_PRELOAD_SECONDARY_DATA';

export interface IDataState {
  data: SourceDataCollection;
  preloadData: IPreloadDataCollection;
  preloadSecondaryData: IPreloadSecondaryDataCollection;
}


// Action Interfaces

interface IAddDataSeriesAction {
  type: typeof ADD_DATA_SERIES;
  payload: { dataSection: DataSeriesSection; yearOrSlug: string; dataSeries: DataSeries };
}

interface ISetFetchingFlagAction {
  type: typeof SET_DATA_SERIES_FETCHING_FLAG;
  payload: { dataSection: DataSeriesSection; yearOrSlug: string };
}

interface IAddPreloadDataAction {
  type: typeof ADD_PRELOAD_DATA;
  payload: IPreloadDataCollection;
}

interface IAddPreloadSecondaryDataAction {
  type: typeof ADD_PRELOAD_SECONDARY_DATA;
  payload: IPreloadSecondaryDataCollection;
}


export type DataActionTypes = (
  IAddDataSeriesAction
  | ISetFetchingFlagAction
  | IAddPreloadDataAction
  | IAddPreloadSecondaryDataAction
);
