import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


interface IOptionType {
  label: string;
  mutable: boolean;
}

type Props = {
  input: string;
  onNewInput: (value: string) => void;
  scenarioNameList: IOptionType[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderInputComponent(inputProps: any) {
  const {
    classes,
    inputRef = () => {
      // Do nothing
    },
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const renderSuggestion = (
  suggestion: IOptionType,
  { isHighlighted }: Autosuggest.RenderSuggestionParams,
) => {
  if (suggestion.mutable) {
    return (
      <MenuItem disabled={!suggestion.mutable} selected={isHighlighted} component="div">
        <div>
          {suggestion.label}
        </div>
      </MenuItem>
    );
  }
  return null;
};

function getSuggestions(value: string, options: IOptionType[]) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : options.filter((suggestion) => {
      const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

function getSuggestionValue(suggestion: IOptionType) {
  return suggestion.label;
}

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    container: {
      position: 'relative',
    },
    popupPaper: {
      position: 'fixed',
      zIndex: 1,
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
  })
));

export default function ScenarioNameInput(props: Props): JSX.Element {
  const classes = useStyles();
  const { input, onNewInput, scenarioNameList } = props;
  const { t } = useTranslation();
  const [stateSuggestions, setSuggestions] = React.useState<IOptionType[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(
      value,
      scenarioNameList,
    ));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (
    _: React.ChangeEvent<unknown>,
    { newValue }: Autosuggest.ChangeEvent,
  ) => {
    onNewInput(newValue);
  };

  return (
    <Autosuggest
      renderInputComponent={renderInputComponent}
      suggestions={stateSuggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        // @ts-ignore
        classes,
        id: 'scenarioName-autosuggest',
        label: t('scenarioName'),
        value: input,
        onChange: handleChange,
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderSuggestionsContainer={(options: any) => (
        <Paper {...options.containerProps} square className={classes.popupPaper}>
          {options.children}
        </Paper>
      )}
    />
  );
}
