import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { grey, red } from '@material-ui/core/colors';
import _ from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  mapCalcStatesToProps,
  mapEMobilityParamsToProps,
  mapPreComputedToProps,
  mapPreloadDataToProps,
} from '../../../redux/mappers';
import MiniChartComponent from './MiniChartComponent';
import { PlotDataSeries } from '../../main-section/ChartComponent';
import { toEnergy, toPower } from '../../../domain_model/math/helpers';
import {
  DATA_POINT_DURATION_H,
  NR_OF_VALUES,
  WIND_POWER_CORRECTION_FACTOR,
} from '../../../business-logic/constants';
import { SECONDARY } from '../../../domain_model/Colors';
import CalculationState from '../../../domain_model/CalculationState';
import Quantity from '../../../domain_model/math/Quantity';
import { DB_ENERGY_UNIT } from '../../../domain_model/math/EnergyUnit';
import { IReduxState } from '../../../redux/types';
import { beautifyNumber } from '../../helper';
import { assertUnreachable } from '../../../helper';


type Props = PropsFromRedux & {
  scaling: number;
  yearOrSlug: string;
  section: 'endUser' | 'import' | 'export' | 'nuclear' | 'thermal' | 'solar' | 'wind' | 'river';
  max?: number;
  className?: string;
  disableMaxLine?: boolean;
  showSolarWinterTotal?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    height: '100%',
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: '"graph"',
  },
  loadingRoot: {
    backgroundColor: 'rgba(200,200,200,0.2)',
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {},
  circleIndeterminate: {
    animation: 'unset',
    strokeDasharray: '80px, 200px',
  },
  graph: {
    gridArea: 'graph',
    position: 'relative',
  },
  graphLegendContainer: {
    position: 'absolute',
    zIndex: 10,
    bottom: '-1.4em',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: grey[500],
  },
  total: {
    gridArea: 'graph',
    zIndex: 1,
    position: 'relative',
    textAlign: 'center',
    marginTop: '1em',
    alignSelf: 'start',
    color: grey[800],
    textShadow: '0 0 4px white',
  },
  solarTotalTextContainer: {
    flexGrow: 1,
  },
  solarTotalTextTable: {
    width: '100%',
    tableLayout: 'fixed',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    '& tr': {
      '& td:first-child': {
        width: '50%',
      },
      '& td:nth-child(2)': {
        width: 'max-content',
      },
    },
  },
}));

const CurveDiagram = (props: Props) => {
  const { t } = useTranslation();
  const {
    scaling: scaledValue,
    yearOrSlug,
    maxSectionDataCollection,
    lowResSectionDataCollection,
    sumSectionDataCollection,
    section,
    max,
    additionalEMobilityEnergy,
    preComputedData: {
      solar: {
        active: {
          data: solarLoadPreScaling,
        },
      },
    },
    calculationStates: { solarLoadActivePreCalculation: solarLoadPreCalculation },
    className,
    disableMaxLine,
    showSolarWinterTotal,
  } = props;
  const classes = useStyles();

  const originalMaxValue = section === 'solar'
    ? solarLoadPreScaling.max
    : maxSectionDataCollection[section][yearOrSlug] || 0;
  // Do not remove cloneDeep or else bug!!! This fixes mutation of preload data on year change
  const originalDataSeries = _.cloneDeep(
    section === 'solar'
      ? solarLoadPreScaling.lowRes
      : lowResSectionDataCollection[section][yearOrSlug] || [],
  );
  const scaledDataSeries = originalDataSeries.map((x) => x * (scaledValue / originalMaxValue));
  const scalingLineDataSeries = scaledDataSeries.map(() => scaledValue);

  const plotData: PlotDataSeries[] = [
    {
      type: 'area',
      data: scaledDataSeries,
      name: '',
      color: red[200],
    },
  ];

  if (section !== 'endUser' && !disableMaxLine) {
    plotData.push({
      type: 'line',
      data: scalingLineDataSeries,
      name: '',
      color: red[500],
    });
  }

  // Original Data Line
  switch (section) {
    case 'wind':
    case 'solar':
      break;
    case 'endUser': {
      const originalSum = sumSectionDataCollection[section][yearOrSlug] || 0;
      const ScalingFactor = (toEnergy(originalSum) / scaledValue);
      plotData.push({
        type: 'line',
        data: scaledDataSeries.map((x) => x * ScalingFactor),
        name: '',
        color: grey[700],
      });

      const endUserFactor = toEnergy(originalSum) / originalMaxValue;
      plotData.unshift({
        type: 'area',
        data: scaledDataSeries.map(
          () => toPower(additionalEMobilityEnergy / NR_OF_VALUES)
            * endUserFactor,
        ),
        name: '',
        color: SECONDARY,
      });

      break;
    }
    case 'import':
    case 'export': {
      const originalSum = sumSectionDataCollection[section][yearOrSlug] || 0;
      const ScalingFactor = (toEnergy(originalSum) / scaledValue);
      plotData.push({
        type: 'line',
        data: scaledDataSeries.map((x) => x * ScalingFactor),
        name: '',
        color: grey[700],
      });

      break;
    }
    case 'nuclear':
    case 'thermal':
    case 'river':
      plotData.push({
        type: 'line',
        data: originalDataSeries,
        name: '',
        color: grey[700],
      });
      break;
    default:
      assertUnreachable(section);
  }

  function renderTotal() {
    let totalRawValue;
    // Solar only
    let winterRawValue = 0;
    switch (section) {
      case 'endUser': // Intellisense Bug: This branch IS reachable
      case 'import':
      case 'export':
        totalRawValue = scaledValue;
        break;
      case 'solar':
        totalRawValue = scaledValue * toEnergy(solarLoadPreScaling.sum);
        if (showSolarWinterTotal) {
          winterRawValue = scaledValue * toEnergy(
            _.sum(
              solarLoadPreScaling.highRes.filter(
                (__, index) => (
                  (index < (31 + 30) * (24 / DATA_POINT_DURATION_H))
                  || (index > (365 - 31) * (24 / DATA_POINT_DURATION_H))
                ),
              ),
            ),
          );
        }
        break;
      case 'wind': // Intellisense Bug: This branch IS reachable
        totalRawValue = scaledValue
          * WIND_POWER_CORRECTION_FACTOR
          * toEnergy(sumSectionDataCollection[section][yearOrSlug] || 0);
        break;
      case 'nuclear':
      case 'thermal':
      case 'river':
        totalRawValue = (scaledValue / (maxSectionDataCollection[section][yearOrSlug] || 0))
          * toEnergy(sumSectionDataCollection[section][yearOrSlug] || 0);
        break;
      default:
        assertUnreachable(section);
    }

    if (!totalRawValue && totalRawValue !== 0) {
      return null;
    }
    const total = new Quantity(totalRawValue, DB_ENERGY_UNIT, false);

    function renderText() {
      if (section === 'solar' && showSolarWinterTotal) {
        return (
          <div className={classes.solarTotalTextContainer}>
            <table className={classes.solarTotalTextTable}>
              <tr>
                <td>
                  {t('previewChartTotal')}
                </td>
                <td>
                  {`${beautifyNumber(total.val, 0, true)} ${t(total.unit.name)}`}
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  {t('previewChartWinter')}
                </td>
                <td>
                  {`${beautifyNumber(winterRawValue, 0, true)} ${t(total.unit.name)}`}
                </td>
                <td />
              </tr>
            </table>
          </div>
        );
      }
      return `${beautifyNumber(total.val, 0, true)} ${t(total.unit.name)}`;
    }

    return (
      <Typography className={classes.total} variant="caption">
        {renderText()}
      </Typography>
    );
  }

  const loadingOverlay = (
    <div className={classes.loadingRoot}>
      <div className={classes.loading}>
        <CircularProgress
          color="primary"
          size="3em"
        />
      </div>
    </div>
  );

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.graphLegendContainer}>
        <Typography variant="caption">{t('paramsDiagramLabelJanuary')}</Typography>
        <Typography variant="caption">{t('paramsDiagramLabelJune')}</Typography>
        <Typography variant="caption">{t('paramsDiagramLabelDecember')}</Typography>
      </div>
      <div className={classes.graph}>
        {section === 'solar' && solarLoadPreCalculation !== CalculationState.Idle && loadingOverlay}
        <MiniChartComponent plotData={plotData} max={max || _.max(scaledDataSeries) || 1} />
      </div>
      {renderTotal()}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
const mapToProps = (state: IReduxState) => ({
  ...mapEMobilityParamsToProps(state),
  ...mapPreloadDataToProps(state),
  ...mapPreComputedToProps(state),
  ...mapCalcStatesToProps(state),
});
const connector = connect(mapToProps, {});

export default connector(CurveDiagram);
