import React, { FunctionComponent, RefObject } from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notistackRef: RefObject<any> = React.createRef();
const onClickDismiss = (key: string | number | undefined) => () => {
  notistackRef.current.handleDismissSnack(key);
};

const NotificationProvider: FunctionComponent = ({ children }) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    ref={notistackRef}
    action={(key) => (
      <Button onClick={onClickDismiss(key)} style={{ minWidth: 0 }}>
        <CloseIcon style={{ color: 'white' }} />
      </Button>
    )}
  >
    {children}
  </SnackbarProvider>
);

export default NotificationProvider;
