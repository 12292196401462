import React from "react";

const SvgBarChart1 = props => (
  <svg
    id="bar_chart_1_svg__Ebene_1"
    x={0}
    y={0}
    viewBox="0 0 23 23"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".bar_chart_1_svg__st1{fill:none}"}</style>
    <path d="M2.5 2.2h17.8V9H2.5zM13.1 20.8h-1.7v-6.7H9.9v-1.7h3.2z" />
    <path className="bar_chart_1_svg__st1" d="M-.6 2h24v24h-24V2z" />
    <path className="bar_chart_1_svg__st1" d="M-.5-.5h24v24h-24v-24z" />
  </svg>
);

export default SvgBarChart1;
