import React from "react";

const SvgDam = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M7 5.6c.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8s2.2-.4 3-.8c.6-.3 1.2-.6 2-.6V3.7c-1.3 0-2.1.4-2.9.8-.6.3-1.2.6-2 .6-.9 0-1.4-.3-2-.6-.9-.4-1.7-.8-3.1-.8s-2.2.4-3 .8c-.6.3-1.1.6-2 .6V7c1.3 0 2.2-.4 3-.8.6-.3 1.1-.6 2-.6zM7 10c.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8s2.2-.4 3-.8c.6-.3 1.2-.6 2-.6V8c-1.3 0-2.1.4-2.9.8-.6.3-1.1.6-2 .6s-1.4-.3-2-.6c-.9-.3-1.7-.7-3.1-.7s-2.2.4-3 .8c-.6.3-1.1.6-2 .6v2c1.3 0 2.2-.4 3-.8.6-.4 1.1-.7 2-.7zM7 14.5c.9 0 1.4.3 2 .6.8.4 1.6.8 3 .8s2.2-.4 3-.8c.6-.3 1.1-.6 2-.6v-1.9c-1.3 0-2.1.4-2.9.8-.6.3-1.2.6-2 .6-.9 0-1.4-.3-2-.6-.8-.4-1.6-.8-3-.8s-2.3.4-3.1.7c-.6.3-1.2.6-2 .6v2c1.3 0 2.2-.4 3-.8.6-.4 1.1-.6 2-.6zM16.9 17c-1.3 0-2.1.4-2.9.8-.6.3-1.2.6-2 .6-.9 0-1.4-.3-2-.6-.8-.4-1.6-.8-3-.8s-2.2.4-3 .8c-.6.3-1.2.6-2 .6v2c1.3 0 2.2-.4 3-.8.6-.3 1.2-.6 2-.6s1.4.3 2 .6c.8.4 1.6.8 3 .8s2.2-.4 3-.8c.6-.3 1.2-.6 2-.6v-2zM18.8 3.7v16.6h5l-3-16.6z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

export default SvgDam;
