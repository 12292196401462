import { TFunction } from 'i18next';
import downloadFile from 'js-file-download';
import Table from '../../domain_model/data-export/Table';
import { IComputedData } from '../../domain_model/ComputedDataCollection';
import Column from '../../domain_model/data-export/Column';
import { DataSeries } from '../../domain_model/SourceDataCollection';
import { CURRENT_APP_VERSION } from '../../version';

function download(dataStr: string, t: TFunction) {
  downloadFile(dataStr, `${t('csvResultDataExportFileName')}.csv`);
}

function toCsvString(rows: (string | number)[][]) {
  return rows.map((row) => row.join(';')).join('\n');
}

function downloadAsCsv(table: Table, t: TFunction): void {
  download(toCsvString(table.getRows()), t);
}

function exportResultData(computed: IComputedData, t: TFunction): void {
  const table = new Table();
  //  Todo fill Table
  table.columns.push(new Column('Source', ['https://powercheck.ch']));
  table.columns.push(new Column('Date', [new Date().toDateString()]));
  table.columns.push(new Column('App-Version', [CURRENT_APP_VERSION]));
  table.columns.push(new Column('Scenario', [computed.scenarioInfo.scenarioName.getString(t)]));

  function addDataSeriesColumns(seriesObject: {[key in string]: DataSeries}) {
    Object.entries(seriesObject).forEach(([key, value]) => {
      table.columns.push(new Column(key, value));
    });
  }

  function addNumberColumns(seriesObject: {[key in string]: number}) {
    Object.entries(seriesObject).forEach(([key, value]) => {
      table.columns.push(new Column(key, [value]));
    });
  }

  addDataSeriesColumns(computed.basicLoads);
  addDataSeriesColumns(computed.customData);
  addDataSeriesColumns(computed.specialLoads);
  addDataSeriesColumns(computed.computedStorage);
  addDataSeriesColumns(computed.computedMisc);
  addDataSeriesColumns(computed.basicLoads);
  addNumberColumns(computed.computedSummary);

  downloadAsCsv(table, t);
}


export default exportResultData;
