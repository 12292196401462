import React from 'react';
import {
  CircularProgress, createStyles, makeStyles, Modal,
} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus': {
      outline: 'none',
    },
  },
  circleIndeterminate: {
    animation: 'unset',
    strokeDasharray: '42px, 200px',
    color: 'white',
  },
}));

const LoadingScreen = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Modal open>
      <div className={classes.progress}>
        <CircularProgress
          classes={{
            circleIndeterminate: classes.circleIndeterminate,
          }}
          size="5em"
        />
      </div>
    </Modal>
  );
};

export default LoadingScreen;
